
const isAlphaNumeric = (str) => {
    var code, i, len;
  
    for (i = 0, len = str.length; i < len; i++) {
      code = str.charCodeAt(i);
      if (!(code > 47 && code < 58) && // numeric (0-9)
          !(code > 64 && code < 91) && // upper alpha (A-Z)
          !(code > 96 && code < 123) && // lower alpha (a-z)
          !(code === 45)
        ) { 
        return false;
      }
    }
    return true;
  };

  export async function validateChannelName(channelName, client){

    if (!channelName || !isAlphaNumeric(channelName)){
        return 'Channel name can only contain numbers, letters, and hyphens (no spaces).'
    }

    const channelsById = await client.queryChannels({id: channelName}); 
    const channelsByName = await client.queryChannels({name: channelName}); 

    if (channelsByName.length || channelsById.length){
      return 'Channel name already exists. Please use a unique name'
    } else {
        return false
    }
  }