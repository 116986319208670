import React, { useState, useEffect } from "react";
import "./user-profile.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faDollyFlatbed, faHandshake, faSitemap } from "@fortawesome/free-solid-svg-icons";
import { CloseUserProfile } from "../../assets/CloseUserProfile";
import {ProfilePicPlaceholder} from "../../../other/profilePicPlaceholder/ProfilePicPlaceholder"
import { GET } from "../../../../util/api/api";

export function calculateUserReviews(reviews, userType){
    let total;
    let bedsideManner;
    let professionalism;
    let punctuality;
    let skillSet;
    let cleanliness;
    let friendliness;
    let helpfulness;

    if (!reviews || !reviews.length){
        return ""
    }

    for (let i = 0; i < reviews.length; i++){
        let tempTotal;
        if (typeof reviews[i].bedside_manner === 'number'){
            bedsideManner = bedsideManner === undefined ? reviews[i].bedside_manner : bedsideManner + reviews[i].bedside_manner
            tempTotal = tempTotal === undefined ? reviews[i].bedside_manner : tempTotal + reviews[i].bedside_manner
        }
        if (typeof reviews[i].professionalism === 'number'){
            professionalism = professionalism === undefined ? reviews[i].professionalism : professionalism + reviews[i].professionalism
            tempTotal = tempTotal === undefined ? reviews[i].professionalism : tempTotal + reviews[i].professionalism
        }
        if (typeof reviews[i].punctuality === 'number'){
            punctuality = punctuality === undefined ? reviews[i].punctuality : punctuality + reviews[i].punctuality
            tempTotal = tempTotal === undefined ? reviews[i].punctuality : tempTotal + reviews[i].punctuality
        }
        if (typeof reviews[i].skill_set === 'number'){
            skillSet = skillSet === undefined ? reviews[i].skill_set : skillSet + reviews[i].skill_set
            tempTotal = tempTotal === undefined ? reviews[i].skill_set : tempTotal + reviews[i].skill_set
        }
        if (typeof reviews[i].cleanliness === 'number'){
            cleanliness = cleanliness === undefined ? reviews[i].cleanliness : cleanliness + reviews[i].cleanliness
            tempTotal = tempTotal === undefined ? reviews[i].cleanliness : tempTotal + reviews[i].cleanliness
        }
        if (typeof reviews[i].friendliness === 'number'){
            friendliness = friendliness === undefined ? reviews[i].friendliness : friendliness + reviews[i].friendliness
            tempTotal = tempTotal === undefined ? reviews[i].friendliness : tempTotal + reviews[i].friendliness
        }
        if (typeof reviews[i].helpfulness === 'number'){
            helpfulness = helpfulness === undefined ? reviews[i].helpfulness : helpfulness + reviews[i].helpfulness
            tempTotal = tempTotal === undefined ? reviews[i].helpfulness : tempTotal + reviews[i].helpfulness
        }

        if (typeof tempTotal === 'number'){
            if (userType === 'office'){
                total = total === undefined ? tempTotal/3 : total + (tempTotal/3)
            } else {
                total = total === undefined ? tempTotal/4 : total + (tempTotal/4)
            }
            
        }
    }

    const response = {}

    if (total !== undefined){
        response.total = (total / reviews.length).toFixed(1)
    }

    if (bedsideManner !== undefined){
        response.bedside_manner = (bedsideManner / reviews.length).toFixed(1)
    }
    if (professionalism !== undefined){
        response.professionalism = (professionalism / reviews.length).toFixed(1)
    }
    if (punctuality !== undefined){
        response.punctuality = (punctuality / reviews.length).toFixed(1)
    }
    if (skillSet !== undefined){
        response.skill_set = (skillSet / reviews.length).toFixed(1)
    }
    if (cleanliness !== undefined){
        response.cleanliness = (cleanliness / reviews.length).toFixed(1)
    }
    if (friendliness !== undefined){
        response.friendliness = (friendliness / reviews.length).toFixed(1)
    }
    if (helpfulness !== undefined){
        response.helpfulness = (helpfulness / reviews.length).toFixed(1)
    }

    response.total_reviews = reviews.length

    return response
}

function UserProfile(props) {
    const { userSpotlight, setUserSpotlight, setSpotlightActive, editCreate, panelsOpen, setPanelsOpen, customId, isOrgConnectPage, currentUser } = props;

    const [user, setUser] = useState("");
    const [reviewTotals, setReviewTotals] = useState({})

    useEffect(() => {
        async function getUser() {
            try {
                const user = await GET(`/api/user/${userSpotlight}`);
                setUser(user.data);
                const reviews = calculateUserReviews(user.data.reviews, user.data.user_type)
                setReviewTotals(reviews)
            } catch (err) {
                console.log(err);
            }
        }

        if (userSpotlight) {
            getUser();
        }
    }, [userSpotlight]);

    return (
        <div className={`persona-info-container ${editCreate ? 'persona-container-side-pop' : ''} ${!panelsOpen.right ? 'mobile-close' : ''}`} id={customId ? customId : ''}>
            <CloseUserProfile {...{ setSpotlightActive, setUserSpotlight, setPanelsOpen }} />
            <div className="persona-profile-pic">{user ? user.profile_img ? <img src={user.profile_img}></img> : <ProfilePicPlaceholder user={user} size={"profile"}/>: <div className="placeholder"></div>}</div>
            <div className="persona-actions">
                {/* <p>Details</p> */}
                {/* <div className="persona-action">
                    <div className="persona-action-icon">
                        <FontAwesomeIcon icon={faDollyFlatbed} />
                    </div>
                    <div className="persona-action-text"><p>View Inventory</p></div>
                </div>
                <div className="persona-action">
                    <div className="persona-action-icon">
                        <FontAwesomeIcon icon={faHandshake} />
                    </div>
                    <div className="persona-action-text"><p>New Transaction</p></div>
                </div>
                <div className="persona-action">
                    <div className="persona-action-icon">
                        <FontAwesomeIcon icon={faSitemap} />
                    </div>
                    <div className="persona-action-text"><p>View in Org</p></div>
                </div> */}
                {isOrgConnectPage && 
                (currentUser && currentUser.connections && currentUser.connections.includes(userSpotlight))
                    ? <button onClick={() => window.location.href=`/messages?id=${userSpotlight}`} className="send-message-persona-actions send-message-persona-actions-adjust">Send Message</button> : ""}
            </div>
            <div className="persona-info">


                {user?.user_type === 'freelancer' ?
                
                <>
                    <div>
                        <h4 className="persona-info-label">Name</h4>
                    </div>
                    <div>
                        <p className="persona-info-value">
                            {user.first_name} {user.last_name}
                        </p>
                    </div>
                    <div>
                        <h4 className="persona-info-label">Area of Expertise</h4>
                    </div>
                    <div>
                        <p className="persona-info-value">{user.expertise ? user.expertise : "--"}</p>
                    </div>
                    <div>
                        <h4 className="persona-info-label">Years of Experience</h4>
                    </div>
                    <div>
                        <p className="persona-info-value">{user.years_of_experience ? user.years_of_experience : "--"}</p>
                    </div>
                    <div>
                        <h4 className="persona-info-label">City</h4>
                    </div>
                    <div>
                        <p className="persona-info-value">{user.location && user.location.address && user.location.address.city ? user.location.address.city : "--"}</p>
                    </div>
                    <div>
                        <h4 className="persona-info-label">State</h4>
                    </div>
                    <div>
                        <p className="persona-info-value">{user.location && user.location.address && user.location.address.state ? user.location.address.state : "--"}</p>
                    </div>
                    <div>
                        <h4 className="persona-info-label">Zip Code</h4>
                    </div>
                    <div>
                        <p className="persona-info-value">{user.location && user.location.address && user.location.address.zip ? user.location.address.zip : "--"}</p>
                    </div>
                    <div>
                        <h4 className="persona-info-label">Bio</h4>
                    </div>
                    <div>
                        <p className="persona-info-value">{user.bio ? user.bio : "--"}</p>
                    </div>
                    <div>
                        <h4 className="persona-info-label">Reviews {"(" + reviewTotals?.total_reviews + ")"}</h4>
                    </div>
                    <div>
                        <p className="persona-info-value" style={{fontWeight: 600}}>Overall:
                            <span className="review-stars"> {reviewTotals?.total ? reviewTotals?.total + " ⭐️" : "--"}</span>
                        </p>
                    </div>
                    <div>
                        <p className="persona-info-value">Professionalism:
                            <span className="review-stars"> {reviewTotals?.professionalism ? reviewTotals?.professionalism + " ⭐️" : "--"}</span>
                        </p>
                    </div>
                    <div>
                        <p className="persona-info-value">Punctuality: 
                            <span className="review-stars"> {reviewTotals?.punctuality ? reviewTotals?.punctuality + " ⭐️" : "--"}</span>
                        </p>
                    </div>
                    <div>
                        <p className="persona-info-value">Bedside Manner: 
                            <span className="review-stars"> {reviewTotals?.bedside_manner ? reviewTotals?.bedside_manner + " ⭐️" : "--"}</span>
                        </p>
                    </div>
                    <div>
                        <p className="persona-info-value">Skill Set:
                            <span className="review-stars"> {reviewTotals?.skill_set ? reviewTotals?.skill_set + " ⭐️" : "--"}</span>
                        </p>
                    </div>
                </>

                : (user?.user_type === 'office' ?
                
                <>
                    <div>
                        <h4 className="persona-info-label">Office Name</h4>
                    </div>
                    <div>
                        <p className="persona-info-value">
                            {user.office_name ? user.office_name : "--"}
                        </p>
                    </div>
                    <div>
                        <h4 className="persona-info-label">Office Type</h4>
                    </div>
                    <div>
                        <p className="persona-info-value">{user.office_type ? user.office_type : "--"}</p>
                    </div>
                    <div>
                        <h4 className="persona-info-label">Number of Operatories</h4>
                    </div>
                    <div>
                        <p className="persona-info-value">{user.operatories_amt ? user.operatories_amt : "--"}</p>
                    </div>
                    <div>
                        <h4 className="persona-info-label">Bio</h4>
                    </div>
                    <div>
                        <p className="persona-info-value">{user.bio ? user.bio : "--"}</p>
                    </div>
                    <div>
                        <h4 className="persona-info-label">Location</h4>
                    </div>
                    <div>
                        <p className="persona-info-value">{user?.location?.address?.address ? user.location.address.address : "--"}</p>
                        <p className="persona-info-value">{user?.location?.address?.city && user?.location?.address?.state && user?.location?.address?.zip ? user?.location?.address?.city + ", " + user?.location?.address?.state + " " + user?.location?.address?.zip : "--"}</p>
                    </div>
                    <div>
                        <h4 className="persona-info-label">Reviews {"(" + reviewTotals?.total_reviews + ")"}</h4>
                    </div>
                    <div>
                        <p className="persona-info-value" style={{fontWeight: 600}}>Overall:
                            <span className="review-stars"> {reviewTotals?.total ? reviewTotals?.total + " ⭐️" : "--"}</span>
                        </p>
                    </div>
                    <div>
                        <p className="persona-info-value">Cleanliness:
                            <span className="review-stars"> {reviewTotals?.cleanliness ? reviewTotals?.cleanliness + " ⭐️" : "--"}</span>
                        </p>
                    </div>
                    <div>
                        <p className="persona-info-value">Friendliness: 
                            <span className="review-stars"> {reviewTotals?.friendliness ? reviewTotals?.friendliness + " ⭐️" : "--"}</span>
                        </p>
                    </div>
                    <div>
                        <p className="persona-info-value">Helpfulness: 
                            <span className="review-stars"> {reviewTotals?.helpfulness ? reviewTotals?.helpfulness + " ⭐️" : "--"}</span>
                        </p>
                    </div>
                </>

                : "")
            }
            </div>
        </div>
    );
}

export default UserProfile;
