import React, { useState, useEffect } from "react";
import { useChatContext } from "stream-chat-react";

import "./EditChannel.css";

import { UserList } from "../CreateChannel/UserList";
import { MessagePopup } from "../MessagePopup/MessagePopup";
import {validateChannelName} from "../../../../helpers/validateChannelName";
import { CloseCreateChannel } from "../../assets";
import UserProfile from "../UserProfile/UserProfile"

const ChannelNameInput = (props) => {
    const { channel } = useChatContext();
    const { setFilters, setSelectedUsers, channelName = "", setChannelName, leaveChannel, deleteChannel, channelMembers, id, activeTab, setActiveTab } = props;
    const [popupActive, setPopupActive] = useState(false);
    const [popupActionWord, setActionWord] = useState("");

    const handleChange = (event) => {
        event.preventDefault();
        setChannelName(event.target.value);
    };

    const displayConfirmPopup = (actionWord) => {
        setPopupActive(true);
        setActionWord(actionWord);
    };

    return (
        <>
            <MessagePopup active={popupActive} setPopupActive={setPopupActive} actionWord={popupActionWord} actionFunc={popupActionWord === "delete" ? deleteChannel : leaveChannel} />
            <div className="channel-name-input__wrapper">
                <p>Name</p>
                <div>
                    <input onChange={handleChange} placeholder="space-name" type="text" value={channelName} />
                    <div className="leave-channel-button-container">
                        {channel?.data?.created_by?.id === id ? (
                            <button
                                className="leave-channel-button"
                                onClick={() => {
                                    displayConfirmPopup("delete");
                                }}
                            >
                                Delete Channel
                            </button>
                        ) : (
                            <button
                                className="leave-channel-button"
                                onClick={() => {
                                    displayConfirmPopup("leave");
                                }}
                            >
                                Leave Channel
                            </button>
                        )}
                    </div>
                </div>
                <div>
                    <div
                        className={"edit-channel-tab " + (activeTab === "members" ? "" : "tab-deselected")}
                        onClick={() => {
                            setActiveTab("members");
                            setSelectedUsers([])
                            setFilters({id: { $in: channelMembers }})
                        }}
                    >
                        <p>Current Members</p>
                    </div>
                    <div
                        className={"edit-channel-tab " + (activeTab === "add" ? "" : "tab-deselected")}
                        onClick={() => {
                            setActiveTab("add");
                            setSelectedUsers([])
                            setFilters({id: { $nin: channelMembers }})
                        }}
                    >
                        <p>Add Members</p>
                    </div>
                </div>
            </div>
        </>
    );
};

export const EditChannel = (props) => {
    const {isEditing, setIsEditing, isCreating, id, setUserSpotlight, setSpotlightActive} = props;
    const { channel, client } = useChatContext();

    const [channelName, setChannelName] = useState(channel?.data.name || channel?.data.id);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [activeTab, setActiveTab] = useState("members");
    const [reloadUsers, setReloadUsers] = useState(false);
    const [nameError, setNameError] = useState(false);

    const channelMembers = Object.keys(channel.state.members);
    const defaultFilter = {id: channelMembers.length ? activeTab === "members" ? { $in: channelMembers } : { $nin: channelMembers } : ""}
    const [filters, setFilters] = useState(defaultFilter)

    const updateChannel = async (event) => {
        event.preventDefault();

        const nameChanged = channelName !== (channel.data.name || channel.data.id);
        let validation

        if (nameChanged) {
            validation = await validateChannelName(channelName, client);
            setNameError(validation);

            if (!validation) {
                await channel.update({ name: channelName }, { text: `Channel name changed to ${channelName}` });
            }
        }

        if (selectedUsers.length && activeTab === "add") {
            await channel.addMembers(selectedUsers);
            setReloadUsers(true);
        }
        if (selectedUsers.length && activeTab === "members") {
            await channel.removeMembers(selectedUsers);
            setReloadUsers(true);
        }

        if (!validation) {
            setChannelName(null);
            setIsEditing(false);
            setSelectedUsers([]);
        }
    };

    const leaveChannel = async () => {
        await channel.stopWatching()
        setIsEditing(false);
        await channel.removeMembers([id]);
        window.location.reload()
    };

    const deleteChannel = async () => {
        await channel.stopWatching()
        setIsEditing(false);
        await channel.delete();
        window.location.reload()
    };

    const handleSearchInput = (e) => {
        if (e.target && e.target.value) {
            setFilters({name: { $autocomplete: e.target.value }})
        } else if (e.target.value === "") {
            setFilters(defaultFilter)
        }
    }

    return (
        <>
            <div className='edit-channel__container'>
                <div className="edit-channel__header">
                    <p>Edit Space</p>
                    <CloseCreateChannel {...{ setIsEditing }} />
                </div>
                {nameError ? <p className="error-message">{nameError}</p> : ""}
                <ChannelNameInput {...{ setFilters, setSelectedUsers, channelName, setChannelName, leaveChannel, deleteChannel, channelMembers, id, activeTab, setActiveTab }} />
                <div className='messaging-user-search-bar_container'>
                    <input className="messaging-user-search-bar" onChange={handleSearchInput} placeholder="search for users"></input>
                </div>
                <UserList {...{ filters, setFilters, selectedUsers, setSelectedUsers, activeTab, setActiveTab, id, isEditing, setIsEditing, isCreating, reloadUsers, setReloadUsers, setUserSpotlight, setSpotlightActive }} />
                <div className="edit-channel__button-wrapper" onClick={updateChannel}>
                    <p>Save Changes</p>
                </div>
            </div>
            {/* {spotlightActive ? <UserProfile {...{userSpotlight, setUserSpotlight, setSpotlightActive}} editCreate={isEditing}/> : ""} */}
        </>
    );
};
