import { GET, POST } from "../../util/api/api";
import "./main.css";
import { React, useState, useEffect } from "react";
import Loading from "../other/Loading";
import { faBuilding } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {loadConnect} from '@stripe/connect-js';
import {ConnectPayments, ConnectComponentsProvider } from "@stripe/react-connect-js";
import { Transactions } from "./Transactions";
import { PaymentsNotConfigured } from "./Payments";

export function PaymentMethod(){
    const [isLoading, setIsLoading] = useState(true);
    const [activePayment, setActivePayment] = useState('')
    const [savePaymentLink, setSavePaymentLink] = useState('')
    const [configurePaymentsLink, setConfigurePaymentsLink] = useState('');
    const [paymentsEnabled, setPaymentsEnabled] = useState(false);
    
    useEffect(() => {
        async function getPaymentMethod(){

            const {data} = await GET("/other/stripe/account")
            const stripePaymentsEnabled = data.user_type === 'office' ? data.charges_enabled : data.payouts_enabled
            setConfigurePaymentsLink(data.user_type === 'office' ? data.save_payment_link : data.account_link)
            setPaymentsEnabled(stripePaymentsEnabled)

            if (stripePaymentsEnabled){
                const {data} = await GET("/other/stripe/payment-methods")

                if (data.user_type === 'office'){
                    setActivePayment(data?.invoice_settings?.default_payment_method)
                } else {
                    const activePaymentTemp = {
                        type: data.object,
                        us_bank_account: {
                            bank_name: data.bank_name,
                            last4: data.last4,
                            account_type: data.object
                        },
                        card: {
                            brand: data.brand,
                            last4: data.last4,
                            exp_month: data.exp_month,
                            exp_year: data.exp_year
                        }
                    }
                    setActivePayment(activePaymentTemp)
                }
                setSavePaymentLink(data.save_payment_link)
            }
            
            setIsLoading(false)
        }

        getPaymentMethod()
    }, []);

    function handleClick(){
        window.location.href = savePaymentLink
    }

    return (
        <>
        {isLoading ?
            <Loading size={'large'} color={'brown'}/>
            : !paymentsEnabled ? 
                <PaymentsNotConfigured configurePaymentsLink={configurePaymentsLink}/>
                :
            
                <div>
                    <h3>Active Payment Method</h3>
                    <hr></hr>
                    <div style={{display: 'flex'}}>
                        <div className="payment-method">
                            {activePayment?.type === 'card'
                            ?
                                <div className="col">
                                    <div className="icon"><svg style={{fill: '#919397', height: '35px'}} xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 576 512"><path d="M512 80c8.8 0 16 7.2 16 16v32H48V96c0-8.8 7.2-16 16-16H512zm16 144V416c0 8.8-7.2 16-16 16H64c-8.8 0-16-7.2-16-16V224H528zM64 32C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64H512c35.3 0 64-28.7 64-64V96c0-35.3-28.7-64-64-64H64zm56 304c-13.3 0-24 10.7-24 24s10.7 24 24 24h48c13.3 0 24-10.7 24-24s-10.7-24-24-24H120zm128 0c-13.3 0-24 10.7-24 24s10.7 24 24 24H360c13.3 0 24-10.7 24-24s-10.7-24-24-24H248z"/></svg></div>
                                    <p className="first-info">{activePayment?.card?.brand.toUpperCase()}</p>
                                    <p>****{activePayment?.card?.last4}</p>
                                    <p>{activePayment?.card?.exp_month}/{activePayment?.card?.exp_year}</p>
                                </div>
                            :
                                <div className="col">
                                    <div className="icon"><svg style={{fill: '#919397', height: '35px'}} xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path d="M243.4 2.6l-224 96c-14 6-21.8 21-18.7 35.8S16.8 160 32 160v8c0 13.3 10.7 24 24 24H456c13.3 0 24-10.7 24-24v-8c15.2 0 28.3-10.7 31.3-25.6s-4.8-29.9-18.7-35.8l-224-96c-8-3.4-17.2-3.4-25.2 0zM128 224H64V420.3c-.6 .3-1.2 .7-1.8 1.1l-48 32c-11.7 7.8-17 22.4-12.9 35.9S17.9 512 32 512H480c14.1 0 26.5-9.2 30.6-22.7s-1.1-28.1-12.9-35.9l-48-32c-.6-.4-1.2-.7-1.8-1.1V224H384V416H344V224H280V416H232V224H168V416H128V224zM256 64a32 32 0 1 1 0 64 32 32 0 1 1 0-64z"/></svg></div>
                                    <p className="first-info">{activePayment?.us_bank_account?.bank_name}</p>
                                    <p>{activePayment?.us_bank_account?.account_type}</p>
                                    <p>****{activePayment?.us_bank_account?.last4}</p>
                                </div>  
                            }
                            
                            <div className="col">
                                <button onClick={handleClick} className="buttonMain">Change</button>
                            </div>
                        </div>
                    </div>    
                </div>
            }
        </>
    )
}