import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faConciergeBell, faCheck } from "@fortawesome/free-solid-svg-icons";
import React, { useState} from "react";

import "./helper-pages.css"

function Support() {
    const [textCopied, setTextCopied] = useState(false);

    function handleClick(){
        navigator.clipboard.writeText("support@dentalworks.io")
        setTextCopied(true)

        setTimeout(() => {
            setTextCopied(false)
        }, 2500);
    }

    return (
        <div className="demo-mask">
            <div className="demo-container">
                <div>
                    <h2>Support</h2>
                    <div>
                        <FontAwesomeIcon icon={faConciergeBell} />
                    </div>
                    <p>
                        Need help with something on your DentalWORKS account? Email us at the address below and we'll get back to you and soon as possible!<br></br>
                        <br></br>
                    </p>
                    <p className="shareable-link-box">
                        <a className="support-email-link" href="mailto:support@dentalworks.io">support@dentalworks.io</a>
                    </p>
                    <button className="helper-copy-text-button" onClick={handleClick}>{ textCopied ? <FontAwesomeIcon className="helper-check-icon" icon={faCheck}/> : "Copy" }</button>
                </div>
            </div>
        </div>
    );
}


export default Support;
