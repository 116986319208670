import React from 'react'
import { Redirect, Route } from 'react-router-dom'
import {isTokenLive} from '../../../util/auth/auth'

const AuthRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      isTokenLive() ? (
        <Redirect to={{pathname: "/"}} />
      ) : (
        <Component {...props} />
      )
    }
  />
)

export default AuthRoute