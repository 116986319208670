import { formatFreelancerType, formatJobDate, formatJobTime } from "./JobItem"
import { React, useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faCheckCircle, faInfoCircle, faFileContract } from "@fortawesome/free-solid-svg-icons";
import { faCircle } from "@fortawesome/free-regular-svg-icons";
import Loading from "../../other/Loading";
import { DELETE, POST } from "../../../util/api/api";
import { ApplicantRow } from "./ApplicantRow";
import { OfficeHeadingOptions } from "./JobItem";
import { ReviewAndCompletionStep } from "../Reviews/ReviewAndCompletion";

export function goToMessageUser(user) {
    if (!user._id){
        alert('an unknown error occurred. Please try again later.')
    }
    window.location.href = `/messages?id=${user._id}`
}

export function getJobHoursWorked(job, additionalHours, activeTab){
    const startTime = new Date(job.start_time)
    const endTime = new Date(job.end_time)
    let hoursWorked = (endTime.getTime() - startTime.getTime())/(1000 * 60 * 60)
    if (activeTab === 'completed' && job.adjusted_hours_amt){
        hoursWorked = hoursWorked + job.adjusted_hours_amt
        
    } else if (activeTab !== 'completed' && additionalHours){
        hoursWorked = hoursWorked + additionalHours
    }
    hoursWorked = Math.round(hoursWorked * 100) / 100
    return hoursWorked
}

export function getWagesToFreelancer(job, additionalHours, activeTab){
    const result = getJobHoursWorked(job, additionalHours, activeTab) * job.pay_rate
    return parseFloat(result.toFixed(2))
}

export function getPlatformFees(job, additionalHours, activeTab){
    let result = getWagesToFreelancer(job, additionalHours, activeTab) * 0.08 + 0.31
    return parseFloat(result.toFixed(2))
}

export function getJobBoostFee(job){
    return job.job_boosted ? parseFloat(job.job_boosted_amt.toFixed(2)) : 0
}

export function getAmountToChargeOffice(job, additionalHours, activeTab){
    const freelancerAmt = getWagesToFreelancer(job, additionalHours, activeTab)
    const platformFees = getPlatformFees(job, additionalHours, activeTab)
    const boostJobFee = getJobBoostFee(job)
    // return parseFloat((freelancerAmt + platformFees + boostJobFee).toFixed(2))
    return parseFloat((freelancerAmt).toFixed(2))
}

export function JobView(props) {

    const {job, user, activeTab, setUserSpotlight, setSpotlightActive, changeTab} = props

    const [isLoading, setIsLoading] = useState(false);
    const [userIsInApplicantList, setUserIsInApplicantList] = useState(true);
    const [isInReviewAndCompletionPhase, setIsInReviewAndCompletionPhase] = useState(false)
    const [additionalHours, setAdditionalHours] = useState(0);

    const isOffice = user.user_type === 'office'

    useEffect(() => {
        if (job?.applicants?.find((obj) => obj?.toString() === user?._id?.toString() || obj?._id?.toString() === user?._id?.toString())){
            setUserIsInApplicantList(true)
        } else {
            setUserIsInApplicantList(false)
        }
    }, []);

    function goToAddressInMaps(){
        const url = `https://maps.google.com/?q=${job?.office_id?.location?.address?.address}, ${job?.office_id?.location?.address?.city}, ${job?.office_id?.location?.address?.state}, ${job?.office_id?.location?.address?.zip}`
        window.open(url, '_blank');
    }

    async function applyToJob(){
        try {
            setIsLoading(true)
            const body = {
                id: job._id
            }
            const data = await POST(`/api/jobs/apply`, body);
    
            if (data.status === 200){
                setUserIsInApplicantList(true)
            }
            
            setIsLoading(false);
        } catch (err){
            console.log(err)
            alert('An error occurred')
        }
    }

    async function removeApplication(){
        try {
            setIsLoading(true)
            const data = await DELETE(`/api/jobs/applicants/${job._id}`);

            if (data.status === 200){
                changeTab('applied')
            }
            
            setIsLoading(false);
        } catch (err){
            console.log(err)
            alert('An error occurred')
        }
    }

    async function leaveReview(){
        setIsInReviewAndCompletionPhase(true)
    }

    async function adjustHours(e){
        if (e && e.target && typeof e.target.value === "string"){
            const val = Number(e.target.value)
            if (val + getJobHoursWorked(job) < 0){
                alert('you cannot adjust job hours to be less than the total amount of hours worked.')
                return
            }
            setAdditionalHours(val)
        }
    }

    function OfficeButtonOptions(){
        let buttonActions

        if (activeTab === 'unfilled'){
            buttonActions = 
                <>
                    <div className="applicant-heading">
                        <h3>Applicants</h3>
                    </div>

                    {job?.applicants?.length ? job?.applicants?.map((applicant, i) => 
                        <ApplicantRow key={i} job={job} applicant={applicant} changeTab={changeTab} activeTab={activeTab} setUserSpotlight={setUserSpotlight} setSpotlightActive={setSpotlightActive}/>
                    )
                    : <p className="empty-applicants">No applicants yet</p>
                    }
                </>
        } else if (activeTab === 'filled' || activeTab === 'completed'){
            buttonActions = 
                <>
                    <div className="applicant-heading">
                        <h3>Payment</h3>
                    </div>
                    <div>
                    <div className="columns payment-info" style={{marginTop: '25px', marginBottom: '10px'}}>
                        <div className="column">
                            <div style={{marginTop: "-3px"}}>
                                <div className="job-detail-label">Hours Worked</div>
                                <div className="job-detail-label">Pay Rate</div>
                                <div className="job-detail-label">{activeTab === 'completed' ? "Adjusted Hours" : "Adjust Hours"}
                                    <FontAwesomeIcon className='info-icon' icon={faInfoCircle}/>
                                    <div className="info-popup-container">
                                        <span className="info-popup">Adjust the total hours that a temp has worked. If the temp worked through lunch, left early, or stayed late then you can add or subtract time to reflect the accurate number of hours</span>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className="job-detail-data pill-info job-datetime-pill">{activeTab === 'completed' ? getJobHoursWorked(job) : getJobHoursWorked(job, additionalHours, activeTab)}</div>
                                <div className="job-detail-data pill-info job-datetime-pill">{'$' + job?.pay_rate + "/hr"}</div>
                                {activeTab === 'completed' 
                                    ? <div className="job-detail-data pill-info job-datetime-pill">{job?.adjusted_hours_amt ? job.adjusted_hours_amt : 0}</div>
                                    : <div className="job-detail-data additional-hours"><input type="number" step="0.25" value={additionalHours} onChange={adjustHours}></input></div>
                                }
                            </div>
                        </div>
                        <div className="column">
                            <div style={{marginTop: "-3px"}}>
                                <div className="job-detail-label">Wages</div>
                                <div className="job-detail-label">Service Fee
                                    <FontAwesomeIcon className='info-icon' icon={faInfoCircle}/>
                                    <div className="info-popup-container">
                                        <span style={{left: '-50px', top: '-105px'}} className="info-popup">This is the total amount that dentalWORKS will bill your account for. This charge will happen automatically using your payment method on file after the shift is completed.</span>
                                    </div>
                                </div>
                                {job?.job_boosted_amt ? <div className="job-detail-label">Job Boost Fee</div> : ""}
                                <div className="job-detail-label">Due to Temp
                                    <FontAwesomeIcon className='info-icon' icon={faInfoCircle}/>
                                    <div className="info-popup-container">
                                        <span style={{left: '-50px', top: '-90px;'}} className="info-popup">This is the total amount due to the temp. You are responsible for paying them this amount at job completion.</span>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className="job-detail-data pill-info job-datetime-pill">${getWagesToFreelancer(job, additionalHours, activeTab)}</div>
                                <div className="job-detail-data pill-info job-datetime-pill">${getPlatformFees(job, additionalHours, activeTab)}</div>
                                {job?.job_boosted_amt ? <div className="job-detail-data pill-info job-datetime-pill">${getJobBoostFee(job)}</div> : ""}
                                <div className="job-detail-data pill-info job-datetime-pill total-amt job-pay-pill">${getAmountToChargeOffice(job, additionalHours, activeTab)}</div>
                            </div>
                        </div>
                    </div>
                    </div>
                    <div className="applicant-heading">
                        <h3>Accepted Applicant</h3>
                    </div>
                    <ApplicantRow 
                        job={job}
                        applicant={job?.freelancer_id} 
                        changeTab={changeTab} 
                        activeTab={activeTab} 
                        setUserSpotlight={setUserSpotlight} 
                        setSpotlightActive={setSpotlightActive}
                        setIsInReviewAndCompletionPhase={setIsInReviewAndCompletionPhase}
                    />
                </>
        }

        return (
            buttonActions
        )
    }

    function FreelancerPaymentDetails(){
        return (
            <>
                <div className="applicant-heading">
                    <h3>Payment</h3>
                </div>
                <div>
                    <div className="columns payment-info" style={{marginTop: '25px', marginBottom: '10px'}}>
                        <div className="column">
                            <div style={{marginTop: "-3px"}}>
                                <div className="job-detail-label">Hours Worked</div>
                                <div className="job-detail-label">Pay Rate</div>
                                {activeTab === 'completed' ?
                                    <div className="job-detail-label">Adjusted Hours
                                        <FontAwesomeIcon className='info-icon' icon={faInfoCircle}/>
                                        <div className="info-popup-container">
                                            <span className="info-popup">Hours can be adjusted by the dental practice to account for same day changes to the original agreed upon shift. Please contact support if you feel that this field was filled out inaccurately.</span>
                                        </div>
                                    </div>
                                    : ""
                                }

                            </div>
                            <div>
                                <div className="job-detail-data pill-info job-datetime-pill">{getJobHoursWorked(job)}</div>
                                <div className="job-detail-data pill-info job-datetime-pill">{'$' + job?.pay_rate + "/hr"}</div>
                                {activeTab === 'completed' ?
                                    <div className="job-detail-data pill-info job-datetime-pill">{job?.adjusted_hours_amt ? job?.adjusted_hours_amt : 0}</div>
                                    : ""
                                }
                            </div>
                        </div>
                        <div className="column">
                            <div style={{marginTop: "-3px"}}>
                                <div className="job-detail-label">Wages</div>
                                <div className="job-detail-label">Total Payout
                                    <FontAwesomeIcon className='info-icon' icon={faInfoCircle}/>
                                    <div className="info-popup-container">
                                        <span style={{top: '-79px', left: '-37px'}} className="info-popup">Payments are paid out by the dental office after job completion.</span>
                                    </div>
                                </div>
                                
                            </div>
                            <div>
                                <div className="job-detail-data pill-info job-datetime-pill">${getWagesToFreelancer(job, 0, activeTab)}</div>
                                <div className="job-detail-data pill-info job-datetime-pill total-amt job-pay-pill">${getWagesToFreelancer(job, 0, activeTab)}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }

    function FreelancerButtonOptions(){
        let buttonActions

        if (activeTab === 'search'){

            if (user.verification !== 'verified') {
            // if (user.verification !== 'verified' || !user.stripe_enabled){
                buttonActions = 
                <div className="freelancer-button-container">
                    <p className="button-actions-apply">Before you can apply to jobs, you need to complete the following actions:</p>
                    <div className="pre-apply-tasks-container">
                        {/* <p className={user.stripe_enabled ? 'task-completed' : ''}>
                            {user.stripe_enabled
                            ? <FontAwesomeIcon className='complete-task-icon' style={{color: 'green'}} icon={faCheckCircle} /> 
                            : <FontAwesomeIcon className='complete-task-icon' icon={faCircle} />}&nbsp; Set up banking information for payouts. Click <a style={{textDecoration: "underline"}} href="/payments">here</a> to set that up.</p> */}
                        <p className={user.verification === 'pending' || user.verification === 'verified' ? 'task-completed' : ''}>
                            {user.verification === 'pending' || user.verification === 'verified'
                            ? <FontAwesomeIcon className='complete-task-icon' style={{color: 'green'}} icon={faCheckCircle} />
                            : <FontAwesomeIcon className='complete-task-icon' icon={faCircle} />}&nbsp; Submit your ID {user.expertise === 'Registered Dental Hygienist (RDH)' ? 'and Dental License Number' : ''}. Click <a style={{textDecoration: "underline"}} href="/onboard">here</a> to submit that info.</p>
                        <p className={user.verification === 'verified' ? 'task-completed' : ''}>
                            {user.verification === 'verified'
                            ? <FontAwesomeIcon className='complete-task-icon' style={{color: 'green'}} icon={faCheckCircle} />
                            : <FontAwesomeIcon className='complete-task-icon' icon={faCircle} />}&nbsp; Wait to get your ID {user.expertise === 'Registered Dental Hygienist (RDH)' ? 'and Dental License Number ' : ''} verified (usually this only takes a couple hours after submitting).</p>
                    </div>
                </div>
            } else if (userIsInApplicantList) {
                buttonActions = <div className="freelancer-button-container"><p className="button-actions-apply" style={{color: "#008655"}}>Applied<FontAwesomeIcon className="applied-icon" icon={faCheck} /></p></div>
            } else {
                buttonActions = <div className="freelancer-button-container">
                    {job.status === 'filled' || job.status === 'completed' || new Date() > new Date(job.start_time) ?
                        <p style={{padding: '20px'}}>Job has been filled or start date has already passed.</p>
                        :
                        <button onClick={applyToJob} className="buttonMain">Apply</button>
                    }
                </div>
            }
        } else if (activeTab === 'applied'){
            buttonActions = <div className="freelancer-button-container">
                <button onClick={() => goToMessageUser(job.office_id)} className="buttonMain message-office-button freelancer-button-actions">Send Message</button>
                <button onClick={removeApplication} className="buttonMain remove-application freelancer-button-actions">Remove Application</button>
            </div>
        } else if (activeTab === 'accepted'){
            buttonActions = <>
                <FreelancerPaymentDetails />
                <div className="freelancer-button-container"><button onClick={() => goToMessageUser(job.office_id)} className="buttonMain message-office-button freelancer-button-actions">Send Message</button></div>
            </>
        } else if (activeTab === 'completed'){
            if (job.freelancer_review){
                buttonActions = <>
                    <FreelancerPaymentDetails />
                    <div className="freelancer-button-container"><p style={{color: "#008655", margin: "25px"}}>Job Complete<FontAwesomeIcon className="applied-icon" icon={faCheck} /></p></div>
                </>
            } else {
                buttonActions = <>
                    <FreelancerPaymentDetails />
                    <div className="freelancer-button-container"><button onClick={leaveReview} className="buttonMain">Leave Review</button></div>
                </>
            }
            
        }

        return (
            <>
                {buttonActions}
            </>
        )
    }

    return (
        isInReviewAndCompletionPhase
            ? (<ReviewAndCompletionStep user={user} job={job} changeTab={changeTab} additionalHours={additionalHours}/>)
            :
            <div className="job-container job-view-container">
                <div className="job-view-inner-container">
                    <h3>{formatFreelancerType(job?.freelancer_type)} &nbsp; | &nbsp; <OfficeHeadingOptions job={job} user={user} activeTab={activeTab} setSpotlightActive={setSpotlightActive} setUserSpotlight={setUserSpotlight}/></h3>
                    <hr></hr>
                    <p className="long-text">{job?.description}</p>
                    <hr></hr>
                    <div className="columns">
                        <div className="column">
                            <div style={{marginTop: "-3px"}}>
                                <div className="job-detail-label">Date</div>
                                <div className="job-detail-label">Hours</div>
                                <div className="job-detail-label">Pay
                                    <FontAwesomeIcon className='info-icon' icon={faInfoCircle}/>
                                    <div className="info-popup-container">
                                        <span style={{top: '-120px'}} className="info-popup">This is the hourly wage and the employment type the office will use to pay for this job. Jobs listed as W2/1099 allow the temp to decide whichever employment type is best for them.</span>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className="job-detail-data pill-info job-datetime-pill">{formatJobDate(job?.start_time)}</div>
                                <div className="job-detail-data pill-info job-datetime-pill">{formatJobTime(job?.start_time)} - {formatJobTime(job?.end_time)}</div>
                                <div>
                                    <div style={{display: "inline-block"}} className="job-detail-data pill-info job-pay-pill">{'$' + job?.pay_rate + "/hr"}</div>
                                    <div style={{display: "inline-block", marginLeft: '0'}} className="job-detail-data pill-info job-datetime-pill">
                                        <FontAwesomeIcon style={{marginRight: '5px'}} icon={faFileContract}/>
                                        {job.employment_type === 'either' ? 'W2/1099' : job.employment_type ? job.employment_type : 'W2/1099'}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="column">
                            <div style={{marginTop: "-3px"}}>
                                <div className="job-detail-label">Office Type</div>
                                <div className="job-detail-label">No. of Operatories</div>
                                <div className="job-detail-label">Location</div>
                            </div>
                            <div>
                                <div className="job-detail-data pill-info job-datetime-pill">{job?.office_id?.office_type}</div>
                                <div className="job-detail-data pill-info job-datetime-pill">{job?.office_id?.operatories_amt}</div>
                                <div onClick={goToAddressInMaps} className="job-detail-data pill-info job-location-pill">
                                    <div>{activeTab === 'accepted' || activeTab === 'completed' || user.user_type === 'office' ? job?.office_id?.location?.address?.address : ''}</div>
                                    <div>{job?.office_id?.location?.address?.city + ", " + job?.office_id?.location?.address?.state + " " + job?.office_id?.location?.address?.zip}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr></hr>
                    <p className="long-text"><b>Bio:</b> {job?.office_id?.bio}</p>
                </div>
                <div className='button-container'> 
                {isLoading 
                    ? <Loading color="brown"/> 
                    : isOffice
                        ? <OfficeButtonOptions />
                        : <FreelancerButtonOptions />  
                }
                </div>
            </div>
    )
}