import { JobItem } from "./JobItem"
import logo from "../../../images/peopleicons.png";
import { React, useState, useEffect } from "react";
import { useLocation, useHistory } from 'react-router-dom';
import { GET } from "../../../util/api/api"
import { JobPopup } from "./JobPopup";

export function JobsList(props){
    const {jobs, user, activeTab, changeTab, emptyMessage, spotlightActive, setUserSpotlight, setSpotlightActive} = props
    const location = useLocation();
    const history = useHistory();
    const [activeJobOnMount, setActiveJobOnMount] = useState(false);
    const [viewPopupActive, setViewPopupActive] = useState(true);

    useEffect(() => {
        async function getJob() {
            const searchParams = new URLSearchParams(location.search);
            const jobID = searchParams.get('job_id')

            try {
                if (jobID){
                    const {data} = await GET(`/api/jobs/all/${jobID}`)
    
                    if (!data) return
    
                    setActiveJobOnMount(data)
                    setViewPopupActive(true)
                    
                    if (user.user_type === 'freelancer'){
                        switch (data.status) {
                            case 'unfilled':
                                changeTab('search', true); break
                            case 'filled':
                                changeTab('accepted', true); break
                            case 'completed':
                                changeTab('completed', true); break
                        }
                    } else if (user.user_type === 'office'){
                        
                        changeTab(data.status, true)
                    }
                } 
            } catch(err) {
                console.log(err)
            }
            
        }

        getJob();
    }, []);

    function EmptyResults() {
        return (
            <div className="empty-results-container">
                <div style={{display: 'flex', flexDirection: 'column', marginTop: '220px'}} className="empty-results">
                    <img style={{width: '90px', filter: 'grayscale(0.9)', margin: 'auto'}} src={logo}></img>
                    <p style={{textAlign: 'center', marginTop: '20px'}}>{emptyMessage}</p>
                </div>
            </div>
        );
    }

    function closeActiveJobOnMountPopup(){
        const { pathname } = location;
        history.push({ pathname });
        setViewPopupActive(false)
    }

    return (
        <section id="jobs-list">

            {jobs.length 
                ? <>
                    {activeJobOnMount && viewPopupActive ? <JobPopup view={'view'} setPopupActive={closeActiveJobOnMountPopup} job={activeJobOnMount} user={user} activeTab={activeTab} changeTab={changeTab} spotlightActive={spotlightActive} setUserSpotlight={setUserSpotlight} setSpotlightActive={setSpotlightActive}/> : ''}
                    {jobs.map((job, i) => (
                        <JobItem key={i} job={job} user={user} activeTab={activeTab} changeTab={changeTab} spotlightActive={spotlightActive} setUserSpotlight={setUserSpotlight} setSpotlightActive={setSpotlightActive}/>
                    ))}
                </> : <>
                    {activeJobOnMount && viewPopupActive ? <JobPopup view={'view'} setPopupActive={closeActiveJobOnMountPopup} job={activeJobOnMount} user={user} activeTab={activeTab} changeTab={changeTab} spotlightActive={spotlightActive} setUserSpotlight={setUserSpotlight} setSpotlightActive={setSpotlightActive}/> : ''}
                    <EmptyResults />
                </>
            }
        </section>
    )
}