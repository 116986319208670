import "./profilePicPlaceholder.css"

export function ProfilePicPlaceholder(props) {
    const {user, size} = props;

    if (size === "in-app-small"){
        return <div className="profile-pic-placeholder-standard-small">
            { user.user_type === 'freelancer' && user.first_name ? user?.first_name?.charAt(0).toUpperCase() : user?.office_name?.charAt(0).toUpperCase()}
        </div>
    }

   return <div className={size === "header" ? "profile-pic-placeholder header-pic" : "profile-pic-placeholder"}>
            { user.user_type === 'freelancer' && user.first_name ? user?.first_name?.charAt(0).toUpperCase() : user?.office_name?.charAt(0).toUpperCase()}
        </div> 
}



