import React, { useState, useEffect } from "react";
import { Redirect, Route, useLocation } from "react-router-dom";
import axios from "axios";
import { apiDomain } from "../../../util/constants";
import { isTokenLive, deleteToken } from "../../../util/auth/auth";
import Header from "../../Header";
import { Userpilot } from "userpilot";

const PrivateRoute = ({ component: Component, ...rest }, props) => {
    const [isAuthorized, setAuthorized] = useState(undefined);
    const [isOnboarded, setOnboarded] = useState(undefined);
    const [user, setUser] = useState(undefined);
    const [width, setWidth] = useState(window.innerWidth);
    const location = useLocation();

    useEffect(() => {
        Userpilot.reload();
    }, [location]);

    useEffect(() => {
        async function go() {
            try {
                if (!isTokenLive()) {
                    deleteToken();
                    window.location.href = "/login";
                    return;
                }

                const data = await axios.get(`${apiDomain}/api/user`);

                if (data.status === 200) {
                    setAuthorized(true);
                    setUser(data.data)
                    Userpilot.identify(data.data._id, {
                        user_id: data.data._id,
                        created_at: data.data.created_date,
                        name: `${data.data.first_name ? data.data.first_name : ''} ${data.data.last_name ? data.data.last_name : ''}`,
                        company: data.data.org_id && data.data.org_id.name ? data.data.org_id.name : '',
                        title: data.data.title,
                        onboarding_complete: data.data.onboarding_complete,
                    });
                }

                if (data.data.onboarding_complete) {
                    setOnboarded(true);
                } else {
                    setOnboarded(false)
                }

            } catch (err) {
                if (err.response && err.response.status === 401) {
                    setAuthorized(false);
                    deleteToken();
                    window.location.href = "/login";
                    return;
                }
            }
        }

        go();
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }
  
    let isMobile = width <= 800

    if (isAuthorized === undefined || isOnboarded === undefined) {
        return <></>;
    } else {
        return (
            <>
                <Header user={user} isMobile={isMobile}/>
                <Route
                    {...rest}
                    render={(props) =>
                        isAuthorized && isOnboarded ? (
                            <Component {...props} user={user} isMobile={isMobile}/>
                        ) : isAuthorized && !isOnboarded ? (
                            <Redirect
                                to={{
                                    pathname: "/onboard",
                                }}
                            />
                        ) : (
                            <Redirect
                                to={{
                                    pathname: "/login",
                                }}
                            />
                        )
                    }
                />
            </>
        );
    }
};

export default PrivateRoute;
