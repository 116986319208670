
import { GET, POST } from "../../util/api/api";
import "./main.css";
import { React, useState, useEffect } from "react";
import Loading from "../other/Loading";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {loadConnect} from '@stripe/connect-js';
import {ConnectPayments, ConnectComponentsProvider } from "@stripe/react-connect-js";
import { Transaction } from "./Transaction";



export function Transactions(props){
    const {txs, userType, dashboardLink} = props

    function openDashboard(){
        // window.location.href = dashboardLink
        window.open(dashboardLink, '_blank');

    }

    return (
        <>
            {userType === 'freelancer' ?
                <div className="reports-button-container">
                    <button onClick={openDashboard} className="buttonMain reports-button">Reports</button>
                </div>
            : ''}
            <div style={{paddingRight: '5px'}}>
                <h3>Payment History</h3>
                <hr></hr>

                <table>
                    <tr className="row">
                        <th>Date</th>
                        <th>Type</th>
                        <th>Description</th>
                        <th>Amount</th>
                        <th>Status</th>
                        <th>Job ID</th>
                        <th>Receipt</th>
                    </tr>
                    {txs.map((tx, i) => {
                        return (
                            tx.amount - tx.amount_refunded !== 0 ?
                                <Transaction tx={tx} userType={userType} key={i} />
                            : 
                                ""
                        )
                    })}
                </table>
                
            </div>
        </>
    )
}