import "../styles/header.css";
import logo from "../images/Layer+12.png";
import React, { useState} from "react";
import { Link, useLocation } from "react-router-dom";
import { ProfilePicPlaceholder } from "./other/profilePicPlaceholder/ProfilePicPlaceholder";

const routesWithoutHeader = ["/login", "/register", "/forgot", "/onboard", /\/reset\/(.*)-(.*)/];

function Header(props) {
    const { user, isMobile } = props;
    let location = useLocation();
    const [isDropDownOpen, setDropDownOpen] = useState(false);

    function handleClick() {
        setDropDownOpen(!isDropDownOpen);
    }

    function truncateString(str, n=20){
        return (str.length > n) ? str.slice(0, n-1) + '...' : str;
      };

    let shouldIncludeHeader = true;

    for (let i = 0; i < routesWithoutHeader.length; i++) {
        if (location.pathname.match(routesWithoutHeader[i])) {
            shouldIncludeHeader = false;
        }
    }

    return shouldIncludeHeader ? (
        <>
        <header>
            <div>
                <div className="menu-container">
                    <div style={{ display: "flex" }}>
                        <Link to="/">
                            <img className="logo" src={logo}></img>
                        </Link>
                    </div>
                    <div className="navbar desktop-only">
                        <div className="navbar-item">
                            <Link to="/messages">Messages</Link>
                        </div>
                        <div className="navbar-item">
                            <Link to="/jobs">My Jobs</Link>
                        </div>
                        {user.user_type === 'office' ?
                            <div className="navbar-item">
                                <Link to="/payments">Payments</Link>
                            </div>
                            : ""
                        }
                        
                        
                    </div>
                </div>
                <div className="profile-container">
                    {/* <div className="searchbar-container">
                        <input id="searchbar"></input>
                        <a ><i className="fas fa-search"></i></a>
                    </div> */}
                    <div id="nav-profile-image" className="profile-image" onClick={handleClick}>
                        {user.profile_img ? <img src={user.profile_img}></img> : <ProfilePicPlaceholder user={user} size={"header"} />}
                    </div>
                    {isDropDownOpen ? (
                        <div className="nav-dropdown-mask" onClick={handleClick}>
                            <div id="nav-drop-down-menu" className="drop-down-container">
                                <p className="profile-name">
                                    <strong>
                                        {user.user_type === 'office' ? truncateString(user.office_name) : user.first_name + " " + user.last_name}
                                    </strong>
                                </p>
                                <Link to="/onboard">
                                    <p>Edit Profile</p>
                                </Link>
                                <Link to="/support">
                                    <p>Support</p>
                                </Link>
                                <Link to="/share">
                                    <p>Share</p>
                                </Link>
                                <Link to="/settings">
                                    <p>Settings</p>
                                </Link>
                                {isMobile ? <>
                                    <Link to="/messages">
                                        <p className="mobile-only">Messages</p>
                                    </Link>
                                    <Link to="/jobs">
                                        <p className="mobile-only">My Jobs</p>
                                    </Link>
                                    {user.user_type === 'office' ?
                                        <Link to="/payments">
                                            <p className="mobile-only">Payments</p>
                                        </Link>
                                        : ""
                                    }
                                </> : ""}
                                <Link to="/logout">
                                    <p>Log Out</p>
                                </Link>
                            </div>
                        </div>
                    ) : (
                        ""
                    )}
                </div>
            </div>
        </header>
        </>
    ) : (
        <></>
    );
}

export default Header;
