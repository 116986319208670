import { JobForm } from "./JobForm"
import { JobView } from "./JobView"

export function JobPopup(props) {

    const {setPopupActive, job, user, view, changeTab, activeTab, spotlightActive, setUserSpotlight, setSpotlightActive, test} = props

    function closePopup(e) {
        if (e.target.id === "popup-screen" || e.target.className === "close-popup") {
            setPopupActive(false)
        }
    }

    function JobContent() {

        return (
            <>
                {view === 'edit' ? <JobForm activeTab={activeTab} isEditable={true} job={job} changeTab={changeTab} user={user}/> : ""}
                {view === 'view' ? <JobView activeTab={activeTab} user={user} job={job} changeTab={changeTab} setUserSpotlight={setUserSpotlight} setSpotlightActive={setSpotlightActive}/> : ""}
            </>
        )
    }

    return (
        <div onClick={closePopup} id="popup-screen" className={`popup-screen ${spotlightActive ? 'popup-screen-spotlight-open' : ''}`}>
            <span onClick={closePopup} className="close-popup">X</span>
            <JobContent />
        </div>
    )
}