import { useState, useEffect } from "react";
import "../../styles/other/loading.css";

function Loading(props) {
    return (
        <div className={`lds-grid ${props.color ? 'lds-grid-' + props.color : ""} ${props.size === 'small' ? 'lds-grid-small' : ''} `}>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
    );
};

export default Loading;
