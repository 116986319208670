import { LoginBoilerplate } from "../login-signup/LoginMain";
import "./onboard.css";
import { useState, useEffect } from "react";
import { apiDomain } from "../../util/constants";
import axios from "axios";
import Loading from "../other/Loading"
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import MultistepProgressBar from "./MultistepProgress";
import StateSelect from "./StateSelect";
import { FileUploader } from "react-drag-drop-files";
import { CodeOfConduct } from "./CodeOfConduct";

function OnboardStepOne(props) {
    const {setOnboardingStep, setUser, user} = props
    const [isLoading, setIsLoading] = useState(false);
    const [state, setState] = useState(false);
    const [apiError, setApiError] = useState(false);

    function handleSaveInfo() {
        setIsLoading(true)
        const formToSend = {}

        if (!state){
            formToSend.user_type = user.user_type
        } else {
            formToSend.user_type = state.user_type
        }

        axios.post(`${apiDomain}/api/user/set-user-type`, formToSend)
        .then((res) => {
            if (res.status === 200){
                setUser(res.data)
                setOnboardingStep(2)
            }
        })
        .catch((err) => {
            console.log(err)
            setApiError(true)
            setIsLoading(false)
        });
    }

    function handleInput(e) {
        setState({
            ...state,
            [e.target.name]: e.target.value,
        });
    }

    return (
        isLoading ? (
            <div className="loading-container">
                <Loading color={"yellow"}/>
            </div>
        ) :
        <div className="onboarding-container">
            {apiError ? <p style={{color: "red"}}>An error occurred. Please try again.</p> : <p className="onboarding-title">Nice to meet you!</p>} 
            <form onSubmit={handleSaveInfo} id="onboarding-form1">
                <p className="text-input-label">Who are you?*</p>
                <select name="user_type" id="dental-select" defaultValue={user.user_type} onChange={handleInput} required>
                    <option value="" selected disabled hidden>Pick One!</option>
                    <option value="freelancer">Dental Temp</option>
                    <option value="office">Dental Office</option>
                </select>
            </form>
            <button type="submit" form="onboarding-form1" className="buttonMain">
                Next
            </button>
        </div>
    )
}

function OnboardStepTwo(props) {
    const {user, setUser, setOnboardingStep} = props
    const [isLoading, setIsLoading] = useState(false);
    const [state, setState] = useState({});
    const [formToSend, setFormToSend] = useState(new FormData());
    const [apiError, setApiError] = useState(false);

    function handleInput(e) {
       
        if (e.label && e.value){
            e.target = {}
            e.target.value = e.value
        }

        setState({
            ...state,
            [e.target.name]: e.target.value,
        });
    }

    function handleSaveInfo() {
        setIsLoading(true)

        if (user.user_type === "office"){
            if ((!(user.office_name || state.office_name) || state.office_name === '') ||
                (!(user.office_type || state.office_type) || state.office_type === '') ||
                (!(user.operatories_amt || state.operatories_amt) || state.operatories_amt === '') ||
                !(user?.location?.address?.address || state.address) ||
                !(user?.location?.address?.city || state.city) ||
                !(user?.location?.address?.state || state.state) ||
                !(user?.location?.address?.zip || state.zip)
            ) {
                alert('Please fill out all fields before continuing.')
                setIsLoading(false)
                return 
            }
        } else {
            if ((!(user.first_name || state.first_name) || state.first_name === '') ||
                (!(user.last_name || state.last_name) || state.last_name === '') ||
                (!(user.expertise || state.expertise) || state.expertise === '') ||
                (!(user.years_of_experience || state.years_of_experience) || state.years_of_experience === '') ||
                !(user?.location?.address?.state || state.state) ||
                !(user?.location?.address?.zip || state.zip)
            ) {
                alert('Please fill out all fields before continuing.')
                setIsLoading(false)
                return 
            }
        }

        for (const key in state) {
            formToSend.set(key, state[key]);
        }

        axios.post(`${apiDomain}/api/user/update`, formToSend)
        .then((res) => {
            if (res.status === 200){
                if (res.data){
                    setUser(res.data)
                }
                setOnboardingStep(3)
            }
        })
        .catch((err) => {
            console.log(err)
            setApiError(true)
            setIsLoading(false)
        });
    }

    return (
        isLoading ? (
            <div className="loading-container">
                <Loading color={"yellow"}/>
            </div>
        ) :
        <div className="onboarding-container">
             {apiError ? <p style={{color: "red"}}>An error occurred. Please try again.</p> :<p className="onboarding-title">We just need a little bit of info to get started.</p>}

            {user.user_type === 'office' ?
                <>
                    <form id="onboarding-form2-1">
                        <p className="text-input-label">Office Name*</p>
                        <input className="text-input" type="text" name="office_name" onChange={handleInput} defaultValue={user.office_name} required></input>
                        <p className="text-input-label">Type of Office*</p>
                        <select name="office_type" id="dental-select" defaultValue={user.office_type} onChange={handleInput} required>
                            <option value="" selected disabled hidden></option>
                            <option value="General Dentistry">General Dentistry</option>
                            <option value="Pediatric Dentistry">Pediatric Dentistry</option>
                            <option value="Periodontics">Periodontics</option>
                            <option value="Oral Surgery">Oral Surgery</option>
                            <option value="Prosthodontics">Prosthodontics</option>
                            <option value="Orthodontics">Orthodontics</option>
                            <option value="Endodontics">Endodontics</option>
                        </select>
                        <p className="text-input-label">Number of Operatories*</p>
                        <input className="text-input" type="number" name="operatories_amt" min="0" onChange={handleInput} defaultValue={user.operatories_amt} required></input>
                        <p className="text-input-label">Address*</p>
                        <input className="text-input" type="text" name="address" onChange={handleInput} defaultValue={user.location && user.location.address && user.location.address.address ? user.location.address.address : ''} required></input>
                        <p className="text-input-label">City*</p>
                        <input className="text-input" type="text" name="city" onChange={handleInput} defaultValue={user.location && user.location.address && user.location.address.city ? user.location.address.city : ''} required></input>
                        <p className="text-input-label">State*</p>
                        <select name="state" defaultValue={user.location && user.location.address && user.location.address.state ? user.location.address.state : ''} id="state-select-office" onChange={handleInput} required>
                            <StateSelect/>
                        </select>
                        <p className="text-input-label">Zip*</p>
                        <input className="text-input" type="text" name="zip" onChange={handleInput} defaultValue={user.location && user.location.address && user.location.address.zip ? user.location.address.zip : ''} required></input>
                    </form>
                    <button onClick={handleSaveInfo} className="buttonMain">
                        Next
                    </button>
                </>
            :    
                <>
                    <form id="onboarding-form2-2">
                        <p className="text-input-label">First Name*</p>
                        <input className="text-input" type="text" name="first_name" onChange={handleInput} defaultValue={user.first_name} required></input>
                        <p className="text-input-label">Last Name*</p>
                        <input className="text-input" type="text" name="last_name" onChange={handleInput} defaultValue={user.last_name} required></input>
                        <p className="text-input-label">Area of Expertise*</p>
                        <select name="expertise" defaultValue={user.expertise ? user.expertise : ''} id="expertise-select" onChange={handleInput} required>
                            <option value="" selected disabled hidden> </option>
                            <option value="Registered Dental Hygienist (RDH)">Registered Dental Hygienist (RDH)</option>
                            <option value="Dental Assistant (DA)">Dental Assistant (DA)</option>
                            <option value="Front Desk (FD)">Front Desk (FD)</option>
                        </select>
                        <p className="text-input-label">Years of Experience*</p>
                        <input className="text-input" type="number" max="99" name="years_of_experience" onChange={handleInput} defaultValue={user.years_of_experience} required></input>
                        <p className="text-input-label">State*</p>
                        <select name="state" defaultValue={user.location && user.location.address && user.location.address.state ? user.location.address.state : ''} id="state-select" onChange={handleInput} required>
                            <StateSelect/>
                        </select>
                        <p className="text-input-label">Zip*</p>
                        <input className="text-input" type="text" name="zip" onChange={handleInput} defaultValue={user.location && user.location.address && user.location.address.zip ? user.location.address.zip : ''} required></input>
                    </form>
                    <button onClick={handleSaveInfo} className="buttonMain">
                        Next
                    </button>
                </>
            }    
        </div>
    )
}

function OnboardStepThree(props) {
    const {user, setUser, setOnboardingStep} = props
    const [isLoading, setIsLoading] = useState(false);
    const [state, setState] = useState({});
    const [apiError, setApiError] = useState(false);
    const [formToSend, setFormToSend] = useState(new FormData());
    const [uploadedProfileImage, setUploadedProfileImage] = useState(false);
    const [uploadedLicenseImage, setUploadedLicenseImage] = useState(false);
    const fileTypes = ["JPG", "JPEG", "PNG"];

    useEffect(() => {
        if (user && user.user_type === 'freelancer' && user.cell_phone_number){
            setCellPhoneNumber(user.cell_phone_number)
        } else if (user && user.user_type === 'office' && user.phone_number) {
            setPhoneNumber(user.phone_number)
        }

        if (user && user.id_img){
            setUploadedLicenseImage(user.id_img)
        }

        if (user && user.profile_img){
            setUploadedProfileImage(user.profile_img)
        }

    }, []);

    function handleInput(e) {
       
        if (e.label && e.value){
            e.target = {}
            e.target.value = e.value
        }

        setState({
            ...state,
            [e.target.name]: e.target.value,
        });
    }

    function handleImageUpload(file, name) {
        if (name === 'license'){
            setUploadedLicenseImage(file.name)
        } else if (name === 'profile'){
            setUploadedProfileImage(file.name)
        }

        formToSend.set(name, file);
    }

    function setCellPhoneNumber(cell_phone_number){
        setState({
            ...state,
            "cell_phone_number": cell_phone_number,
        })
    }

    function setPhoneNumber(phone_number){
        setState({
            ...state,
            "phone_number": phone_number,
        })
    }

    function handleSaveInfo() {
        setIsLoading(true)

        for (const key in state) {
            formToSend.set(key, state[key]);
        }

        if (user.user_type === "office"){
            if (!(user.phone_number || state.phone_number) || state.phone_number === '') {
                alert('Please fill out all fields before continuing.')
                setIsLoading(false)
                return 
            }
        } else {
            if (!(user.cell_phone_number || state.cell_phone_number) || state.cell_phone_number === '') {
                alert('Please fill out all fields before continuing.')
                setIsLoading(false)
                return 
            }
        }

        if ((state.cell_phone_number && state.cell_phone_number.length > 0 && state.cell_phone_number.length < 10) 
        || (state.phone_number && state.phone_number.length > 0 && state.phone_number.length < 10)){
            alert("please enter a valid, 10-digit phone number")
            setIsLoading(false)
            return
        }

        axios.post(`${apiDomain}/api/user/update`, formToSend)
        .then((res) => {
            if (res.status === 200){
                if (user.onboarding_complete){
                    window.location.href = "/"
                } else {
                    setOnboardingStep(4)
                }
            }
        })
        .catch((err) => {
            console.log(err)
            setApiError(true)
            setIsLoading(false)
        });
    }

    function ImageUploaderText(props){
        const {imageText} = props

        return (
            <div className="custom-upload-text-container">
                {imageText
                    ? <span className="custom-upload-text custom-upload-text-left"><u>Image added!</u> &nbsp; {imageText}</span>
                    : <span className="custom-upload-text custom-upload-text-left"><u>Upload</u> or drop image right here!</span>
                }      
                    <span className="custom-upload-text custom-upload-text-right">JPG, PNG</span>
            </div>
        )
    }

    return (
        isLoading ? (
            <div className="loading-container">
                <Loading color={"yellow"}/>
            </div>
        ) :
        <div className="onboarding-container">
            {apiError ? <p style={{color: "red"}}>An error occurred. Please try again.</p> : <p className="onboarding-title">Final details!</p>}
            {user.user_type === 'office' ?
                <>
                    <form id="onboarding-form3-1">
                        <p className="text-input-label">Phone Number*</p>
                        <PhoneInput 
                            inputProps={{
                                name: 'phone_number',
                                required: true
                            }}
                            placeholder={"(801) 123-4567"}
                            value={state.phone_number}
                            country={'us'}
                            onlyCountries={['us']}
                            disableCountryCode={true}
                            onChange={phone_number => setPhoneNumber(phone_number)}/>
                        <p className="text-input-label">Profile Picture</p>
                        <FileUploader classes="image-uploader" handleChange={(file) => handleImageUpload(file, 'profile')} name="profile" types={fileTypes}>
                            <ImageUploaderText imageText={uploadedProfileImage}/>
                        </FileUploader>
                        <p className="text-input-label">Bio (Tell hygienists about what working here is like!)</p>
                        <textarea className="text-input" type="text" name="bio" onChange={handleInput} defaultValue={user.bio}></textarea>
                    </form>
                    <button onClick={handleSaveInfo} className="buttonMain">
                        Save
                    </button>
                </>
                :
                <>
                    <form id="onboarding-form3-2">
                        <p className="text-input-label">Cell Phone Number*</p>
                        <PhoneInput 
                            inputProps={{
                                name: 'cell_phone_number',
                                required: true
                            }}
                            placeholder={"(801) 123-4567"}
                            value={state.cell_phone_number}
                            country={'us'}
                            onlyCountries={['us']}
                            disableCountryCode={true}
                            onChange={cell_phone_number => setState({
                                ...state,
                                "cell_phone_number": cell_phone_number,
                            })}/>

                         {user.expertise === 'Registered Dental Hygienist (RDH)' ? 
                            <>
                                <p className="text-input-label">Dental License Number</p>
                                <input className="text-input" type="text" name="dental_license" onChange={handleInput} defaultValue={user.dental_license}></input>
                            </>
                            : ""
                        }
                        <p className="text-input-label">Picture of Drivers License</p>
                        <FileUploader classes="image-uploader image-uploader-license" handleChange={(file) => handleImageUpload(file, 'license')} name="license" types={fileTypes}>
                            <ImageUploaderText imageText={uploadedLicenseImage}/>
                        </FileUploader>
                        <p className="text-input-label">Profile Picture</p>
                        <FileUploader classes="image-uploader image-uploader-profile" handleChange={(file) => handleImageUpload(file, 'profile')} name="profile" types={fileTypes}>
                            <ImageUploaderText imageText={uploadedProfileImage}/>
                        </FileUploader>
                        <p className="text-input-label">Bio (add anything you'd like to say more about who you are!)</p>
                        <textarea className="text-input" type="text" name="bio" onChange={handleInput} defaultValue={user.bio}></textarea>
                    </form>
                    <button onClick={handleSaveInfo} className="buttonMain">
                        Save
                    </button>
                </>
            }
        </div>
    )
}

function OnboardStepFour(props) {
    const {setOnboardingStep, setUser, user} = props
    const [isLoading, setIsLoading] = useState(false);
    const [state, setState] = useState(false);
    const [apiError, setApiError] = useState(false);

    function handleSaveInfo() {
        setIsLoading(true)

        const data = {
            agreed_to_code_of_conduct: true
        }

        axios.post(`${apiDomain}/api/user/update`, data)
        .then((res) => {
            if (res.status === 200){
                window.location.href = "/"
            }
        })
        .catch((err) => {
            console.log(err)
            setApiError(true)
            setIsLoading(false)
        });
    }

    return (
        isLoading ? (
            <div className="loading-container">
                <Loading color={"yellow"}/>
            </div>
        ) :
        <div className="onboarding-container">
            {apiError ? <p style={{color: "red"}}>An error occurred. Please try again.</p> : <p className="onboarding-title">Lastly, please review and agree to our Code of Conduct. <br></br>Scroll to bottom to agree.</p>} 
            <CodeOfConduct />
            <button onClick={handleSaveInfo} className="buttonMain">
                Agree
            </button>
        </div>
    )
}

function Onboard() {
    
    const [isLoading, setIsLoading] = useState(false);
    const [onboardingStep, setOnboardingStep] = useState(0)
    const [user, setUser] = useState("");

    useEffect(() => {
        async function go() {
            await getUser()
        }
        go();
    }, []);

    async function getUser(){
        setIsLoading(true);

        try {
            const res = await axios.get(`${apiDomain}/api/user`);
        
            setUser(res.data);
            if (res.data.onboarding_complete){
                setOnboardingStep(2);    
            } else {
                setOnboardingStep(res.data.onboarding_stage);
            }
            setIsLoading(false);
            return res.data
        } catch (err) {
            console.log(err);
        }
    }

    async function navigateOnboardingStep(step){
        const user = await getUser()
        if (step > user.onboarding_stage){
            setOnboardingStep(user.onboarding_stage)
        } else {
            setOnboardingStep(step)
        }
    }

    return (
        <LoginBoilerplate>
            {user && !user.onboarding_complete
            ? <MultistepProgressBar navigateOnboardingStep={navigateOnboardingStep} onboardingStep={onboardingStep}/>
            : ""}
            {onboardingStep === 1 ? <OnboardStepOne user={user} setUser={setUser} setOnboardingStep={setOnboardingStep}></OnboardStepOne>
            : onboardingStep === 2 ? <OnboardStepTwo user={user} setUser={setUser} setOnboardingStep={setOnboardingStep}></OnboardStepTwo>
            : onboardingStep === 3 ? <OnboardStepThree user={user} setUser={setUser} setOnboardingStep={setOnboardingStep}></OnboardStepThree>
            : onboardingStep === 4 ? <OnboardStepFour user={user} setUser={setUser} setOnboardingStep={setOnboardingStep}></OnboardStepFour>
            : ""
            }
        </LoginBoilerplate>
    );
}

export default Onboard;
