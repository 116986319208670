import React, { useState } from "react";
import { useChatContext } from "stream-chat-react";

import "./MessagePopup.css";
import "../EditChannel/EditChannel.css"

export const MessagePopup = (props) => {
    let {active, setPopupActive, actionWord, actionFunc} = props

    function capitalize(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    function handleClick(){
        setPopupActive(false)
    }

    return active ? (
        <div className="popup-messenger-mask">
            <div className="popup-messenger-container">
                <h3>Are you sure you want to {actionWord} this channel?</h3>
                <div className="button-container">
                    <button className="leave-channel-button gray-channel-button" onClick={handleClick}>No, Go Back</button>
                    <button className="leave-channel-button" onClick={actionFunc}>Yes, {capitalize(actionWord)} Channel</button>
                </div>
            </div>
        </div>
    ) : (<></>);
};
