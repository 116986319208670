import { React, useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faCheck } from "@fortawesome/free-solid-svg-icons";
import { POST } from "../../../util/api/api";
import { goToMessageUser } from "./JobView";
import {calculateUserReviews} from "../../messages/components/UserProfile/UserProfile"

export function ApplicantRow(props){
    const {applicant, setUserSpotlight, setSpotlightActive, job, changeTab, activeTab, setIsInReviewAndCompletionPhase} = props

    let buttonOptions
    const [reviewTotals, setReviewTotals] = useState({})

    useEffect(() => {
        setReviewTotals(calculateUserReviews(applicant?.reviews))
    }, []);

    function handleClick(e) {

        if (e.target.className === 'accept-button' || e.target.className === 'message-button'){
            return
        }

        const buttons = document.getElementsByClassName('message-button')

        for (let i = 0; i < buttons.length; i++ ){
            if (buttons[i].contains(e.target)){
                return
            }
        }
        
        setUserSpotlight(applicant._id)
        setSpotlightActive(true)
    }

    function isJobEndTimePassed() {
        const endTime = new Date(job.end_time)
        const now = new Date()
        return endTime < now
    }

    function formatFreelancerName(firstName, lastName){
        if (firstName){
            firstName = firstName.charAt(0).toUpperCase() + firstName.slice(1)
        }

        if (lastName){
            lastName = lastName.charAt(0).toUpperCase() + lastName.slice(1)
        }
    
        return firstName + " " + lastName
    }
    
    function getExpertiseAbbreviation(expertise){
        if (!expertise){
            return ''
        }
        const index = expertise.indexOf('(');
        if (index !== -1) {
          const extractedString = expertise.substring(index);
          return extractedString;
        } else {
          return '';
        }
    }
    
    async function acceptApplicant() {
        if (window.confirm('This will select this applicant for this job listing and mark this job as filled. Continue?')){
    
            const body = {
                job_id: job._id,
                applicant_id: applicant._id
            }
            const data = await POST(`/api/jobs/applicants/accept`, body);
    
            if (data?.status === 200){
                changeTab('unfilled')
            } else {
                alert('An unknown error ocurred. Please refresh and try again later.')
            }
        }
    }
    
    async function completeJob() {
        setIsInReviewAndCompletionPhase(true)
    }

    if (activeTab === 'unfilled'){
        buttonOptions = <div className="applicant-button-container">
            <button onClick={() => goToMessageUser(applicant)} className="message-button"><FontAwesomeIcon className="message-button-icon" icon={faEnvelope} /></button>
            {job.start_time > new Date().toISOString()
                ? <button onClick={acceptApplicant} className="accept-button">Accept</button>
                : <button className="accept-button time-passed-button">Time Passed</button>
            }
            
        </div>
    } else if (activeTab === 'filled'){
        if (isJobEndTimePassed()){
            buttonOptions = <div className="applicant-button-container">
                <button onClick={() => goToMessageUser(applicant)} className="message-button"><FontAwesomeIcon className="message-button-icon" icon={faEnvelope} /></button>
                <button onClick={completeJob} className="accept-button">Complete</button>
            </div>
        } else {
            buttonOptions = <p className="awaiting-job-completion">Awaiting Job Completion</p>
        }  
    } else if (activeTab === 'completed'){
        buttonOptions = <p className="awaiting-job-completion job-complete" style={{color: "#008655"}}>Job Complete! <FontAwesomeIcon className="applied-icon" icon={faCheck} /></p>
    }

    return (
        <div onClick={handleClick} className="applicant-row-container">
            <span className="profile-img-container">
                {applicant?.profile_img
                    ? <img className="profile-img" src={applicant?.profile_img} />
                    : <div className="profile-img-placeholder">{applicant?.first_name[0].toUpperCase()}</div>
                }
                
            </span>
            <div className="applicant-info">
                <p>{formatFreelancerName(applicant?.first_name, applicant?.last_name)} 
                    <span>&nbsp; • &nbsp; {getExpertiseAbbreviation(applicant?.expertise)}</span>
                    {reviewTotals?.total ? <span>&nbsp; • &nbsp;{reviewTotals.total} ⭐️</span> : ''}
                </p>
                <p className="applicant-bio">{applicant?.bio}</p>
                <p>{applicant?.location?.address?.city + ", " + applicant?.location?.address?.state}</p>
            </div>
            {buttonOptions}
        </div>
    )
}