import React, { useEffect, useState } from "react";
import { Channel, useChatContext, Avatar } from "stream-chat-react";

import "./ChannelContainer.css";

import { ChannelInner } from "./ChannelInner";

import { ChannelEmptyState } from "../ChannelEmptyState/ChannelEmptyState";
import { CreateChannel } from "../CreateChannel/CreateChannel";
import { EditChannel } from "../EditChannel/EditChannel";
import { TeamMessage } from "../TeamMessage/TeamMessage";
import { TeamMessageInput } from "../TeamMessageInput/TeamMessageInput";
import { filterOut } from "../../../../util/constants";
import UserProfile from "../UserProfile/UserProfile";

import { CloseThreadIcon } from "../../assets";

const ThreadHeader = (props) => {
    const { closeThread, setPinsOpen, thread } = props;

    const getReplyCount = () => {
        if (!thread?.reply_count) return "";
        if (thread.reply_count === 1) return "1 reply";
        return `${thread.reply_count} Replies`;
    };

    return (
        <div className="custom-thread-header">
            <div className="custom-thread-header__left">
                <p className="custom-thread-header__left-title">Thread</p>
                <p className="custom-thread-header__left-count">{getReplyCount()}</p>
            </div>
            <CloseThreadIcon {...{ closeThread, setPinsOpen }} />
        </div>
    );
};

const CustomAvatar = (props) => {
    return <Avatar image={props.image} shape="square" size={50} />;
};

export const ChannelContainer = (props) => {

    const { createType, isCreating, isEditing, setIsCreating, setIsEditing, connections, id, panelsOpen, setPanelsOpen } = props;

    const { channel, client } = useChatContext();
    const [pinsOpen, setPinsOpen] = useState(false);
    const [userSpotlight, setUserSpotlight] = useState("");
    const [spotlightActive, setSpotlightActive] = useState(false);

    if (isCreating) {
        return (
            <>
                <div className={!panelsOpen.center ? "mobile-close channel__container" : "channel__container"}>
                    <CreateChannel {...{ createType, setIsCreating, isCreating, setUserSpotlight, setSpotlightActive }} />
                </div>
                {userSpotlight ? <UserProfile {...{ userSpotlight, setUserSpotlight, spotlightActive, setSpotlightActive, panelsOpen, setPanelsOpen }} /> : ""}
            </>
        );
    }

    if (isEditing) {
        // const filters = [{}, {}];
        // setFilters([{}, {}])

        if (channel?.state?.members) {
            let channelMembers = Object.keys(channel.state.members);

            // let usersAllowedToAdd = channelMembers.filter(function (el) {
            //     return !channelMembers.includes(el);
            // });

            // filters[0].id = usersAllowedToAdd.length ? { $in: usersAllowedToAdd } : "";



            // filters[0].id = channelMembers.length ? { $nin: channelMembers } : "";
            // filters[1].id = channelMembers.length ? { $in: channelMembers } : "";

            // staticFilters = [{id: channelMembers.length ? { $in: channelMembers } : ""}, {id: channelMembers.length ? { $nin: channelMembers } : ""}]
        }

        return (
            <>
                <div className={!panelsOpen.center ? "mobile-close channel__container" : "channel__container"}>
                    <EditChannel {...{isEditing, setIsEditing, isCreating, id, setUserSpotlight, setSpotlightActive }} />
                </div>
                {userSpotlight ? <UserProfile {...{ userSpotlight, setUserSpotlight, spotlightActive, setSpotlightActive, panelsOpen, setPanelsOpen }} /> : ""}
            </>
        );
    }

    if (!channel) {
        return (
            <div className={`channel__container mobile-only ${!panelsOpen.center ? "mobile-close" : ""}`}>
                <div className="chat-empty-channel-message">
                    <div>
                        <p>You don't have any messages yet. Use the left sidebar to create a new Space or a DM with someone!</p>
                        <button onClick={() => setPanelsOpen({left: true, center: false, right: false})}>Go Back</button>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <>
            <div className={!panelsOpen.center ? "mobile-close channel__container" : "channel__container"}>
                <Channel 
                    EmptyStateIndicator={ChannelEmptyState} 
                    Input={TeamMessageInput} 
                    Message={(messageProps, i) => <TeamMessage key={i} {...messageProps} {...{ setPinsOpen }} />} 
                    ThreadHeader={(threadProps) => <ThreadHeader {...threadProps} {...{ setPinsOpen }} />} 
                    TypingIndicator={() => null}>
                    <ChannelInner
                        {...{
                            pinsOpen,
                            setIsEditing,
                            setPinsOpen,
                            setUserSpotlight,
                            setSpotlightActive,
                            setPanelsOpen,
                        }}
                    />
                </Channel>
            </div>
            {userSpotlight ? <UserProfile {...{ userSpotlight, setUserSpotlight, setSpotlightActive, panelsOpen, setPanelsOpen }} /> : ""}
        </>
    );
};
