import React, {useEffect} from 'react';

import './TeamChannelList.css';

import { AddChannel } from '../../assets';
import { Channel, useChatContext, Avatar } from "stream-chat-react";


const ChannelList = (props) => {
  const { channel, client } = useChatContext();
  const {
    children,
    error = false,
    loading,
    setCreateType,
    setIsCreating,
    setIsEditing,
    type,
    setPanelsOpen
  } = props;

  // useEffect(() => {
  //   if (channel){
  //     console.log(channel)
  //     channel.on(event => {
  //         if (event.type === 'message.new' && (event.channel_type === "messaging" || event.channel_type === "team")) {
  //             let mentionedInMessage;

  //             if (event.channel_type == "team" && event.message.mentioned_users){
  //                 for (let i=0; i<event.message.mentioned_users.length; i++){
  //                     if (event.message.mentioned_users[i].id === client.user.id){
  //                         mentionedInMessage = true
  //                     }
  //                 }
  //                 if (!mentionedInMessage){return}
  //             }

  //             console.log('ye')
  //             new Notification(`New Message from ${event.message.user.name}`, {
  //                 body: event.message.text,
  //             });

  //             var audio = new Audio('/notification.wav');
  //             audio.play();

  //             document.getElementById('favicon').href =
  //               'https://emojipedia-us.s3.dualstack.us-west-1.amazonaws.com/thumbs/240/google/223/bell_1f514.png';
  //         }
  
  //         if (event.type === 'message.read' && !event.total_unread_count) {
  //         document.getElementById('favicon').href = '/favicon.ico';
  //         }
  //     });
  // }
    
  // }, [channel]);

  if (error) {
    return type === 'team' ? (
      <div className='team-channel-list'>
        <p className='team-channel-list__message'>
          Connection error, please wait a moment and try again.
        </p>
      </div>
    ) : null;
  }

  if (loading) {
    return (
      <div className='team-channel-list'>
        <p className='team-channel-list__message loading'>
          {type === 'team' ? 'Spaces' : 'Messages'} loading....
        </p>
      </div>
    );
  }

  return (
    <div className='team-channel-list'>
      <div className='team-channel-list__header'>
        <p className='team-channel-list__header__title'>
          {type === 'team' ? 'Spaces' : 'Messages'}
        </p>
        <AddChannel
          {...{ setCreateType, setIsCreating, setIsEditing, setPanelsOpen }}
          type={type === 'team' ? 'team' : 'messaging'}
        />
      </div>
      {children}
    </div>
  );
};

export const TeamChannelList = React.memo(ChannelList);
