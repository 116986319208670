import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faHammer, faJedi} from "@fortawesome/free-solid-svg-icons";
import React, { useState, useEffect} from "react";
import { GET, POST } from "../../util/api/api";
import Loading from "../../components/other/Loading"

import "./main.css"


function Settings() {
    const [currentUser, setCurrentUser] = useState("");
    const [notifyOnMention, setNotifyOnMention] = useState(false)
    const [notifyOnDM, setNotifyOnDM] = useState(false)
    const [notifyOnChannelMsg, setNotifyOnChannelMsg] = useState(false)
    const [notifyOnChannelAdd, setNotifyOnChannelAdd] = useState(false)
    const [notifyOnJobApply, setNotifyOnJobApply] = useState(false)
    const [notifyOnJobCompleted, setNotifyOnJobCompleted] = useState(false)
    const [notifyOnAcceptedToJob, setNotifyOnAcceptedToJob] = useState(false)
    const [notifyOnJobCancelled, setNotifyOnJobCancelled] = useState(false)
    const [notifyOnBoostedJobPost, setNotifyOnBoostedJobPost] = useState(false)
    const [SMSnotifyOnMention, SMSsetNotifyOnMention] = useState(false)
    const [SMSnotifyOnDM, SMSsetNotifyOnDM] = useState(false)
    const [SMSnotifyOnChannelMsg, SMSsetNotifyOnChannelMsg] = useState(false)
    const [SMSnotifyOnChannelAdd, SMSsetNotifyOnChannelAdd] = useState(false)
    const [SMSnotifyOnJobApply, SMSsetNotifyOnJobApply] = useState(false)
    const [SMSnotifyOnJobCompleted, SMSsetNotifyOnJobCompleted] = useState(false)
    const [SMSnotifyOnAcceptedToJob, SMSsetNotifyOnAcceptedToJob] = useState(false)
    const [SMSnotifyOnJobCancelled, SMSsetNotifyOnJobCancelled] = useState(false)
    const [SMSnotifyOnBoostedJobPost, SMSsetNotifyOnBoostedJobPost] = useState(false)
    const [isLoading, setLoading] = useState(false)

    useEffect(() => {
        async function getCurrentUser() {
            const { data } = await GET(`/api/user`);
            setCurrentUser(data);
            if (data && data.notifications && data.notifications.email) {
                setNotifyOnMention(data.notifications.email.on_mention)
                setNotifyOnDM(data.notifications.email.on_dm)
                setNotifyOnChannelMsg(data.notifications.email.on_any_channel_msg)
                setNotifyOnChannelAdd(data.notifications.email.on_added_to_channel)
                setNotifyOnJobApply(data.notifications.email.on_new_applicant_to_job)
                setNotifyOnJobCompleted(data.notifications.email.on_job_completed)
                setNotifyOnAcceptedToJob(data.notifications.email.on_accepted_to_job)
                setNotifyOnJobCancelled(data.notifications.email.on_job_cancelled)
                setNotifyOnBoostedJobPost(data.notifications.email.on_boosted_job_posted)
            }
            if (data && data.notifications && data.notifications.sms) {
                SMSsetNotifyOnMention(data.notifications.sms.on_mention)
                SMSsetNotifyOnDM(data.notifications.sms.on_dm)
                SMSsetNotifyOnChannelMsg(data.notifications.sms.on_any_channel_msg)
                SMSsetNotifyOnChannelAdd(data.notifications.sms.on_added_to_channel)
                SMSsetNotifyOnJobApply(data.notifications.sms.on_new_applicant_to_job)
                SMSsetNotifyOnJobCompleted(data.notifications.sms.on_job_completed)
                SMSsetNotifyOnAcceptedToJob(data.notifications.sms.on_accepted_to_job)
                SMSsetNotifyOnJobCancelled(data.notifications.sms.on_job_cancelled)
                SMSsetNotifyOnBoostedJobPost(data.notifications.sms.on_boosted_job_posted)
            }

        }
        getCurrentUser();
    }, []);

    async function saveSettings() {
        setLoading(true)
        const body = {
            notifications: {
                email: {
                    on_mention: notifyOnMention,
                    on_dm: notifyOnDM,
                    on_any_channel_msg: notifyOnChannelMsg,
                    on_added_to_channel: notifyOnChannelAdd,
                    on_new_applicant_to_job: notifyOnJobApply,
                    on_job_completed: notifyOnJobCompleted,
                    on_accepted_to_job: notifyOnAcceptedToJob,
                    on_job_cancelled: notifyOnJobCancelled,
                    on_boosted_job_posted: notifyOnBoostedJobPost
                },
                sms: {
                    on_mention: SMSnotifyOnMention,
                    on_dm: SMSnotifyOnDM,
                    on_any_channel_msg: SMSnotifyOnChannelMsg,
                    on_added_to_channel: SMSnotifyOnChannelAdd,
                    on_new_applicant_to_job: SMSnotifyOnJobApply,
                    on_job_completed: SMSnotifyOnJobCompleted,
                    on_accepted_to_job: SMSnotifyOnAcceptedToJob,
                    on_job_cancelled: SMSnotifyOnJobCancelled,
                    on_boosted_job_posted: SMSnotifyOnBoostedJobPost
                }
            }
        }
        const data = await POST(`/api/user/settings`, body);
        if (data && data.status === 200) {
            window.location.reload()
        }
    }
    
    return (
        <div className="demo-mask">
            <div className="demo-container">
                <div id="settings">
                    <h2>Settings</h2>
                    <div>
                        <FontAwesomeIcon icon={faHammer} />
                    </div>
                    <h3 className="section-title">Notifications</h3>
                    <hr></hr>
                    <table>
                        <tr>
                            <th>Send Notifications When</th>
                            <th style={{"width":"60px"}}>Email</th>
                            <th style={{"width":"93px"}}>Text Message</th>
                        </tr>
                        <tr>
                            <td>You are @ mentioned in a message</td>
                            <td className="checkbox"><input type="checkbox" onChange={() => setNotifyOnMention(!notifyOnMention)} checked={notifyOnMention}></input></td>
                            <td className="checkbox"><input type="checkbox" onChange={() => SMSsetNotifyOnMention(!SMSnotifyOnMention)} checked={SMSnotifyOnMention}></input></td>
                        </tr>
                        <tr>
                            <td>You receive a direct message</td>
                            <td className="checkbox"><input type="checkbox" onChange={() => setNotifyOnDM(!notifyOnDM)} checked={notifyOnDM}></input></td>
                            <td className="checkbox"><input type="checkbox" onChange={() => SMSsetNotifyOnDM(!SMSnotifyOnDM)} checked={SMSnotifyOnDM}></input></td>
                        </tr>
                        <tr>
                            <td>You receive a message in your spaces</td>
                            <td className="checkbox"><input type="checkbox" onChange={() => setNotifyOnChannelMsg(!notifyOnChannelMsg)} checked={notifyOnChannelMsg}></input></td>
                            <td className="checkbox"><input type="checkbox" onChange={() => SMSsetNotifyOnChannelMsg(!SMSnotifyOnChannelMsg)} checked={SMSnotifyOnChannelMsg}></input></td>
                        </tr>
                        <tr>
                            <td>You're added to a new space</td>
                            <td className="checkbox"><input type="checkbox" onChange={() => setNotifyOnChannelAdd(!notifyOnChannelAdd)} checked={notifyOnChannelAdd}></input></td>
                            <td className="checkbox"><input type="checkbox" onChange={() => SMSsetNotifyOnChannelAdd(!SMSnotifyOnChannelAdd)} checked={SMSnotifyOnChannelAdd}></input></td>
                        </tr>
                        {currentUser?.user_type === "office"
                            ? 
                            <tr>
                                <td>You receive a new applicant to a job</td>
                                <td className="checkbox"><input type="checkbox" onChange={() => setNotifyOnJobApply(!notifyOnJobApply)} checked={notifyOnJobApply}></input></td>
                                <td className="checkbox"><input type="checkbox" onChange={() => SMSsetNotifyOnJobApply(!SMSnotifyOnJobApply)} checked={SMSnotifyOnJobApply}></input></td>
                            </tr>
                            :
                            <>
                                 <tr>
                                    <td>You've been accepted to a job you applied for</td>
                                    <td className="checkbox"><input type="checkbox" onChange={() => setNotifyOnAcceptedToJob(!notifyOnAcceptedToJob)} checked={notifyOnAcceptedToJob}></input></td>
                                    <td className="checkbox"><input type="checkbox" onChange={() => SMSsetNotifyOnAcceptedToJob(!SMSnotifyOnAcceptedToJob)} checked={SMSnotifyOnAcceptedToJob}></input></td>
                                </tr>
                                <tr>
                                    <td>A job you've worked is marked completed</td>
                                    <td className="checkbox"><input type="checkbox" onChange={() => setNotifyOnJobCompleted(!notifyOnJobCompleted)} checked={notifyOnJobCompleted}></input></td>
                                    <td className="checkbox"><input type="checkbox" onChange={() => SMSsetNotifyOnJobCompleted(!SMSnotifyOnJobCompleted)} checked={SMSnotifyOnJobCompleted}></input></td>
                                </tr>
                                <tr>
                                    <td>A job you applied for was cancelled</td>
                                    <td className="checkbox"><input type="checkbox" onChange={() => setNotifyOnJobCancelled(!notifyOnJobCancelled)} checked={notifyOnJobCancelled}></input></td>
                                    <td className="checkbox"><input type="checkbox" onChange={() => SMSsetNotifyOnJobCancelled(!SMSnotifyOnJobCancelled)} checked={SMSnotifyOnJobCancelled}></input></td>
                                </tr>
                                <tr>
                                    <td>A new boosted job was posted (these are priority jobs that often pay higher wages)</td>
                                    <td className="checkbox"><input type="checkbox" onChange={() => setNotifyOnBoostedJobPost(!notifyOnBoostedJobPost)} checked={notifyOnBoostedJobPost}></input></td>
                                    <td className="checkbox"><input type="checkbox" onChange={() => SMSsetNotifyOnBoostedJobPost(!SMSnotifyOnBoostedJobPost)} checked={SMSnotifyOnBoostedJobPost}></input></td>
                                </tr>
                            </>
                        }
                        
                    </table>

                    <button className="buttonMain settings-button" onClick={saveSettings}>
                       {isLoading ? <Loading/> : "Save"}
                    </button>
                </div>
            </div>
        </div>
    );
}    

export default Settings;