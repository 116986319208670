import React, { useState, useEffect } from 'react';
import { useChatContext } from 'stream-chat-react';
import { UserList } from './UserList';
import { CloseCreateChannel } from '../../assets';
import {validateChannelName} from '../../../../helpers/validateChannelName';
import UserProfile from "../UserProfile/UserProfile"


import './CreateChannel.css';

const ChannelNameInput = (props) => {
  const { channelName = '', setChannelName } = props;

  const handleChange = (event) => {
    event.preventDefault();
    setChannelName(event.target.value);
  };

  return (
    <div className='channel-name-input__wrapper'>
      <p>Name</p>
      <input
        onChange={handleChange}
        placeholder='space-name (no spaces)'
        type='text'
        value={channelName}
      />
      <p>Add Members</p>
    </div>
  );
};

export const CreateChannel = (props) => {
  const { createType, setIsCreating, isCreating, setUserSpotlight, setSpotlightActive} = props;

  const { client, setActiveChannel } = useChatContext();
  const [channelName, setChannelName] = useState('');
  const [selectedUsers, setSelectedUsers] = useState([client.userID || '']);
  const [nameError, setNameError] = useState(false);
  const [filters, setFilters] = useState('');

  const createChannel = async (event) => {

    event.preventDefault();

    let channelErrorsPresent = false

    if(createType === "team"){
      channelErrorsPresent = await validateChannelName(channelName, client)
      setNameError(channelErrorsPresent)
    }
    if (!channelErrorsPresent){
      try {
        const newChannel = await client.channel(createType, channelName, {
          name: channelName,
          members: selectedUsers
        });
  
        await newChannel.watch();
  
        setChannelName('');
        setIsCreating(false);
        setSelectedUsers([client.userID]);
        setActiveChannel(newChannel);
      } catch (err) {
        console.log(err);
      }
    }
  };

  const handleSearchInput = (e) => {
    if (e.target && e.target.value) {
      setFilters({name: { $autocomplete: e.target.value }})
    } else if (e.target.value === "") {
      setFilters("")
    }
  }

  return (
    <>
    <div className='create-channel__container'>
      <div className='create-channel__header'>
        <p>{createType === 'team' ? 'Create a New Space' : 'Send a Direct Message'}</p>
        <CloseCreateChannel {...{ setIsCreating }} />
      </div>
      {nameError ? <p className="error-message">{nameError}</p> : ""}
      {createType === 'team' && <ChannelNameInput {...{ channelName, setChannelName, nameError, setNameError }} />}
      <div className='messaging-user-search-bar_container'>
        <input className="messaging-user-search-bar" onChange={handleSearchInput} placeholder="search for users"></input>
      </div>
      
      <UserList {...{ filters, setFilters, selectedUsers, setSelectedUsers, isCreating, setIsCreating, setUserSpotlight, setSpotlightActive, createType}}/>
      <div className='create-channel__button-wrapper' onClick={createChannel}>
        <p>{createType === 'team' ? 'Create Space' : 'Create Message'}</p>
      </div>
    </div>
    </>
  );
};
