
import "./main.css";
import { React, useState, useEffect } from "react";

export function Transaction(props){
    const {tx, userType} = props

    function getPaymentType(){
        if (userType === 'freelancer' && tx.object === 'charge' && !tx.refunded){
            return 'Payout'
        } else if (userType === 'freelancer' && tx.object === 'charge' && !tx.refunded){
            return 'Charge'
        } else {
            return tx.object.charAt(0).toUpperCase() + tx.object.slice(1)
        }
    }

    function formatDate(timestamp){
        const date = new Date(timestamp * 1000)
        return (date.getMonth() + 1) + "/" + date.getDate() + "/" + date.getFullYear()
    }

    function formatAmount(amount){
        return (amount/100).toFixed(2)
    }

    return (
        <tr className="row">
            <td>{formatDate(tx.created)}</td>
            <td>{getPaymentType()}</td>
            <td className="description">{tx.description}</td>
            <td>{getPaymentType() === 'Charge' ? `$(${formatAmount(tx.amount - tx.amount_refunded)})` : '$' + formatAmount(tx.amount - tx.amount_refunded)}</td>
            <td>{tx.status === 'succeeded' ? 'paid' : tx.status}</td>
            <td className="job_id"><a className="job_id_shortened" href={`/jobs?job_id=${tx?.metadata?.job_id}`}>{tx?.metadata.job_id ? '...' + tx?.metadata?.job_id.substring(tx?.metadata?.job_id.length - 6, tx?.metadata?.job_id.length) : ""}</a></td>
            <td className="job_id">{tx.receipt_url ? <a  target="_blank" href={tx.receipt_url}>View</a> : "" }</td>
        </tr>
    )
}