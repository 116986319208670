export function CodeOfConduct(){
    return (
        <div className="code-of-conduct">
            <h2>Code of Conduct - Dental Offices</h2>
            <h5>Creating Jobs:</h5>     
Every job post on the dentalWORKS app includes: pay rate, date, arrive time, end time, position title, and zip code. Jobs may not be posted without this information.
<br></br><br></br>
To protect practice privacy, temps are unable to see your office contact information (name and phone number) until after they have been accepted for a job. The only information available publicly to non-accepted temps is practice type (e.g. general dentistry, ortho, etc.) and the practice zip code.
<br></br><br></br>
You are able to add additional notes to your job post. For example: parking instructions, directions, specific PPE or dress code requirements, etc. Please be sure to add any relevant information to your job description. 
<br></br><br></br>
Please be sure you enable text and email notifications in your settings so you can be alerted as soon as a temp(s) have applied for your job. 
<br></br><br></br>
Temps are not employees of dentalWORKS and are considered independent contractors.
<br></br><br></br>
dentalWORKS is responsible for sending year-end tax forms to temps. 
<br></br><br></br>
When a temp signs up for dentalWORKS, we verify licenses to ensure they are currently active with the State. However, it is the responsibility of the practice to ensure a hired temp is qualified for the job. 

<h5>Accepting Temps:</h5>

After a temp has applied for your job through the dentalWORKS platform, you may hire the best candidate by selecting “approve” on the job listing.
<br></br><br></br>
If multiple temps apply for the same position, you can click on their picture, view their profile, and see any additional information about them (e.g. reviews, certifications, experience, etc.) to make a more informed hiring decision. 
<br></br><br></br>
After you’ve accepted a temp for your job, the temp has 24 hours to message you on the dentalWORKS platform and confirm they accept the job. 
<br></br><br></br>
We encourage temps to reach out to you as soon as they are notified of the accepted job. 
<br></br><br></br>
We also encourage offices to message the temp on the dentalWORKS platform immediately after accepting them in order to introduce yourself and share any relevant information about the upcoming job. You may simply also send a “confirm?” message to the temp.
<br></br><br></br>
If a temp does not message you, or respond to your initial message, to confirm the shift within 24 hours, you are able, but not obligated, to cancel the job and post again. This will cause the temp’s account to be placed on hold until they reach out to support@dentalworks.io.
<br></br><br></br>
We rely on practices to inform us of a temp’s unprofessional behavior or lack of response. Accurately reporting temps helps ensure the dentalWORKS platform users are highly skilled and professional temps. 
<br></br><br></br>
If you post a job that has an arrival time that starts in 24 hours, it is up to you and the temp to be as communicative and responsive with each other as possible. 
<br></br><br></br>
If you post a job that has an arrival time that starts the same day, it is up to you and the temp to be as communicative and responsive with each other as possible.

<h5>Job Cancellations:</h5>

Posting a job to dentalWORKS is a commitment by your office. Canceling the job after a temp has been accepted for the position is unprofessional. This may result in your account being suspended.
<br></br><br></br>
If you must cancel a job after a temp has been accepted, please message the temp first, and, if needed, then message support@dentalworks.io. 
<br></br><br></br>
Offices may not cancel jobs within 48 hours of the job’s start time. If you must cancel the job within 48 hours, please message support@dentalworks.io to have the job canceled. 
<br></br><br></br>
Offices that need to cancel jobs within 48 hours of the job’s arrival time must pay a 25% of that job’s total pay as a cancellation fee that is paid to the temp. 
<br></br><br></br>
Offices that need to cancel jobs within 24 hours of a job’s arrival time must pay 50% of that job's total pay as a cancellation fee that is paid to the temp. 
<br></br><br></br>
Offices that need to cancel jobs within 12 hours of a job’s arrival time must pay 100% of that job's total pay as a cancellation fee that is paid to the temp. 
<br></br><br></br>
dentalWORKS has a zero tolerance policy for temp no shows. If a temp does not show up for work, please message support@dentalworks.io as soon as possible. This will result in the temp being permanently banned from the dentalWORKS app.
<br></br><br></br>
We want to ensure that only the highest quality and most professional temps are using dentalWORKS. If any negative interaction with a temp occurs, please message support@dentalworks.io as soon as possible. 

<h5>Day of Job:</h5>

Temps must bring a state or federal issued ID with them so you can confirm their identity. Allowing a temp to work a job, without being properly identified, negates any insurance coverage dentalWORKS carries.

<h5>Professional Appearance:</h5>

If a temp is not properly dressed (e.g. soiled or unkept scrubs/lab coats, unprofessional business attire, open toed shoes or flip flops, etc.) or they are not properly groomed (e.g. offensive smell, bad hygiene, etc.) you have the right to not accept them for work that day. If this occurs, please message support@dentalworks.io and cancel the job through the dentalWORKS platform. 
<br></br><br></br>
Temp’s should be in your office and prepared to receive instructions for the day exactly at the “arrival time” stated in the job post. Arrival time is paid time before patient care begins, which allows you to give adequate instructions for the day. Arrival time is the start of the temp’s paid shift. If a temp is late, you have the right to not accept them for work that day. If this occurs please message support@dentalworks.io and cancel the job through the dentalWORKS platform. 

<h5>Professional Etiquette:</h5>

Temps are instructed to not bring their personal lives into your office. Similarly, we ask that offices strive to foster a positive work environment free from inter-office drama and politics.
<br></br><br></br>
When a temp arrives for work, please ensure they have a single point of contact for that day who they can receive instructions from or ask questions to (e.g. office manager, senior Hygienist, etc.) 
<br></br><br></br>
All temps are expected to adhere to all office policies and protocols. Please ensure temps have all relevant work information available to them before patient care begins so they can have a successful day.
<br></br><br></br>
Please ensure you do not ask temps to perform job duties outside the scope of their training and job description. (e.g. asking an RDH or DA to clean bathrooms.) However, duties like X-rays, numbing, sterilization, etc. are acceptable. 

<h5>On the Job Issues:</h5>

If an issue arises during a temp’s shift (e.g. belligerent, disrespectful, incompetent, etc.) please communicate any concerns with the temp first as it may be a simple misunderstanding of job duties or expectations. If problems persist, please contact support@dentalworks.io immediately. 
<br></br><br></br>
If a temp is a danger to themselves, patients, or office staff please contact local authorities if necessary and message dentalWORKS support immediately. 
<br></br><br></br>
Please be respectful of the agreed upon end times for the shift. If you need a temp to stay later, please communicate the request with the temp and, if they agree, adjust the time on the job post on the dentalWORKS platform. 

<h5>After Job Completion:</h5>

After a temp’s shift has been completed you must close out their job on the dentalWORKS platform. To do this, simply press “Completed” on the job specific job posting. This will initiate payment processing and funds will be transferred to the temp in 4-6 business days. 
<br></br><br></br>
We are adding a “same-day pay” feature to the platform soon, which practices may select as an incentive to help you stand out.
<br></br><br></br>
You will have the ability to rate a temp’s job performance (punctuality, bedside manner, professionalism, knowledge, and enthusiasm) after the shift is completed. 
<br></br><br></br>
Temp’s rely on high ratings from offices to be considered for additional jobs on the dentalWORKS platform. Please rate the temp fairly and accurately. 
<br></br><br></br>
If you have concerns or questions about ratings please contact dentalWORKS support. If a temp reaches out to you regarding their rating please direct them to support@dentalworks.io
<br></br><br></br>
If you would like to hire the same temp for future temporary jobs, you can send them a direct message on the dentalWORKS app when you post a new job and ask them to apply. 
<br></br><br></br>
If a temp asks you to hire them outside of the dentalWORKS platform, please advise them to apply for the job through the app. 
<br></br><br></br>
Hiring temps outside the dentalWORKS platform negates any insurance coverage and denies temps a high rating from your office, which helps them secure future jobs from other offices. Practices and temps that hire outside of the dentalWORKS platform may have their accounts suspended or banned. 
<br></br><br></br>
If a temp you hired through the dentalWORKS platform would make a great addition to your office, in either a part-time or full-time position, feel free to extend an offer to them!
<br></br><br></br>
We DO NOT believe in charging you a finder’s fee if you find great talent through dentalWORKS. Simply message dentalWORKS support and we’ll update the temp’s profile accordingly. 

<h2>Code of Conduct - Temps</h2>
<h5>Applying for jobs:</h5>

Every job listing on dentalWORKS includes the relevant job information (e.g. pay, date, arrival time, location, position title, and zip code.) However, it is up to you to read any additional notes on the job post, such as language or software skills needed, before applying. 
<br></br><br></br>
Before applying for a job on dentalWORKS, you must ensure you have no scheduling conflicts with other accepted and confirmed jobs.
<br></br><br></br>
Please ensure that you have notifications turned on in your dentalWORKS settings. When you are hired for a job you will receive notifications by the method of your preference (text and/or email). 
<br></br><br></br>
Choosing to not receive notifications may put you at risk of a no-show and possible account suspension.
<br></br><br></br>
If you apply for a job, but the office has not yet accepted you, and you are no longer available to work that job, it is your responsibility to cancel your submission. 
<br></br><br></br>
You may do this by navigating to My Jobs - Applied - click the job post - Cancel Application.
<br></br><br></br>
Before applying for any job on the dentalWORKS app, you must provide the necessary banking information so you can get paid in a timely manner. We partner with Stripe to ensure prompt and accurate payment. 
<br></br><br></br>
As a freelancing professional you are considered an independent contractor, meaning we DO NOT withhold any federal or state taxes. 
<br></br><br></br>
This keeps more money in your pocket and allows you to maximize the number of tax write-offs to lower your year-end taxes (e.g. vehicle gas, internet, car payments, cell phone, home office, etc.) We recommend setting aside a percentage of your total pay for year-end taxes. 
<br></br><br></br>
dentalWORKS will provide you with all the necessary tax documents at the end of the year.
<br></br><br></br>
For more information on tax write-offs and benefits of 1099 contractor work, please speak with an accountant. 

<h5>Accepted Jobs:</h5>

When you apply for a job on the dentalWORKS platform, the dental office can view your dentalWORKS profile to read any additional information about you. Please ensure you write a concise and accurate bio of your experience and skills. 
<br></br><br></br>
If the office accepts you for that job, you will be notified through your preferred notification setting (text and/or email).
<br></br><br></br>
When a dental office accepts you for a job, that is considered a conditional job offer. As such, you will need to confirm with the dental office that you accept the job.
<br></br><br></br>
You can confirm that you accept the job with the dental office by messaging them on the dentalWOKRS platform. Navigate to: My Jobs - Accepted - click on the job - Message Office.
<br></br><br></br>
It’s recommended that you confirm the job with the dental office as soon as you are notified, but you have a 24 hour window to confirm.
<br></br><br></br>
When messaging the office to confirm a job you can simply write “confirmed”, or you can introduce yourself and ask any specific questions (such as parking requirements, PPE, dress code, arrival time, etc.). 
<br></br><br></br>
If you do not confirm within 24 hours, the dental office may cancel your job and your account will be suspended. You must contact support@dentalworks.io to reinstate your account.
<br></br><br></br>
If the job you applied for has an arrival time within 24 hours, it is up to you and the dental office to be as communicative and responsive with each other as possible. 
<br></br><br></br>
If the job you are accepted for is the same day, it is up to you and the dental office to be as communicative and responsive with each other as possible. 

<h5>Job Cancellations:</h5>

Accepting a job through the dentalWORKS app is a commitment. Canceling that job for any reason is unprofessional. We strongly discourage you from canceling jobs.
<br></br><br></br>
If you must cancel an accepted job, notify the office and support@dentalworks.io before canceling in the app.
<br></br><br></br>
Cancellations within 48 hours of the start time of the jobs cannot be done through the app. You must contact both the dental office and support@dentalworks.io. Canceling without cause within 48 hours may result in account suspension. 
<br></br><br></br>
No shows. We have a zero tolerance policy for no shows. If you do not show up for a job, your account will be automatically suspended and you will be banned from the dentalWORKS app. You may appeal your account suspension by sending an email to support@dentalworks.io.
<br></br><br></br>
If an office contacts you directly to cancel a job, advise them to cancel via the dentalWORKS app first. This will allow you to immediately look for other job opportunities for that same day.
<br></br><br></br>
If you reached out to the dental office to cancel a job they do not respond or have not canceled the job, please email support@dentalworks.io and we will work with the office to get the job canceled

<h5>Day of Job:</h5>

Please bring a state or federal issued ID with you to the job so the office can confirm your identity. Not bringing an ID negates insurance coverage. 
<br></br><br></br>
“Arrival time” as stated in a job post is the time you must be in the office, ready to receive instructions for the day. Please plan ahead to ensure you get to the office before the arrival time starts. This will allow you to find parking, walk inside, and find who you will be working with that day. 
<br></br><br></br>
Arrival time is the start of your paid shift. The office will use this time to give any additional instructions to you before patient care begins.
<br></br><br></br>
Appearance is important, so dress well and behave appropriately. Practices reserve the right to cancel the job if temps arrive to work dressed inappropriately or for offensive hygiene. 
<br></br><br></br>
Appropriate grooming standards:
<br></br><br></br>
Be clean and well groomed. 
<br></br><br></br>
Wear unscented deodorant and body lotions.
<br></br><br></br>
Shorter fingernails are more professional and hygienic. 
<br></br><br></br>
Refrain from wearing too much cologne or perfume as those around you may be allergic or sensitive. 
<br></br><br></br>
Keep hair short or pulled back. 
<br></br><br></br>
Limit your body piercings.
<br></br><br></br>
Avoid strong fragrances and too much makeup. 
<br></br><br></br>
Open toed shoes and heels are not allowed.
<br></br><br></br>
Please wear clean scrubs or lab coats for the back of the office, and business attire for the front, unless the office specifies otherwise.
<br></br><br></br>
A general rule of thumb is to be the kind of professional you would want to hire if you were running an office.

<h5>Professional Office Etiquette:</h5>

Do not bring your personal life into the workplace, and don't get caught up in inter-office drama.
<br></br><br></br>
Upon arrival, establish a point of contact in the office (e.g. office admin, senior RDH, etc) and ask questions of how to best help the office and patients.
<br></br><br></br>
Adhere to all office policies and inquire as to any special needs or roles that may be expected of you.
<br></br><br></br>
Do not use social media while working with patients in the office.
<br></br><br></br>
Do not text or make calls while working with patients in the office.
<br></br><br></br>
Be enthusiastic and professional.

<h5>On the Job Issues:</h5>

You are not expected or required to perform any job duties that are outside the scope of your training (e.g. being asked to clean bathrooms.) However, job duties that are related to your training such as X-rays, numbing, sterilization, etc. are acceptable.
<br></br><br></br>
If any on-the-job issues should arise (e.g. unnotified schedule changes, equipment malfunctions, staffing conflicts, etc.) during your shift, please communicate with your point of contact at the office (e.g. office manager and/or doctor.)
<br></br><br></br>
Try to be professional and de-escalate any issues so that you are able to complete your scheduled shift for the day.
<br></br><br></br>
If you are not able to de-escalate any on-the-job issues please reach out to support@dentalworks.io. 
<br></br><br></br>
DO NOT walk off a job without speaking to the office and contacting dentalWORKS.
<br></br><br></br>
If you feel threatened or in danger at any time during your shift, please get to a safe location, contact authorities (if necessary), and message support@dentalworks.io. The safety of dental professionals is our top priority and we will not tolerate unsafe working conditions for temps. 

<h5>Post Job Checklist:</h5>

After completing your shift, please double check with the office before you leave to ensure they properly mark your shift as “completed” on the dentalWORKS platform. This begins the payment processing and will ensure you get paid.
<br></br><br></br>
Funds will be deposited into your bank account within 4-6 business days after job completion.
<br></br><br></br>
A “same-day pay” feature is being added soon so you can receive your money immediately after a shift is completed. 
<br></br><br></br>
Stripe, our payment processor, deposits money into your bank account in batches. So the deposited amounts may include multiple shifts. Please refer to your Stripe account for summaries and details of your payouts. 
<br></br><br></br>
Offices are instructed to respect the end time of your shift. However, the office’s patient needs can change quickly and without notice.
<br></br><br></br>
If an office asks you to stay longer than your shift’s end time, you are free to do so but under no obligation. If you stay longer, please remind the office to adjust the scheduled hours in the dentalWORKS app so you can be paid appropriately. 
<br></br><br></br>
You’ll have the ability to rate the office on the dentalWORKS platform for things like cleanliness, helpfulness, and friendliness, etc. Please rate offices fairly and accurately. These reviews will help you and other temps consider which offices to work with in the future.
<br></br><br></br>
After completing a shift, the dental office will also have the ability to rate your performance in such areas like punctuality, bedside manner, professionalism, and enthusiasm. 
<br></br><br></br>
Highly rated temps can command the highest pay and best job opportunities on the dentalWORKS app. Feel free to ask the office for a 5-star rating after a successful job completion. 
<br></br><br></br>
If you disagree or have questions regarding your ratings, please contact dentalWORKS support@dentalworks.io.
<br></br><br></br>
If the office contacts you directly to work another shift, please advise them to create a job on the dentalWORKS platform, and to invite you to apply.
<br></br><br></br>
Taking shifts outside the dentalWORKS app negates any liability insurance and prevents the office from giving you a high rating, which helps you secure additional shifts on the app in the future.
<br></br><br></br>
If the office wishes to offer you a permanent position, please let support@dentalworks.io know so that we can keep an updated record.
<br></br><br></br>
dentalWORKS DOES NOT charge a finder’s fee if offices would like to extend part time or full time employment offers to dentalWORKS temps.
        </div>
    )
}