
import "./multistepprogress.css"

function MultistepProgressBar(props){
    const {onboardingStep, navigateOnboardingStep} = props
    return (
        <div class="stepper-wrapper">
            <div className={"stepper-item " + (onboardingStep === 1 ? "active" : "completed")}>
                <div onClick={() => navigateOnboardingStep(1)} className="step-counter">1</div>
                {/* <div class="step-name">First</div> */}
            </div>
            <div className={"stepper-item " + (onboardingStep === 2 ? "active" : (onboardingStep > 2 ? "completed" : ""))}>
                <div onClick={() => navigateOnboardingStep(2)} className="step-counter">2</div>
                {/* <div class="step-name">Second</div> */}
            </div>
            <div className={"stepper-item " + (onboardingStep === 3 ? "active" : (onboardingStep > 3 ? "completed" : ""))}>
                <div onClick={() => navigateOnboardingStep(3)} className="step-counter">3</div>
                {/* <div class="step-name">Third</div> */}
            </div>
            <div className={"stepper-item " + (onboardingStep === 4 ? "active" : (onboardingStep > 4 ? "completed" : ""))}>
                <div onClick={() => navigateOnboardingStep(4)} className="step-counter">4</div>
                {/* <div class="step-name">Third</div> */}
            </div>
        </div>
    )
}

export default MultistepProgressBar