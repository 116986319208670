import Loading from "../../other/Loading";
import "react-datepicker/dist/react-datepicker.css";
import { JobsList } from "../JobsList/JobsList";
import { JobForm } from "../JobsList/JobForm";

export function TabBodyOffice(props) {
    const { 
        activeTab, 
        changeTab, 
        currentUser,
        spotlightActive,
        setUserSpotlight, 
        setSpotlightActive, 
        setPanelsOpen,
        jobs,
        isLoading,
    } = props

    const emptyMessageUnfilled = "No results yet. When you receive a create a new job it'll appear here until someone fills it. Try creating one in the 'Create Job' tab above!"
    const emptyMessageFilled = "No results yet. When you select an applicant for a job, it'll appear here until they complete the job."
    const emptyMessageCompleted = "No results yet. When an applicant completes a job and you mark it complete, that job will appear here!"

    if (activeTab === "create") {
        return (
            <>
                <section id="create-job-page">
                    <JobForm user={currentUser} changeTab={changeTab}/>
                </section>
            </>
        );
    }

    if (activeTab === "unfilled") {
        return isLoading ? (
            <div className="oc-loading-container">
                <div className="loading-container-block">
                    <Loading color={"brown"} />
                </div>
            </div>
        ) : (
            <JobsList 
                jobs={jobs} 
                user={currentUser} 
                activeTab={activeTab} 
                changeTab={changeTab} 
                emptyMessage={emptyMessageUnfilled} 
                spotlightActive={spotlightActive} 
                setUserSpotlight={setUserSpotlight} 
                setSpotlightActive={setSpotlightActive}
            />
        );
    }

    if (activeTab === "filled") {
        return isLoading ? (
            <div className="oc-loading-container">
                <div className="loading-container-block">
                    <Loading color={"brown"} />
                </div>
            </div>
        ) : (
            <JobsList 
                jobs={jobs} 
                user={currentUser} 
                activeTab={activeTab} 
                changeTab={changeTab} 
                emptyMessage={emptyMessageFilled}
                spotlightActive={spotlightActive} 
                setUserSpotlight={setUserSpotlight} 
                setSpotlightActive={setSpotlightActive}
            />
        );
    }

    if (activeTab === "completed") {
        return isLoading ? (
            <div className="oc-loading-container">
                <div className="loading-container-block">
                    <Loading color={"brown"} />
                </div>
            </div>
        ) : (
            <JobsList 
                jobs={jobs} 
                user={currentUser} 
                activeTab={activeTab} 
                changeTab={changeTab} 
                emptyMessage={emptyMessageCompleted}
                spotlightActive={spotlightActive} 
                setUserSpotlight={setUserSpotlight} 
                setSpotlightActive={setSpotlightActive}
            />
        );
    }
}