import React, { useEffect, useState } from "react";
import { Avatar, useChatContext } from "stream-chat-react";

import "./UserList.css";

import { InviteIcon } from "../../assets";

const ListContainer = (props) => {
    const { children, activeTab, id, createType, selectedUsers } = props;
    const { channel } = useChatContext();

    return (
        <div className={createType === "messaging" ? "user-list__container user-list__container_dm" : "user-list__container"}>
            <div className="user-list__header">
                <p>User</p>
                <p>Last Active</p>
                {activeTab === "members" ? (
                    <>{channel?.data?.created_by?.id === id ? <p>Remove{selectedUsers.length ? ' (' + selectedUsers.length + ')' : ''}</p> : ""}</>
                ) : activeTab === "add" ?
                    <>
                        <p>Add{selectedUsers.length ? ' (' + selectedUsers.length + ')' : ''}</p>
                    </>
                    :
                    <>
                        <p>Add{selectedUsers.length ? ' (' + (selectedUsers.length - 1)  + ')' : ''}</p>
                    </>
                }
            </div>
            {children}
        </div>
    );
};

const UserItem = (props) => {
    const { selectedUsers, setSelectedUsers, user, id, activeTab, isEditing, setIsEditing, isCreating, setIsCreating, setUserSpotlight, setSpotlightActive } = props;

    const [selected, setSelected] = useState(false);
    const { channel } = useChatContext();

    useEffect(() => {
        if (selectedUsers.includes(user.id) !== selected) {
            setSelected(selectedUsers.includes(user.id))
        }
    });

    const getLastActive = () => {
        if (!user.last_active) return "";

        const diff = new Date() - new Date(user.last_active);

        if (diff > 86400e3) {
            return Math.floor(diff / 86400e3) + " days ago";
        } else if (diff > 3600e3) {
            return Math.floor(diff / 3600e3) + " hours ago";
        } else if (diff > 60e3) {
            return Math.floor(diff / 60e3) + " minutes ago";
        } else {
            return Math.floor(diff / 1e3) + " seconds ago";
        }
    };

    const handleClick = () => {
        if (userIsSelected()) {
            setSelectedUsers((prevUsers) => prevUsers.filter((prevUser) => prevUser !== user.id));
        } else {
            setSelectedUsers((prevUsers) => [...prevUsers, user.id]);
        }
        setSelected(!selected);
    };

    const handleSpotlight = (userId) => {
        setUserSpotlight(userId);
        setSpotlightActive(true);
    };

    const shouldDisplayAddRemoveIcons = () => {
        return (channel && channel.data && channel.data.created_by && channel.data.created_by.id === id) ||
            isCreating || isEditing && activeTab === "add"
    }

    const userIsSelected = () => {
        return selectedUsers.includes(user.id) || selected
    }

    return (
        <div className="user-item__wrapper">
            <div onClick={() => handleSpotlight(user.id)} className="user-item__name-wrapper">
                <Avatar image={user.image} name={user.name} size={32} />
                <p className="user-item__name">{user.name}</p>
                {isEditing && channel?.data?.created_by?.id === user.id ? <p className="admin-badge">Owner</p> : ""}
            </div>
            <p onClick={() => handleSpotlight(user.id)} className="user-item__last-active">
                {getLastActive()}
            </p>
            <div onClick={handleClick}>
                { shouldDisplayAddRemoveIcons()
                ? userIsSelected() ? <InviteIcon activeTab={activeTab} isEditing={isEditing}/> : <div className="user-item__invite-empty" /> 
                : ""}
            </div>
        </div>
    );
};

export const UserList = (props) => {
    const { filters, selectedUsers, setSelectedUsers, activeTab, id, isEditing, setIsEditing, isCreating, setIsCreating, reloadUsers, setReloadUsers, setUserSpotlight, setSpotlightActive, createType } = props;

    const { client, channel } = useChatContext();

    const [error, setError] = useState(false);
    const [listEmpty, setListEmpty] = useState(false);
    const [tooManyUsers, setTooManyUsers] = useState(false);
    const [loading, setLoading] = useState(false);
    const [users, setUsers] = useState([]);

    useEffect(() => {
        setLoading(false);
        setListEmpty(false);
        setError(false);
        
        const getUsers = async () => {
            if (loading) return;
            setLoading(true);

            if (filters && filters.id && filters.id.$in && !filters.id.$in.length) {
                setListEmpty(true);
                return;
            }

            try {

                let response = {}

                if (activeTab === 'members'){
                    response = await channel.queryMembers({ ...filters });
                    if (response && response.members) {
                        const updatedArray = response.members.map((obj) => ({
                            ...obj,
                            name: obj.user.name,
                            id: obj.user_id
                          }));

                        response.users = updatedArray
                    }
                } else {
                    const allUsers = await client.queryUsers({ id: { $ne: client.userID }, ...filters }, { last_active: -1});
                    if (allUsers && allUsers.users) {
                        if (isCreating){
                            response.users = allUsers.users
                        } else {
                            let sort = {created_at: -1};
                            const {members} = await channel.queryMembers({ id: { $ne: client.userID }, ...filters }, sort, {});

                            if (members.length === 100){
                                response.users = members
                            } else if (members){
                                response.users = allUsers.users.filter((u) => !members.some((member) => u.id === member.user_id));
                            }
                        }
                    }
                }

                if (response.users.length === 100 && activeTab === 'add'){
                    setTooManyUsers(true)
                } else if (response.users.length) {
                    setTooManyUsers(false)
                    setUsers(response.users);
                } else {
                    setListEmpty(true);
                }
            } catch (err) {
                setError(true);
            }

            setLoading(false);
            if (setReloadUsers) {
                setReloadUsers(false);
            }
        };

        if (client) getUsers();
    }, [filters, activeTab, reloadUsers]); // eslint-disable-line react-hooks/exhaustive-deps

    if (error) {
        return (
            <ListContainer activeTab={activeTab} id={id} createType={createType} selectedUsers={selectedUsers}>
                <div className="user-list__message">Error loading, please refresh and try again.</div>
            </ListContainer>
        );
    }

    if (listEmpty) {
        return (
            <ListContainer activeTab={activeTab} id={id} createType={createType} selectedUsers={selectedUsers}>
                <div className="user-list__message">No users found.</div>
            </ListContainer>
        );
    }

    if (tooManyUsers) {
        return (
            <ListContainer activeTab={activeTab} id={id} createType={createType} selectedUsers={selectedUsers}>
                <div className="user-list__message">Enter more letters into the search bar to see results.</div>
            </ListContainer>
        );
    }

    return (
        <ListContainer id={id} activeTab={activeTab} createType={createType} selectedUsers={selectedUsers}>
            {loading ? (
                <div className="user-list__message">Loading users...</div>
            ) : (!users.length) ? <></> : (
                users?.length &&
                users.map((user, i) => (
                    <UserItem
                        key={user.id}
                        selectedUsers={selectedUsers}
                        setSelectedUsers={setSelectedUsers}
                        user={user}
                        id={id}
                        activeTab={activeTab}
                        isEditing={isEditing}
                        setIsEditing={setIsEditing}
                        isCreating={isCreating}
                        setIsCreating={setIsCreating}
                        setUserSpotlight={setUserSpotlight}
                        setSpotlightActive={setSpotlightActive}
                    />
                ))
            )}
        </ListContainer>
    );
};
