
import {LoginMain} from "./LoginMain";

function Forgot() {

    return (
        <LoginMain form={"Forgot"}></LoginMain>
    )
}

export default Forgot;