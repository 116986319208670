
export function TabHeadingFreelancer(props) {

    const {activeTab, pendingUsers, changeTab} = props

    return (
        <div id="oc-tabs">
            <p onClick={() => changeTab("search")} className={activeTab === "search" ? "selected-tab" : ""}>
                Search Jobs
            </p>
            <p onClick={() => changeTab("applied")} className={activeTab === "applied" ? "selected-tab" : ""}>
                Applied
            </p>
            <p onClick={() => changeTab("accepted")} className={activeTab === "accepted" ? "selected-tab" : ""}>
                Accepted
                {pendingUsers.length ? pendingUsers.length < 10 ? <span className="pending-tab-notification">{pendingUsers.length}</span> : <span className="pending-tab-notification">9+</span> : ""}
            </p>
            <p onClick={() => changeTab("completed")} className={activeTab === "completed" ? "selected-tab" : ""}>
                Completed
            </p>
        </div>
    )
}

export function TabHeadingOffice(props) {

    const {activeTab, pendingUsers, changeTab} = props

    return (
        <div id="oc-tabs">
            <p onClick={() => changeTab("create")} className={activeTab === "create" ? "selected-tab" : ""}>
                Create Job
            </p>
            <p onClick={() => changeTab("unfilled")} className={activeTab === "unfilled" ? "selected-tab" : ""}>
                Unfilled
            </p>
            <p onClick={() => changeTab("filled")} className={activeTab === "filled" ? "selected-tab" : ""}>
                Filled
                {pendingUsers.length ? pendingUsers.length < 10 ? <span className="pending-tab-notification">{pendingUsers.length}</span> : <span className="pending-tab-notification">9+</span> : ""}
            </p>
            <p onClick={() => changeTab("completed")} className={activeTab === "completed" ? "selected-tab" : ""}>
                Completed
            </p>
        </div>
    )
}