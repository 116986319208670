import DatePicker from "react-datepicker";
import Loading from "../../other/Loading";
import { React, useState, useEffect } from "react";
import { POST } from "../../../util/api/api";
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/free-solid-svg-icons";

export function JobForm(props){
    const {isEditable, job, changeTab, user} = props

    const [state, setState] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");
    const [startDate, setStartDate] = useState("");
    const [boostJob, setBoostJob] = useState(false);

    useEffect(() => {
        if (isEditable && job) {
            setStartDate(new Date(job.start_time))
            setState({
                ...state,
                employment_type: job.employment_type,
            });
        } else {
            setState({
                ...state,
                employment_type: 'either'
            });
        }
    }, []);

    function handleInput(e) {
        setState({
            ...state,
            [e.target.name]: e.target.value,
        });
    }

    function handlePayRateChange(e) {
        let payRateHelper = false
        const payRate = Number(e.target.value)
        if (state.freelancer_type === "Registered Dental Hygienist (RDH)" || (job && job.freelancer_type === 'Registered Dental Hygienist (RDH)')){
            if (payRate > 49){
                payRateHelper = 3
            } else if (payRate < 45) {
                payRateHelper = 1
            } else {
                payRateHelper = 2
            }
        } else if ((state.freelancer_type === 'Dental Assistant (DA)' || state.freelancer_type === 'Front Desk (FD)') || job && (job.freelancer_type === 'Dental Assistant (DA)' || job.freelancer_type === 'Front Desk (FD)')) {
            if (payRate > 23){
                payRateHelper = 3
            } else if (payRate < 21) {
                payRateHelper = 1
            } else {
                payRateHelper = 2
            }
        }
        
        setState({
            ...state,
            [e.target.name]: e.target.value,
            payrate_helper: payRateHelper
        });
    }

    function handleEmploymentSelect(e) {
        setState({
            ...state,
            employment_type: e.target.name
        });
    }


    function createUTCDate(date, timeString){
        const timeArr = timeString.split(":")
        date.setHours(timeArr[0], timeArr[1], 0, 0)
        date = date.toISOString()
        return date
    }

    function generateTime(date){
        const dateObj = new Date(date)
        let hours = dateObj.getHours()
        let minutes = dateObj.getMinutes()
        if (minutes.toString().length === 1){
            minutes = "0" + minutes.toString()
        }
        if (hours.toString().length === 1){
            hours = "0" + hours.toString()
        }

        return hours + ":" + minutes
    }

    function createInputIsValid(){
        if (!state.freelancer_type){
            alert('Job Type is required')
            return false
        }

        if (!startDate){
            alert('Date is required')
            return false
        }

        if (!state.start_time){
            alert('Start Time is required')
            return false
        }

        if (!state.end_time){
            alert('End Time is required')
            return false
        }

        if (!state.pay_rate){
            alert('Pay is required')
            return false
        }

        if (!state.description){
            alert('Description is required')
            return false
        }
        
        if (state.end_time <= state.start_time){
            alert('End Time must be after Start Time')
            return false
        }
        
        return true
    }

    async function submitJob() {

        setIsLoading(true)
        if (!createInputIsValid()){
            setIsLoading(false)
            return
        }
        
        const body = {
            start_time: createUTCDate(startDate, state.start_time),
            end_time: createUTCDate(startDate, state.end_time),
            pay_rate: state.pay_rate,
            freelancer_type: state.freelancer_type,
            description: state.description,
            job_boosted: boostJob,
            employment_type: state.employment_type
        }

        const data = await POST(`/api/jobs`, body)
        if (data.status === 200){
            changeTab('unfilled')
        } else {
            setErrorMsg('Something went wrong. Please try again later.')
        }
        setIsLoading(false)
    }

    async function editJob(){
        setIsLoading(true)

        if (!startDate){
            alert('Date is required')
            setIsLoading(false)
            return
        }

        if (!job.start_time || !job.end_time){
            alert('An unexpected error ocurred.')
            setIsLoading(false)
            return
        }

        let startTime = state.start_time || generateTime(job.start_time)
        let endTime = state.end_time || generateTime(job.end_time)

        if (endTime <= startTime){
            alert('End Time must be after Start Time')
            setIsLoading(false)
            return
        }

        const body = {
            id: job._id,
            start_time: createUTCDate(startDate, startTime),
            end_time: createUTCDate(startDate, endTime)
        }

        if (state.freelancer_type) body.freelancer_type = state.freelancer_type
        if (state.pay_rate) body.pay_rate = state.pay_rate
        if (state.description || state.description === "") body.description = state.description
        if (state.employment_type) body.employment_type = state.employment_type

        const data = await POST(`/api/jobs/update`, body)
        if (data.status === 200){
            changeTab('unfilled')
        } else {
            setErrorMsg('Something went wrong. Please try again later.')
        }
        setIsLoading(false)

    }

    function handleClick(){
        window.location = "/payments"
    }

    function handleCheckbox(){
        setBoostJob(!boostJob)
    }

    return (
        <div className="job-container">
            {user.stripe_enabled ?
            <>
                <h3>{isEditable ? 'Edit Job Posting' : 'Create New Job Posting'}</h3>
                <hr></hr>
                <p className="text-input-label">Job Type (What position are you trying to fill?)*</p>
                <select name="freelancer_type" defaultValue={isEditable ? job?.freelancer_type : ""} id="job-type-select" onChange={handleInput} required>
                    <option value="" disabled hidden> </option>
                    <option value="Registered Dental Hygienist (RDH)">Registered Dental Hygienist (RDH)</option>
                    <option value="Dental Assistant (DA)">Dental Assistant (DA)</option>
                    <option value="Front Desk (FD)">Front Desk (FD)</option>
                </select>
                <p className="text-input-label">Date*</p>
                <DatePicker className="text-input" selected={startDate} minDate={new Date()} onChange={(date) => setStartDate(date)} />
                <div style={{display: "flex"}}>
                    <p className="text-input-label half-screen-label">Start Time*</p>
                    <p className="text-input-label half-screen-label">End Time*</p>
                </div>
                <div style={{display: "flex"}}>
                    <input className="text-input half-screen-input" defaultValue={isEditable ? generateTime(job.start_time) : ""} type="time" name="start_time" onChange={handleInput} ></input>
                    <input className="text-input half-screen-input" defaultValue={isEditable ? generateTime(job.end_time) : ""} type="time" name="end_time" onChange={handleInput} ></input>
                </div>
                <p className="text-input-label">Pay (per hour)*</p>
                <span id="pay-rate-prefix">$</span>
                <input className="text-input pay-rate" defaultValue={isEditable ? job.pay_rate : ""} type="number" name="pay_rate" onChange={handlePayRateChange}></input>
                {(state.freelancer_type || job && job.freelancer_type) &&  state.payrate_helper ? 
                    <span style={{color: '#909090'}} id="pay-rate-suffix">
                        <FontAwesomeIcon className={
                            state.payrate_helper && state.payrate_helper === 1 ? 'payrate-helper-1' : 
                            state.payrate_helper && state.payrate_helper === 2 ? 'payrate-helper-2' :
                            state.payrate_helper && state.payrate_helper === 3 ? 'payrate-helper-3' : ""
                        } style={{marginRight: "5px", marginBottom: "-1px"}} icon={faCircle}/>
                            {state.payrate_helper && state.payrate_helper === 1 ? 'This pay rate is below average for this position. Increasing it will help attract more applicants' : 
                            state.payrate_helper && state.payrate_helper === 2 ? 'This pay rate is average for this position.' :
                            state.payrate_helper && state.payrate_helper === 3 ? 'This pay rate is above average for this position. This will help attract applicants!' : ""}
                    </span> 
                : ''}
                <p className="text-input-label">Employment Type *</p>
                <div className="employment-button-container">
                    <button name="W2" onClick={handleEmploymentSelect} className={`employment-button ${state.employment_type === 'W2' ? 'employment-button-selected' : ''}`}>W2</button>
                    <button name="1099" onClick={handleEmploymentSelect} className={`employment-button ${state.employment_type === '1099' ? 'employment-button-selected' : ''}`}>1099</button>
                    <button name="either" onClick={handleEmploymentSelect} className={`employment-button ${state.employment_type === 'either' ? 'employment-button-selected' : ''}`}>Whatever the Temp prefers</button>
                </div>
                <p className="text-input-label">Job Description*</p>
                <textarea className="text-input long-text-input" defaultValue={isEditable ? job.description : ""} placeholder="Be sure to include if you work with specialized software, whether applicants need to have any type of previous experience, etc." type="text" name="description" onChange={handleInput} ></textarea>
                <div>
                    <div className="boosted-job-container">
                        <FormControlLabel control={<Checkbox onChange={handleCheckbox} checked={boostJob} sx={{
                            color: '#c02aba',
                            '&.Mui-checked': {
                                color: '#c02aba',
                            },}}
                        />} label="Boost Job"/>
                    </div>
                    <p className="boosted-job-post-text">Boosting this job will send an immediate alert to all nearby temps who have opted into receiving notifications via SMS text and Email for last minute/same day or high priority jobs. Using this service will add a $20 fee at job completion (no fee is charged if jobs are not filled.)</p>
                </div>
                {errorMsg ? <p style={{color: "red"}}>{errorMsg}</p> : ""}
                <button onClick={isEditable ? editJob : submitJob} className="buttonMain">{isLoading ? <Loading size={'small'}/> : 'Submit'}</button>
            </>
            :
            <>
                <h3>Before creating a job you need to set up payments</h3>
                <button onClick={handleClick} style={{width: "248px"}} className="buttonMain">Go to Payments Setup</button>
            </>
        }
            
        </div>
    )
}