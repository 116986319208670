import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationArrow, faEdit, faTrash, faFileContract } from "@fortawesome/free-solid-svg-icons";
import { React, useState} from "react";
import { JobPopup } from "./JobPopup";
import { DELETE} from "../../../util/api/api"
import { useHistory } from "react-router-dom";

export function JobItem(props) {

    const {job, user, activeTab, changeTab, spotlightActive, setUserSpotlight, setSpotlightActive} = props
    const [editPopupActive, setEditPopupActive] = useState(false);
    const [viewPopupActive, setViewPopupActiveState] = useState(false);
    const history = useHistory()
    
    const createdDaysAgo = calculateDaysAgoCreated(job.created_date)
    const freelancerType = formatFreelancerType(job.freelancer_type)
    const freelancerAbbr = getFreelancerAbbreviation(job.freelancer_type)
    const jobDate = formatJobDate(job.start_time)
    const startTime = formatJobTime(job.start_time)
    const endTime = formatJobTime(job.end_time)
    const isEditable = activeTab === 'unfilled' && user.user_type === 'office' && job?.applicants?.length < 1
    const isDeletable = (activeTab === 'unfilled' || activeTab === 'filled') && user.user_type === 'office'

    function setViewPopupActive(active){
        setViewPopupActiveState(active)
        if (active){
            history.push({
                search: `job_id=${job._id}`
            })
        } else {
            history.push({
                search: ''
            })
        }
    }

    function launchEditablePopup(e){

        const editIcons = document.getElementsByClassName("edit-icons-container")

        for (let i = 0; i < editIcons.length; i++ ){
            if (editIcons[i].contains(e.target)){
                return
            }
        }

        setViewPopupActive(true)
    }

    async function deleteJob(){
        if (!window.confirm('Are you sure you want to delete this job?')){
            return
        }

        try {
            const result = await DELETE(`/api/jobs/${job._id}`)
            if(result.status === 200){
                changeTab(activeTab)
            }
        } catch (err){
            console.log(err)
            alert('An error occurred -- please try again later.')
        }
    }

    async function editJob(){
        setEditPopupActive(true)
    }

    function formatDaysTilJobText(){
        const startTime = new Date(job.start_time)
        const endTime = new Date(job.end_time)
        const now = new Date()
        let daysTilJob = Math.round((startTime - now)/1000/60/60/24)

        if (daysTilJob < 0) daysTilJob = 0

        if (user.user_type === 'office' && startTime <= now && endTime > now){
            return 'job in progress'
        } else if (user.user_type === 'office' && endTime <= now){
            return 'job has ended, please mark complete'
        } else {
            if (daysTilJob === 1){
                return `job starts in ${daysTilJob} day`
            } else {
                return `job starts in ${daysTilJob} days`
            }
        }
    }

    function HelpText(){

        let helpText = ""

        if (user.user_type === 'office'){
            if (activeTab === 'unfilled'){
                helpText = <span className="applicant-help-text"> • &nbsp; {job.applicants.length} applicants</span>
            } else if (activeTab === 'filled'){
                helpText = <span className="applicant-help-text"> • &nbsp; {formatDaysTilJobText()}</span>
            }
        } else if (user.user_type === 'freelancer'){
            if (activeTab === 'applied') {
                helpText = <span className="applicant-help-text"> • &nbsp; waiting for dental office</span>
            } else if (activeTab === 'accepted'){
                helpText = <span className="applicant-help-text"> • &nbsp; {formatDaysTilJobText()}</span>
            }
        }

        return (helpText)
    }

    return (
        <>
        {editPopupActive ? <JobPopup view="edit" setPopupActive={setEditPopupActive} activeTab={activeTab} changeTab={changeTab} user={user} job={job}/> : ""}
        {viewPopupActive ? <JobPopup view="view" setPopupActive={setViewPopupActive} activeTab={activeTab} changeTab={changeTab} user={user} job={job} spotlightActive={spotlightActive} setUserSpotlight={setUserSpotlight} setSpotlightActive={setSpotlightActive}/> : ""}
            <div onClick={(e) => launchEditablePopup(e)} className="job-item-container">
                <h4><OfficeHeadingOptions job={job} user={user} activeTab={activeTab} /> <HelpText /></h4>
                <span className="created-since">{createdDaysAgo}</span>

                <div>
                    {isDeletable
                        ? <span onClick={deleteJob} className="edit-icons-container"><FontAwesomeIcon className="delete-icon" icon={faTrash} /></span>
                        : ""
                    }
                    {isEditable
                        ? <span onClick={editJob} className="edit-icons-container"><FontAwesomeIcon className="edit-icon" icon={faEdit} /></span>
                        : ""
                    }
                </div>

                <div>
                    {freelancerType ? <span className={`freelancer-${freelancerAbbr} pill-info`}>{freelancerType}</span> : ""}
                </div>
                <div style={{marginTop: "5px"}}>
                    <span className="job-datetime-pill pill-info">{jobDate}</span>
                    <span className="job-datetime-pill pill-info">{startTime + " - " + endTime}</span>
                    <span className="job-pay-pill pill-info">{'$' + job.pay_rate + "/hr"}</span>
                    {job.employment_type ? 
                        <span className="job-datetime-pill pill-info">
                            <FontAwesomeIcon style={{marginRight: '5px'}} icon={faFileContract}/>
                            {job.employment_type === 'either' ? 'W2/1099' : ''}
                        </span> 
                    : ''}
                </div>
                <p>{job.description}</p>
                <div className="location-container"><FontAwesomeIcon className="location-arrow" icon={faLocationArrow} /> <span className="job-location">{job?.office_id?.location?.address?.city + ", " + job?.office_id?.location?.address?.state}</span></div>
                
            </div>
        </>
    )
}

export function formatJobTime(time){
    const dateObj = new Date(time)
    let timeFormatted = dateObj.toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'})
    const arr = timeFormatted.split(" ")
    timeFormatted = arr[0] + arr[1].toLowerCase()
    if (timeFormatted[0] === '0'){
        timeFormatted = timeFormatted.slice(1);
    }
    return timeFormatted
}

export function formatJobDate(startTime){
    const dateObj = new Date(startTime)
    const day = dateObj.getDate();
    const month = dateObj.getMonth() + 1;
    const year = dateObj.getFullYear();

    return `${month}/${day}/${year}`
}

export function formatFreelancerType(freelancerType){
    if (freelancerType === 'Registered Dental Hygienist (RDH)'){
        return 'Registered Dental Hygienist'
    } else if (freelancerType === 'Front Desk (FD)'){
        return 'Front Desk'
    } else if (freelancerType === 'Dental Assistant (DA)'){
        return 'Dental Assistant'
    } else {
        return ''
    }
}

export function OfficeHeadingOptions(props){

    const {job, user, activeTab, setUserSpotlight, setSpotlightActive} = props

    let officeTitle;

    if (user.user_type === 'freelancer'){
        if (activeTab === 'accepted' || activeTab === 'completed'){
            officeTitle = <span className='office-heading-style' onClick={() => {setUserSpotlight(job?.office_id?._id.toString()); setSpotlightActive(true)}}>{job?.office_id?.office_name}</span>
        } else {
            officeTitle = <span className='office-heading-style' onClick={() => {setUserSpotlight(job?.office_id?._id.toString()); setSpotlightActive(true)}}>{job?.office_id?.office_type + ' Office'}</span>
        }
    } else {
        officeTitle = job?.office_id?.office_name
    }

    return (officeTitle)
}

function getFreelancerAbbreviation(freelancerType){
    let str = freelancerType.split('(')[1]
    str = str.split(')')[0]
    return str
}

function calculateDaysAgoCreated(createdDate){
    const today = new Date()
    const dateListed = new Date(createdDate)
    const timeinmilisec = today.getTime() - dateListed.getTime();
    let daysAgoCreated = timeinmilisec / (1000 * 60 * 60 * 24);
    let daysAgoCreatedRounded = Math.round(daysAgoCreated)

    if (daysAgoCreatedRounded < 1) {
        return `Created just now`
    } else if (daysAgoCreatedRounded === 1) {
        return `Created ${daysAgoCreatedRounded} day ago`
    } else if (daysAgoCreatedRounded > 1) {
        return `Created ${daysAgoCreatedRounded} days ago`
    } else {
        return ''
    }
} 