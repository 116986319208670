
import { GET, POST } from "../../util/api/api";
import "./main.css";
import { React, useState, useEffect } from "react";
import Loading from "../other/Loading";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Transactions } from "./Transactions";
import { PaymentMethod } from "./PaymentMethod";
import { useLocation, useHistory } from 'react-router-dom';
import { configure } from "@testing-library/react";


export function Payments(){
    const [isLoading, setIsLoading] = useState(true);
    const [dashboardLink, setDashboardLink] = useState('');
    const [configurePaymentsLink, setConfigurePaymentsLink] = useState('');
    const [paymentsEnabled, setPaymentsEnabled] = useState(false);
    const [userType, setUserType] = useState('')
    const [txs, setTxs] = useState('')
    const [hasMoreTxs, setHasMoreTxs] = useState(false)
    const [hasPreviousTxs, setHasPreviousTxs] = useState(false)
    const [activeTab, changeTab] = useState('transactions')
    const location = useLocation();
    const history = useHistory();
    
    useEffect(() => {
        async function getStripeAccount(){

            let stripePaymentsEnabled;
            

            const {data} = await GET("/other/stripe/account")
            stripePaymentsEnabled = data.user_type === 'office' ? data.charges_enabled : data.payouts_enabled

            setConfigurePaymentsLink(data.user_type === 'office' ? data.save_payment_link : data.account_link)
            setPaymentsEnabled(stripePaymentsEnabled)
            setDashboardLink(data.login_link)
            setUserType(data.user_type)
            
            const searchParams = new URLSearchParams(location.search);
            const sessionID = searchParams.get('session_id')
            if(sessionID){
                const res = await POST("/other/stripe/update-payment-method", {sessionID})
                if (res?.success){
                    const { pathname } = location;
                    history.push({ pathname });
                }
            }

            if (stripePaymentsEnabled){
                const {data} = await GET("/other/stripe/transactions")
                setTxs(data.data)
                setHasMoreTxs(data.has_more)
            }

            setIsLoading(false)

        }

        getStripeAccount()
    }, []);

    async function getTxsWithPagination(queryString){
        const {data} = await GET(`/other/stripe/transactions?${queryString}`)
        setTxs(data.data)
        return data
    }

    async function getNextTxs(){
        let queryString;

        if (hasMoreTxs){
            queryString = `starting_after=${txs[txs.length - 1].id}`
        }

        const data = await getTxsWithPagination(queryString)
        setHasMoreTxs(data.has_more)
        setHasPreviousTxs(true)
    }

    async function getPreviousTxs(){
        let queryString;

        if(hasPreviousTxs){
            queryString = `ending_before=${txs[0].id}`
        }

        const data = await getTxsWithPagination(queryString)
        setHasMoreTxs(true)
        setHasPreviousTxs(data.has_more)
    }

    return (
        <main id='payments-page'>
            <div id="oc-tabs">
                <p onClick={() => changeTab("transactions")} className={activeTab === "transactions" ? "selected-tab" : ""}>
                    Transactions
                </p>
                <p onClick={() => changeTab("payment_method")} className={activeTab === "payment_method" ? "selected-tab" : ""}>
                    Payment Method
                </p>
            </div>
            {activeTab === 'transactions' ?
                <div className="job-container">
                    {isLoading ?
                        <Loading size={'large'} color={'brown'}/> :
                        paymentsEnabled ?
                        <>
                            <Transactions txs={txs} userType={userType} dashboardLink={dashboardLink}/>
                        </>
                        :
                        <PaymentsNotConfigured configurePaymentsLink={configurePaymentsLink}/>
                    }
                    {hasPreviousTxs ?
                    <p onClick={getPreviousTxs} className="pagination-select">{'← Previous Page'}</p>
                    : ''}
                    {hasMoreTxs ?
                    <p onClick={getNextTxs} className="pagination-select">{'Next Page →'}</p>
                    : ''}
                </div>
            : 
                <div className="job-container">
                    <PaymentMethod />
                </div>
            }
            
        </main>
    )
}

export function PaymentsNotConfigured(props){
    const {configurePaymentsLink} = props

    function handleSetUpClick(){
        window.location.href = configurePaymentsLink;
    }

    return (
        <>
            <h3>Payment accounts not configured yet. Click below to set up payments.</h3>
            <h3>If you have recently set up payments please wait up to 5 minutes and refresh the page.</h3>
            <hr></hr>
            <button onClick={handleSetUpClick} className="buttonMain">Set Up Payments</button>
        </>
    )
}