import React, { useState, useEffect } from "react";
import { Redirect, Route, useLocation } from "react-router-dom";
import axios from "axios";
import { apiDomain } from "../../../util/constants";
import { isTokenLive, deleteToken } from "../../../util/auth/auth";
import Header from "../../Header";
import { Userpilot } from "userpilot";

const AdminRoute = ({ component: Component, ...rest }) => {
    const [isAuthorized, setAuthorized] = useState(undefined);
    const location = useLocation();

    useEffect(() => {
        Userpilot.reload();
    }, [location]);

    useEffect(() => {
        async function go() {
            try {
                if (!isTokenLive()) {
                    deleteToken();
                    window.location.href = "/login";
                    return;
                }

                const data = await axios.get(`${apiDomain}/other/admin/authenticate`);

                if (data.status === 200) {
                    setAuthorized(true);
                }
            } catch (err) {
                if (err.response && err.response.status === 401) {
                    setAuthorized(false);
                    deleteToken();
                    window.location.href = "/login";
                    return;
                }
            }
        }

        go();
    }, []);

    if (isAuthorized === undefined) {
        return <></>;
    } else {
        return (
            <>
                <Route
                    {...rest}
                    render={(props) =>
                        isAuthorized ? (
                            <Component {...props} />
                        ) : (
                            <Redirect
                                to={{
                                    pathname: "/login",
                                }}
                            />
                        )
                    }
                />
            </>
        );
    }
};

export default AdminRoute;
