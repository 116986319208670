import { BrowserRouter, Switch, Route, Redirect, useLocation } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { Payments } from "./payments/Payments";
import Messages from "./messages/Messages";
import Jobs from "./jobs/Jobs";
import Onboard from "./onboard/Onboard";
import Login from "./login-signup/Login";
import Logout from "./login-signup/Logout";
import Register from "./login-signup/Register";
import Forgot from "./login-signup/Forgot";
import Reset from "./login-signup/Reset";
import Admin from "./admin/Admin";
import Support from "./other/helperPages/Support";
import Share from "./other/helperPages/Share";
import Settings from "./settings/Settings";
import PrivateRoute from "./other/auth/PrivateRoute";
import AuthRoute from "./other/auth/AuthRoute";
import OnboardRoute from "./other/auth/OnboardRoute";
import AdminRoute from "./other/auth/AdminRoute";
import MobileRedirect from "./other/helperPages/MobileRedirect";

function Router() {

    return (
        <BrowserRouter>
            <Switch>
                <Route exact path="/">
                    <Redirect to="/jobs" />
                </Route>
                <AdminRoute exact path="/admin" component={Admin} />
                <PrivateRoute exact path="/jobs" component={Jobs} />
                <PrivateRoute exact path="/payments" component={Payments} />
                <PrivateRoute exact path="/messages" component={Messages} />
                <AuthRoute exact path="/login" component={Login} />
                <AuthRoute exact path="/register" component={Register} />
                <AuthRoute exact path="/forgot" component={Forgot} />
                <AuthRoute exact path="/reset/:id-:token" component={Reset} />
                <Route exact path="/logout" component={Logout} />
                <Route exact path="/mobile-redirect" component={MobileRedirect} />
                <PrivateRoute exact path="/support" component={Support} />
                <PrivateRoute exact path="/share" component={Share} />
                <PrivateRoute exact path="/settings" component={Settings} />
                <OnboardRoute exact path="/onboard" component={Onboard} />
            </Switch>
        </BrowserRouter>
    );
}

export default Router;
