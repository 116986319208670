
import { Redirect } from 'react-router-dom'
import { deleteToken } from '../../util/auth/auth';

function Logout() {

    deleteToken()

    return (
        <Redirect to={{pathname: "/login"}}/>
    )
}

export default Logout;