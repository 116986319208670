import Router from "./components/Router";
import React, { useEffect } from "react";
import { Userpilot } from "userpilot";
import { useLocation } from "react-router-dom";

Userpilot.initialize("NX-44f916d4");

function App() {
  
    return (
        <div className="App">
            <Router />
        </div>
    );
}

export default App;
