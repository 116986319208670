import "./admin.css";
import { GET, POST, DELETE } from "../../util/api/api";
import { React, useState, useEffect } from "react";
import Loading from "../other/Loading"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import {getAmountToChargeOffice, getWagesToFreelancer, getPlatformFees} from "../jobs/JobsList/JobView"

function Admin() {
    const [searchTerm, setSearchTerm] = useState("");
    const [users, setUsers] = useState([]);
    const [isLoading, setIsLoading] = useState(false)
    const [jobs, setJobs] = useState([])
    const [selectedUser, setSelectedUser] = useState("")

    async function handleClick() {
        setIsLoading(true)
        setSelectedUser("");
        const { data } = await GET(`/other/admin/search?keyword=${searchTerm}`);
        setUsers(data);
        setIsLoading(false)
    }

    async function deleteJob(jobID){
        setIsLoading(true)
        if (window.confirm("Are you sure you want to delete this job? It will permanently remove this job and send a notification email to the associated users.")) {
            const { data } = await DELETE(`/api/jobs/${jobID}`);
            await getUserJobs(selectedUser)
        }
        
        setIsLoading(false)
    }

    function handleChange(e) {
        setSearchTerm(e.target.value);
    }

    function handleKeyPress(e) {
        if (e.which === 13) {
            handleClick();
        }
    }

    function formatDate(datetime){
        
        const d = new Date(datetime)
        const minutes = d.getMinutes() === 0 ? '00' : d.getMinutes()
        const formattedDate = `${d.getMonth() + 1}/${d.getDate()}/${d.getFullYear()} ${d.getHours()}:${minutes}`  
        return formattedDate
    }

    function getHours(job){
        const startTime = new Date(job.start_time)
        const endTime = new Date(job.end_time)
        let hoursWorked = (endTime.getTime() - startTime.getTime())/(1000 * 60 * 60)
        hoursWorked = Math.round(hoursWorked * 100) / 100
        return hoursWorked
    }

    function handleRealUserClick(user) {
        setJobs('')
        setSelectedUser(user);
    }

    async function getUserJobs(userID){
        setIsLoading(true)
        setSelectedUser(userID)
        const {data} = await GET(`/api/jobs?user_id=${userID}`);
        setJobs(data)
        setIsLoading(false)
    }

    async function verifyUser(id){
        try {
            const result = await POST(`/other/admin/verify/${id}`)
            if(result.status === 200){
                handleClick()
            }
        } catch (err){
            console.log(err)
            alert('An error occurred -- please try again later.')
        }
    }

    async function unverifyUser(id){
        try {
            const result = await POST(`/other/admin/unverify/${id}`)
            if(result.status === 200){
                handleClick()
            }
        } catch (err){
            console.log(err)
            alert('An error occurred -- please try again later.')
        }
    }

    return (
        <main>
            <section id="admin-header">
                <input placeholder="Search for users" onKeyPress={handleKeyPress} onChange={handleChange}></input>
                {isLoading ? <Loading size="small" color="blue"/> : <button onClick={handleClick}>Search</button> }
            </section>
            <section id="admin-user-results">
                <div className="column-left">
                    <p>Real Users</p>

                    {users && users.length > 0
                        ? users
                              .filter((user) => !user.is_dummy_user)
                              .map((user, i) => (
                                  <div key={i} className={`user-profile ${selectedUser === user._id ? "profile-selected-keep" : ""}`}>
                                    <button className="see-jobs-button" onClick={() => getUserJobs(user._id)}>see all jobs</button>
                                      <div className="user-info-container">
                                          <p className="user-name">
                                              <b>
                                                  {user.first_name} {user.last_name}
                                              </b>
                                          </p>
                                      </div>
                                      <div className="user-info-container">
                                          <p className="user-label">User ID: </p>
                                          <p className="user-item">{user._id}</p>
                                      </div>
                                      <div className="user-info-container">
                                          <p className="user-label">User Type: </p>
                                          <p className="user-item">{user.user_type}</p>
                                      </div>
                                      <div className="user-info-container">
                                          <p className="user-label">Email: </p>
                                          <p className="user-item">{user.email}</p>
                                      </div>
                                      <div className="user-info-container">
                                          <p className="user-label">Phone Number: </p>
                                          <p className="user-item">{user.phone_number}</p>
                                      </div>
                                      <div className="user-info-container">
                                          <p className="user-label">Cell Phone Number: </p>
                                          <p className="user-item">{user.cell_phone_number}</p>
                                      </div>
                                      <div className="user-info-container">
                                          <p className="user-label">Office Name: </p>
                                          <p className="user-item">{user.office_name}</p>
                                      </div>
                                      <div className="user-info-container">
                                          <p className="user-label">Location: </p>
                                          <p className="user-item">{user?.location?.address?.address} {user?.location?.address?.city}, {user?.location?.address?.state} {user?.location?.address?.zip}</p>
                                      </div>
                                      <div className="user-info-container">
                                          <p className="user-label">Office Type: </p>
                                          <p className="user-item">{user.office_type}</p>
                                      </div>
                                      <div className="user-info-container">
                                          <p className="user-label">No. of Operatories: </p>
                                          <p className="user-item">{user.operatories_amt}</p>
                                      </div>
                                      <div className="user-info-container">
                                          <p className="user-label">Expertise: </p>
                                          <p className="user-item">{user.expertise}</p>
                                      </div>
                                      <div className="user-info-container">
                                          <p className="user-label">Years of Experience: </p>
                                          <p className="user-item">{user.years_of_experience}</p>
                                      </div>
                                      <div className="user-info-container">
                                          <p className="user-label">Bio: </p>
                                          <p className="user-item">{user.bio}</p>
                                      </div>
                                      <div className="user-info-container">
                                          <p className="user-label">Onboarding Complete: </p>
                                          <p className="user-item">{user.onboarding_complete.toString()}</p>
                                      </div>
                                      <div className="user-info-container">
                                          <p className="user-label">Onboarding Stage: </p>
                                          <p className="user-item">{user.onboarding_stage}</p>
                                      </div>
                                      <div className="user-info-container">
                                          <p className="user-label">ID Image: </p>
                                          <p className="user-item" style={{cursor: "pointer"}} onClick={() => handleRealUserClick(user)}>{user.id_img ? '[click to view]' : ''}</p>
                                      </div>
                                      <div className="user-info-container">
                                          <p className="user-label">Dental License #: </p>
                                          <p className="user-item">{user.dental_license}</p>
                                      </div>
                                      <div className="user-info-container">
                                          <p className="user-label">Verification State: </p>
                                          <p className="user-item" style={{marginRight: "15px"}}>{user.verification}</p>
                                          {user.verification === 'pending'
                                            ? <button onClick={() => verifyUser(user._id)}>verify</button>
                                            : user.verification === 'verified'
                                                ? 
                                                <>
                                                    <FontAwesomeIcon style={{color: "lightgreen", marginRight: "15px"}} className="applied-icon" icon={faCheck} />
                                                    <button onClick={() => unverifyUser(user._id)}>unverify</button>
                                                </>
                                                : ""
                                          }
                                      </div>
                                  </div>
                              ))
                        : ""}
                </div>

                <div className="column-right">
                    {jobs 
                    ?
                    <>
                        <p>User Jobs</p>
                        {jobs.map((job, i) => (
                            <div key={i} className="user-profile">
                                <button className="delete-job" onClick={() => deleteJob(job._id)}>Delete Job</button>
                                <div className="user-info-container">
                                    <p className="user-label">Job ID:</p>
                                    <p className="user-item">{job._id}</p>
                                </div>
                                <div className="user-info-container">
                                    <p className="user-label">Office Name:</p>
                                    <p className="user-item">{job?.office_id?.office_name}</p>
                                </div>
                                <div className="user-info-container">
                                    <p className="user-label">Freelancer Name:</p>
                                    <p className="user-item">{job?.freelancer_id?.first_name} {job?.freelancer_id?.last_name}</p>
                                </div>
                                <div className="user-info-container">
                                    <p className="user-label">Start Time:</p>
                                    <p className="user-item">{formatDate(job.start_time)}</p>
                                </div>
                                <div className="user-info-container">
                                    <p className="user-label">End Time:</p>
                                    <p className="user-item">{formatDate(job.end_time)}</p>
                                </div>
                                <div className="user-info-container">
                                    <p className="user-label">Hours:</p>
                                    <p className="user-item">{getHours(job)}</p>
                                </div>
                                <div className="user-info-container">
                                    <p className="user-label">Adjusted Hours:</p>
                                    <p className="user-item">{job.adjusted_hours_amt}</p>
                                </div>
                                <div className="user-info-container">
                                    <p className="user-label">Pay Rate:</p>
                                    <p className="user-item">${job.pay_rate}/hr</p>
                                </div>
                                <div className="user-info-container">
                                    <p className="user-label">Job Boosted:</p>
                                    <p className="user-item">{job?.job_boosted?.toString()}</p>
                                </div>
                                <div className="user-info-container">
                                    <p className="user-label">Amount to Send to Temp:</p>
                                    <p className="user-item">$ {getWagesToFreelancer(job, 0, 'completed')}</p>
                                </div>
                                <div className="user-info-container">
                                    <p className="user-label">Platform Fees:</p>
                                    <p className="user-item">$ {getPlatformFees(job, 0, 'completed')}</p>
                                </div>
                                <div className="user-info-container">
                                    <p className="user-label">Job Boosted Fee:</p>
                                    <p className="user-item">$ {job?.job_boosted_amt}</p>
                                </div>
                                <div className="user-info-container">
                                    <p className="user-label">Amount to Charge Office:</p>
                                    <p className="user-item">$ {getAmountToChargeOffice(job, 0, 'completed')}</p>
                                </div>
                                <div className="user-info-container">
                                    <p className="user-label">Status:</p>
                                    <p className="user-item">{job.status}</p>
                                </div>
                                <div className="user-info-container">
                                    <p className="user-label">Freelancer Type:</p>
                                    <p className="user-item">{job.freelancer_type}</p>
                                </div>
                                <div className="user-info-container">
                                    <p className="user-label">Applicants:</p>
                                    <p className="user-item">{job?.applicants?.length}</p>
                                </div>
                                <div className="user-info-container">
                                    <p className="user-label">Archived Applicants:</p>
                                    <p className="user-item">{job?.archived_applicants?.length}</p>
                                </div>
                                <div className="user-info-container">
                                    <p className="user-label">Archived:</p>
                                    <p className="user-item">{job.archived ? job.archived.toString() : 'false'}</p>
                                </div>
                            </div>
                        ))}
                        
                    </>
                    : selectedUser ?
                    <>
                        <p>User License Photo</p>
                        <img style={{maxWidth: "700px"}} src={selectedUser.id_img}></img>  
                    </>
                    :
                    <p>.</p>
                    }
                     
                </div>
            </section>
        </main>
    );
}

export default Admin;
