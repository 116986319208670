import React, { useEffect, useState } from 'react';
import { StreamChat } from 'stream-chat';
import {useLocation} from "react-router-dom";
import { Chat, enTranslations, Streami18n, useChatContext } from 'stream-chat-react';
import { GET } from "../../util/api/api"

import 'stream-chat-react/dist/css/index.css';
import './App.css';

import { ChannelContainer } from './components/ChannelContainer/ChannelContainer';
import { ChannelListContainer } from './components/ChannelListContainer/ChannelListContainer';

const theme = 'light';

const i18nInstance = new Streami18n({
  language: 'en',
  translationsForLanguage: {
    ...enTranslations,
  },
});

const options = { state: true, watch: true, presence: true };
const sort = { last_message_at: -1, updated_at: -1 };

const Messages = (props) => {
  const {isMobile} = props
  const [client, setClient] = useState(false)
  const [filters, setFilters] = useState(false)
  const [userData, setUserData] = useState(false)
  const [userVerificationStatus, setUserVerificationStatus] = useState(true)
  const [loadSpecificChannel, setLoadSpecificChannel] = useState(false);
  const [idToLoadChannel, setIdToLoadChannel] = useState('')
  const [showNotificationBanner, setShowNotificationBanner] = useState(false);

  useEffect(() => {
    async function authenticate(){
      const {data} = await GET("/api/messages/authenticate")

      if (data?.data?.user_type === "freelancer"){
        setUserVerificationStatus(data?.data?.verification)
        if (data?.data?.verification !== 'verified'){
          return
        }
      } else {
        setUserVerificationStatus('verified')
      }

      const chatClient = StreamChat.getInstance(data.app_key)
      chatClient.connectUser({ id: data.id }, data.token);

      const setChannelListeners = setInterval( async () => {
        const chans = Object.keys(chatClient.activeChannels)

        if (chans.length){
          clearInterval(setChannelListeners)

          for (let i=0; i<chans.length; i++){
            const [existingChannel] = await chatClient.queryChannels({cid: chans[i]});

            if (existingChannel){
              existingChannel.on('message.new', event => {
                if (event.unread_count > 0 && event.user.id !== chatClient.user.id) {
  
                  let mentionedInMessage;
                  if (event.channel_type == "team" && event.message.mentioned_users){
                      for (let i=0; i<event.message.mentioned_users.length; i++){
  
                        if (event.message.mentioned_users[i].id == chatClient.user.id) {
                          mentionedInMessage = true
                        }
                      }
                      if (!mentionedInMessage){return}
                  }
  
                  new Notification(`New Message from ${event.message.user.name}`, {
                      body: event.message.text,
                  });
  
                  var audio = new Audio('notification.wav');
                  audio.play();
  
                  document.getElementById('favicon').href = 'https://emojipedia-us.s3.dualstack.us-west-1.amazonaws.com/thumbs/240/google/223/bell_1f514.png';
                }
              });
              // does this need to come out of the function? I moved it here to fix something
              existingChannel.on('message.read', event => {
                if (!event.total_unread_count && event.user.id == chatClient.user.id) {
                  document.getElementById('favicon').href = '/favicon.ico';
                }
              })

            }  
          }
        }
      }, 200);

      setClient(chatClient)
      setUserData(data.data)
      setFilters([
        { type: 'team', members: { $in: [data.id]}},
        { type: 'messaging', members: { $in: [data.id]}},
      ])
    }

    function getQueryParams(queryParams){
      const params = queryParams.split("=")

      if (params[0] === "?id"){
        setLoadSpecificChannel(true)
        setIdToLoadChannel(params[1])
      }
    }

    authenticate()

    if (props && props.location && props.location.search){
      getQueryParams(props.location.search)
    }

    if (window.Notification && (Notification.permission === 'granted' ||
      Notification.permission === 'denied')){
        return;
    }
    setShowNotificationBanner(true);


  }, []);
  
  return  (
    !userVerificationStatus || userVerificationStatus === "pending" ?
    <div>
      <div className="demo-mask">
            <div className="demo-container">
                <div style={{marginTop: "35px"}}>
                  {userVerificationStatus === 'pending' ?
                  <p>
                      You'll need to complete your profile verification before sending messages on DentalWORKS. 
                      <br></br>
                      <br></br>
                      Your ID has been submitted. Please allow up to 24 hours for someone on our team to confirm your identity.<br></br>
                      <br></br>
                  </p>
                  :
                  <>
                    <p>
                          You'll need to complete your profile verification before sending messages on DentalWORKS. Click below to upload your ID.<br></br>
                          <br></br>
                      </p>
                      <button onClick={() => window.location = "/onboard"} style={{color: 'white', padding: '8px', fontSize: '16px'}} className='buttonMain'>Upload ID</button>
                  </>
                  }
                </div>
            </div>
        </div>
    </div> 
    :
    userVerificationStatus === 'verified' && client && filters ? <ChatClient 
      client={client} 
      filters={filters} 
      id={userData._id} 
      connections={userData.connections} 
      isMobile={isMobile} 
      loadSpecificChannel={loadSpecificChannel} 
      idToLoadChannel={idToLoadChannel} 
      showNotificationBanner={showNotificationBanner}
      setShowNotificationBanner={setShowNotificationBanner}
      /> : <></>
  )
}

const ChatClient = (props) => {
  const {client, filters, connections, id, isMobile, loadSpecificChannel, idToLoadChannel, showNotificationBanner, setShowNotificationBanner} = props

  const [createType, setCreateType] = useState('');
  const [isCreating, setIsCreating] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [panelsOpen, setPanelsOpen] = useState({
    left: true, 
    center: isMobile ? false : true, 
    right: isMobile ? false : true
  })

  function grantPermission() {
    if (Notification.permission === 'granted') {
      new Notification('You are already subscribed to web notifications');
      return;
    }
    if (
      Notification.permission !== 'denied' ||
      Notification.permission === 'default'
    ) {
      Notification.requestPermission().then(result => {
        if (result === 'granted') {
          new Notification('New message from DentalWORKS', {
            body: 'Nice, notifications are now enabled!',
          });
        }
      });
    }
    setShowNotificationBanner(false);
  }

  return (
      <div className='app__wrapper'>
        <Chat {...{ client, i18nInstance }} theme={`team ${theme}`}>
          {showNotificationBanner && (
            <div class="browser-notification-alert">
              <p>DentalWORKS needs your permission to{' '}
                <button onClick={grantPermission}>
                  enable desktop notifications
                </button>
              </p>
            </div>
          )}
          <ChannelListContainer
            {...{
              isCreating,
              filters,
              options,
              setCreateType,
              setIsCreating,
              setIsEditing,
              sort,
              connections,
              isMobile,
              panelsOpen,
              setPanelsOpen,
              loadSpecificChannel,
              idToLoadChannel
            }}
          />
          <ChannelContainer
            {...{
              createType,
              isCreating,
              isEditing,
              setIsCreating,
              setIsEditing,
              connections,
              id,
              isMobile,
              panelsOpen,
              setPanelsOpen,
            }}
          />
        </Chat>
      </div>
  );
};

export default Messages;
