import "./main.css";
import { React, useState, useEffect } from "react";
import { GET } from "../../util/api/api";
import Loading from "../other/Loading";
import UserProfile from "../messages/components/UserProfile/UserProfile";
import { TabHeadingFreelancer, TabHeadingOffice } from "./tabs-heading/TabHeading";
import { TabBodyOffice } from "./tabs-body/TabBodyOffice";
import { TabBodyFreelancer } from "./tabs-body/TabBodyFreelancer";
import { useLocation } from 'react-router-dom';

function Jobs(props) {
    const { isMobile } = props;
    const [users, setUsers] = useState([]);
    const [jobs, setJobs] = useState([]);
    const [pendingUsers, setPendingUsers] = useState([]);
    const [currentSearchedUsers, setCurrentSearchedUsers] = useState([]);
    const [activeTabOffice, setActiveTabOffice] = useState("create");
    const [activeTabFreelancer, setActiveTabFreelancer] = useState("search");
    const [currentUser, setCurrentUser] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [userSpotlight, setUserSpotlight] = useState("");
    const [spotlightActive, setSpotlightActive] = useState(false);
    const [panelsOpen, setPanelsOpen] = useState({
        left: false,
        center: true,
        right: isMobile ? false : true,
    });
    const location = useLocation();

    useEffect(() => {
        async function getCurrentUser() {
            const { data } = await GET(`/api/user`);
            if (data.user_type === 'freelancer'){
                changeTabFreelancer('search')
            } else {
                const searchParams = new URLSearchParams(location.search);
                const jobID = searchParams.get('job_id')

                if (jobID){
                    // Redirect to any tab that loads the job from the API. It will redirect to the correct tab if it needs to through that process
                    changeTabOffice('unfilled')
                }
            }
 
            setCurrentUser(data);
            setPendingUsers(data.requests_received ? data.requests_received : [])
        }
        getCurrentUser();
    }, []);

    async function changeTabOffice(newTab, loadingSpecificJobOnLoad) {
        setUsers([]);
        setActiveTabOffice(newTab);

        if (loadingSpecificJobOnLoad) return;

        if (newTab === "create") {}

        if (newTab === "unfilled") {
            setIsLoading(true);
            try{
                const { data } = await GET(`/api/jobs/unfilled`);
                setJobs(data);
                setIsLoading(false);
            } catch(err){
                console.log(err)
                setIsLoading(false);
            }
        }

        if (newTab === "filled") {
            setIsLoading(true);
            try{
                const { data } = await GET(`/api/jobs/filled`);
                setJobs(data);
                setIsLoading(false);
            } catch(err){
                console.log(err)
                setIsLoading(false);
            }
        }

        if (newTab === "completed") {
            setIsLoading(true);
            try{
                const { data } = await GET(`/api/jobs/completed`);
                setJobs(data);
                setIsLoading(false);
            } catch(err){
                console.log(err)
                setIsLoading(false);
            }
        }
    }

    async function changeTabFreelancer(newTab, loadingSpecificJobOnLoad) {
        setUsers([]);
        setActiveTabFreelancer(newTab);

        if (loadingSpecificJobOnLoad) return;

        if (newTab === "search") {
            setIsLoading(true);
            try{
                const { data } = await GET(`/api/jobs/search`);
                setJobs(data);
                setIsLoading(false);
            } catch(err){
                console.log(err)
                setIsLoading(false);
            }
        }

        if (newTab === "applied") {
            setIsLoading(true);
            try{
                const { data } = await GET(`/api/jobs/applied`);
                setJobs(data);
                setIsLoading(false);
            } catch(err){
                console.log(err)
                setIsLoading(false);
            }
        }

        if (newTab === "accepted") {
            setIsLoading(true);
            try{
                const { data } = await GET(`/api/jobs/accepted`);
                setJobs(data);
                setIsLoading(false);
            } catch(err){
                console.log(err)
                setIsLoading(false);
            }
        }

        if (newTab === "completed") {
            setIsLoading(true);
            try{
                const { data } = await GET(`/api/jobs/completed`);
                setJobs(data);
                setIsLoading(false);
            } catch(err){
                console.log(err)
                setIsLoading(false);
            }
        }
    }

    return (
        <>
            <main className={`${spotlightActive ? "org-connect-spotlight-open" : ""}`}>
                
                {currentUser.user_type === 'office' ? 

                    <>
                        <TabHeadingOffice
                            pendingUsers={pendingUsers} 
                            changeTab={changeTabOffice}
                            activeTab={activeTabOffice}
                        />
                        <TabBodyOffice
                            jobs={jobs}
                            setJobs={setJobs}
                            currentUser={currentUser}
                            setCurrentUser={setCurrentUser}
                            activeTab={activeTabOffice}
                            changeTab={changeTabOffice}
                            setCurrentSearchedUsers={setCurrentSearchedUsers}
                            isLoading={isLoading}
                            spotlightActive={spotlightActive}
                            setUserSpotlight={setUserSpotlight}
                            setSpotlightActive={setSpotlightActive}
                            setPanelsOpen={setPanelsOpen}
                            useState={useState}
                        />
                    </>

                    : currentUser.user_type === 'freelancer' ?
                        <>
                            <TabHeadingFreelancer 
                                pendingUsers={pendingUsers} 
                                changeTab={changeTabFreelancer}
                                activeTab={activeTabFreelancer}
                            />
                            <TabBodyFreelancer
                                jobs={jobs}
                                setJobs={setJobs}
                                currentUser={currentUser}
                                setCurrentUser={setCurrentUser}
                                activeTab={activeTabFreelancer}
                                changeTab={changeTabFreelancer}
                                setCurrentSearchedUsers={setCurrentSearchedUsers}
                                isLoading={isLoading}
                                setUserSpotlight={setUserSpotlight}
                                setSpotlightActive={setSpotlightActive}
                                setPanelsOpen={setPanelsOpen}
                                useState={useState}
                            />
                        </>
                        : ""
                }
                
            </main>
            {spotlightActive ? <UserProfile userSpotlight={userSpotlight} setUserSpotlight={setUserSpotlight} setSpotlightActive={setSpotlightActive} panelsOpen={panelsOpen} setPanelsOpen={setPanelsOpen} customId={'org-connect-mobile-user-profile'} isOrgConnectPage={true} currentUser={currentUser}/> : ""}
        </>
    );
}

export default Jobs;
