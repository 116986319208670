import axios from 'axios'
import { apiDomain } from '../constants'
import { deleteToken } from "../../util/auth/auth"


export async function GET(endpoint){

    try {
        const data = await axios.get(apiDomain + endpoint)
        return data
    } catch(err){
        console.log(err)
        if (err.response.status === 401){
            deleteToken()
            window.location.href = "/login"
        } else {
            console.log(err)
        }
    }
}


export async function POST(endpoint, body){

    try {
        const data = await axios.post(apiDomain + endpoint, body)
        return data
    } catch(err){
        console.log(err)
        if (err.response.status === 401){
            deleteToken()
            window.location.href = "/login"
        } else {
            console.log(err)
        }
    }
}

export async function DELETE(endpoint){

    try {
        const data = await axios.delete(apiDomain + endpoint)
        return data
    } catch(err){
        console.log(err)
        if (err.response.status === 401){
            deleteToken()
            window.location.href = "/login"
        } else {
            console.log(err)
        }
    }
}

