import "../../styles/login-signup/main.css";
import logo from "../../images/Layer+12.png";
import { useState, useEffect } from "react";
import Loading from "../other/Loading";
import { setToken } from "../../util/auth/auth";
import axios from "axios";
import { apiDomain } from "../../util/constants";

export function LoginBoilerplate(props) {
    return (
        <main id="login-area">
            <div id="login-form">
                <div className="logo-container">
                    <img src={logo}></img>
                </div>
                {props.children}
            </div>
        </main>
    );
}

export function LoginMain(props) {
    const [state, setState] = useState({
        email: "",
        password: "",
        password2: "",
        message: "",
        loading: false,
        requestProcessed: "",
    });

    // useEffect(() => {
    //     const listener = event => {
    //       if (event.code === "Enter" || event.code === "NumpadEnter") {
    //
    //       }
    //     };
    //     document.addEventListener("keydown", listener);
    //     return () => {
    //       document.removeEventListener("keydown", listener);
    //     };
    //   }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setState((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const handleError = (err) => {
        if (err && err.response && err.response.status >= 400) {
            setState((prevState) => ({
                ...prevState,
                message: err.response.data[0],
                loading: false,
            }));
        } else {
            console.log(err);
            setState((prevState) => ({
                ...prevState,
                message: "an unknown error occured please try again later",
                loading: false,
            }));
        }
    };

    const handleKeyPress = (e) => {
        if (e.which === 13) {
            if (props.form === "Register") {
                register();
            }
            if (props.form === "Login") {
                login();
            }
            if (props.form === "Forgot") {
                forgot();
            }
            if (props.form === "Reset") {
                reset();
            }
        }
    };

    const register = async () => {
        setState((prevState) => ({
            ...prevState,
            loading: true,
        }));

        const data = {
            email: state.email,
            password: state.password,
            password2: state.password2,
        };

        try {
            const response = await axios.post(`${apiDomain}/api/auth/register`, {
                data,
            });
            if (response.data.success) {
                setToken(response);
                window.location = "/";
            }
        } catch (err) {
            handleError(err);
        }
    };

    const login = async () => {
        setState((prevState) => ({
            ...prevState,
            loading: true,
        }));

        const data = {
            email: state.email,
            password: state.password,
        };

        try {
            const response = await axios.post(`${apiDomain}/api/auth/login`, {
                data,
            });

            if (response.data && response.data.success) {
                setToken(response);
                window.location = "/";
            }
        } catch (err) {
            handleError(err);
        }
    };

    const forgot = async () => {
        setState((prevState) => ({
            ...prevState,
            loading: true,
        }));

        const data = {
            email: state.email,
        };

        try {
            const response = await axios.post(`${apiDomain}/api/auth/forgot`, {
                data,
            });

            if (response.status === 200) {
                setState((prevState) => ({
                    ...prevState,
                    loading: false,
                    requestProcessed: true,
                }));
            }
        } catch (err) {
            console.log(err);
            handleError(err);
        }
    };

    const reset = async () => {
        setState((prevState) => ({
            ...prevState,
            loading: true,
        }));

        const data = {
            password: state.password,
            password2: state.password2,
            id: props.params.id,
            token: props.params.token,
        };

        try {
            const response = await axios.post(`${apiDomain}/api/auth/reset`, {
                data,
            });

            if (response.status === 200) {
                setState((prevState) => ({
                    ...prevState,
                    loading: false,
                    requestProcessed: true,
                }));
            }
        } catch (err) {
            console.log(err);
            handleError(err);
        }
    };

    if (props.form === "Register") {
        return (
            <LoginBoilerplate>
                <h4 className={state.message ? "error-message" : ""}>{state.message ? state.message : "Enter the following info to register"}</h4>
                <input value={state.email} onChange={(e) => handleChange(e)} onKeyPress={(e) => handleKeyPress(e)} name="email" id="register-email" className="login-input" placeholder="email"></input>
                <input value={state.password} onChange={(e) => handleChange(e)}  onKeyPress={(e) => handleKeyPress(e)} name="password" type="password" id="register-password" className="login-input" placeholder="create password"></input>
                <input value={state.password2} onChange={(e) => handleChange(e)} onKeyPress={(e) => handleKeyPress(e)} name="password2" type="password" id="register-password2" className="login-input" placeholder="re-type password"></input>

                <button onClick={state.loading ? null : register} className="buttonMain" style={state.loading ? { padding: "6px 30px" } : { padding: "12px 30px" }}>
                    {state.loading ? <Loading /> : "Sign Up"}
                </button>
                <div className="links">
                    <p>
                        <a href="/forgot">Forgot Password</a>
                    </p>
                    <p>
                        <a href="/login">Login</a>
                    </p>
                </div>
            </LoginBoilerplate>
        );
    } else if (props.form === "Login") {
        return (
            <LoginBoilerplate>
                <h4 className={state.message ? "error-message" : ""}>{state.message ? state.message : "Enter your credentials to log in"}</h4>
                <input value={state.email} onChange={(e) => handleChange(e)} onKeyPress={(e) => handleKeyPress(e)} name="email" id="register-email" className="login-input" placeholder="email"></input>
                <input value={state.password} onChange={(e) => handleChange(e)} onKeyPress={(e) => handleKeyPress(e)} name="password" type="password" id="register-password" className="login-input" placeholder="password"></input>

                <button onClick={state.loading ? null : login} className="buttonMain" style={state.loading ? { padding: "6px 30px" } : { padding: "12px 30px" }}>
                    {state.loading ? <Loading /> : "Login"}
                </button>
                <div className="links">
                    <p>
                        <a href="/forgot">Forgot Password</a>
                    </p>
                    <p>
                        <a href="/register">Create Account</a>
                    </p>
                </div>
            </LoginBoilerplate>
        );
    } else if (props.form === "Forgot") {
        return (
            <LoginBoilerplate>
                {state.requestProcessed ? (
                    <>
                        <div style={{ margin: "25px" }}>Please check your inbox. If that email is associated with a user account you will receive an email shortly!</div>
                        <i class="fas fa-check success-checkmark"></i>
                    </>
                ) : (
                    <>
                        <h4 className={state.message ? "error-message" : ""}>{state.message ? state.message : "Enter your email to reset your password"}</h4>
                        <input value={state.email} onChange={(e) => handleChange(e)} onKeyPress={(e) => handleKeyPress(e)} name="email" id="register-email" className="login-input" placeholder="email"></input>
                        <button onClick={state.loading ? null : forgot} className="buttonMain" style={state.loading ? { padding: "6px 30px" } : { padding: "12px 30px" }}>
                            {state.loading ? <Loading /> : "Submit"}
                        </button>
                    </>
                )}
                <div className="links">
                    <p>
                        <a href="/login">Login</a>
                    </p>
                    <p>
                        <a href="/register">Create Account</a>
                    </p>
                </div>
            </LoginBoilerplate>
        );
    } else if (props.form === "Reset") {
        return (
            <LoginBoilerplate>
                {state.requestProcessed ? (
                    <>
                        <div style={{ margin: "25px" }}>Password successfully reset! Please click the link below to login.</div>
                        <a href="/login">
                            <i class="fas fa-check success-checkmark"></i>
                        </a>
                    </>
                ) : (
                    <>
                        <h4 className={state.message ? "error-message" : ""}>{state.message ? state.message : "Enter new password"}</h4>
                        <input value={state.password} onChange={(e) => handleChange(e)} onKeyPress={(e) => handleKeyPress(e)} name="password" type="password" id="register-password" className="login-input" placeholder="password"></input>
                        <input value={state.password2} onChange={(e) => handleChange(e)} onKeyPress={(e) => handleKeyPress(e)} name="password2" type="password" id="register-password2" className="login-input" placeholder="re-type password"></input>
                        <button onClick={state.loading ? null : reset} className="buttonMain" style={state.loading ? { padding: "6px 30px" } : { padding: "12px 30px" }}>
                            {state.loading ? <Loading/> : "Submit"}
                        </button>
                    </>
                )}
                <div className="links">
                    <p>
                        <a href="/login">Login</a>
                    </p>
                    <p>
                        <a href="/register">Create Account</a>
                    </p>
                </div>
            </LoginBoilerplate>
        );
    }
}

// export default LoginMain;
