import { React, useState, useEffect } from "react";
import Rating from '@mui/material/Rating';
import { POST } from "../../../util/api/api";
import Loading from "../../other/Loading";

export function ReviewAndCompletionStep(props) {

    const {job, changeTab, user, additionalHours} = props

    const [reviewAndCompletionStep, setReviewAndCompletionStep] = useState('review')
    const [professionalismValue, setProfessionalismValue] = useState(0);
    const [punctualityValue, setPunctualityValue] = useState(0);
    const [bedsideMannerValue, setBedsideMannerValue] = useState(0);
    const [skillsetValue, setSkillsetValue] = useState(0);
    const [cleanlinessValue, setCleanlinessValue] = useState(0);
    const [friendlinessValue, setFriendlinessValue] = useState(0);
    const [helpfulnessValue, setHelpfulnessValue] = useState(0);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (job.office_review){
            setReviewAndCompletionStep('complete')
        }
    }, []);

    async function submitReview(){
        setIsLoading(true)
        const body = {
            professionalism: professionalismValue,
            punctuality: punctualityValue,
            bedside_manner: bedsideMannerValue,
            skill_set: skillsetValue,
            cleanliness: cleanlinessValue,
            friendliness: friendlinessValue,
            helpfulness: helpfulnessValue
        }

        try {
            const data = await POST(`/api/reviews/${job._id}`, body);

            if (data?.status === 200){
                setIsLoading(false)
                if (user.user_type === 'office'){
                    setReviewAndCompletionStep('complete')
                } else {
                    changeTab('completed')
                }
                
            } else {
                setIsLoading(false)
                alert('An unknown error occurred. Please try again later.')
            }
        } catch (err){
            setIsLoading(false)
            console.log(err)
            alert('An unknown error occurred. Please try again later.')
        }
    }

    async function completeJob(){

        setIsLoading(true)

        const body = {
            id: job._id,
            additional_hours: additionalHours
        }

        try {

            const data = await POST(`/api/jobs/complete`, body);

            if (data?.status === 200){
                setIsLoading(false)
                changeTab('completed')
            } else {
                setIsLoading(false)
                alert('An unknown error ocurred. Please refresh and try again later.')
            }
        } catch(err){
            console.log(err)
            setIsLoading(false)
            alert('An unknown error ocurred. Please refresh and try again later.')
        }
        
    }

    function OfficeReviewAndCompletion(){
        return (
            reviewAndCompletionStep === 'review' ?
            
                <div className="job-container job-view-container review-container">
                    <div className="job-view-inner-container">
                        <h3>Write a Review</h3>
                        <hr></hr>
                        <p className="long-text">How did this worker do working at your practice? Please feel free to be honest! Good reviews let temps know when they're doing a great job and how they can improve.</p>
                        <hr></hr>
                        <p className="long-text"><b>Professionalism:</b></p>
                        <Rating
                            name="hover-feedback"
                            value={professionalismValue}
                            precision={0.5}
                            onChange={(event, newValue) => {
                                setProfessionalismValue(newValue)
                            }}
                        />
                        <hr></hr>
                        <p className="long-text"><b>Punctuality:</b></p>
                        <Rating
                            name="hover-feedback"
                            value={punctualityValue}
                            precision={0.5}
                            onChange={(event, newValue) => {
                                setPunctualityValue(newValue)
                            }}
                        />
                        <hr></hr>
                        <p className="long-text"><b>Bedside Manner:</b></p>
                        <Rating
                            name="hover-feedback"
                            value={bedsideMannerValue}
                            precision={0.5}
                            onChange={(event, newValue) => {
                                setBedsideMannerValue(newValue)
                            }}
                        />
                        <hr></hr>
                        <p className="long-text"><b>Skill Set:</b></p>
                        <Rating
                            name="hover-feedback"
                            value={skillsetValue}
                            precision={0.5}
                            onChange={(event, newValue) => {
                                setSkillsetValue(newValue)
                            }}
                        />
                    </div>
                    <div className="freelancer-button-container">
                        {isLoading 
                            ? <div style={{margin: "20px"}}><Loading color="brown"/></div>
                            :  <button onClick={submitReview} className="buttonMain">Continue</button>
                        }
                    </div>
                </div>
            :

                <div className="job-container job-view-container">
                    <div className="job-view-inner-container">
                        <h3>Complete Job</h3>
                        <hr></hr>
                        <p className="long-text">Clicking below will mark this job as completed. Please be sure to pay the temp's wages for this shift in whichever manner you and the temp have agreed to (e.g. 1099, W2, Check, direct deposit, etc.)</p>
                    </div>
                    <div className="freelancer-button-container">
                        {isLoading 
                            ? <div style={{margin: "20px"}}><Loading color="brown"/></div>
                            :  <button onClick={completeJob} className="buttonMain">Complete Job</button> 
                        }
                    </div>
                </div>
        )
    }

    function FreelancerReviewAndCompletion(){
        return (
            <div className="job-container job-view-container review-container">
                <div className="job-view-inner-container">
                    <h3>Write a Review</h3>
                    <hr></hr>
                    <p className="long-text">How was it working at this dental office? Please feel free to be honest! Good reviews let offices know when they're doing a great job and how they can improve.</p>
                    <hr></hr>
                    <p className="long-text"><b>Cleanliness:</b></p>
                    <Rating
                        name="hover-feedback"
                        value={cleanlinessValue}
                        precision={0.5}
                        onChange={(event, newValue) => {
                            setCleanlinessValue(newValue)
                        }}
                    />
                    <hr></hr>
                    <p className="long-text"><b>Friendliness:</b></p>
                    <Rating
                        name="hover-feedback"
                        value={friendlinessValue}
                        precision={0.5}
                        onChange={(event, newValue) => {
                            setFriendlinessValue(newValue)
                        }}
                    />
                    <hr></hr>
                    <p className="long-text"><b>Helpfulness:</b></p>
                    <Rating
                        name="hover-feedback"
                        value={helpfulnessValue}
                        precision={0.5}
                        onChange={(event, newValue) => {
                            setHelpfulnessValue(newValue)
                        }}
                    />
                </div>
                <div className="freelancer-button-container">
                    {isLoading 
                        ? <div style={{margin: "20px"}}><Loading color="brown"/></div>
                        :  <button onClick={submitReview} className="buttonMain">Continue</button>
                    }
                </div>
            </div>
        )
    }

    return (
        user.user_type === 'office' ? <OfficeReviewAndCompletion /> : <FreelancerReviewAndCompletion />

    )
}