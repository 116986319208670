import React, {useEffect, useState} from 'react';
import { ChannelList, useChatContext } from 'stream-chat-react';

import './ChannelListContainer.css';

import { ChannelSearch } from '../ChannelSearch/ChannelSearch';
import { TeamChannelList } from '../TeamChannelList/TeamChannelList';
import { TeamChannelPreview } from '../TeamChannelPreview/TeamChannelPreview';

const customChannelTeamFilter = (channels) => {
  return channels.filter((channel) => channel.type === 'team');
};

const customChannelMessagingFilter = (channels) => {
  return channels.filter((channel) => channel.type === 'messaging');
};

const noChannelsMsg = () => {
  return <p className="no-channels-msg">No Spaces found. Try creating one!</p>
}

const noDMsMsg = () => {
  return <p className="no-channels-msg">No DM's found. Try creating one!</p>
}

export const ChannelListContainer = (props) => {
  const { filters, options, setCreateType, setIsCreating, setIsEditing, sort, connections, panelsOpen, setPanelsOpen, loadSpecificChannel, idToLoadChannel } = props;
  const { client, setActiveChannel } = useChatContext();
  
  useEffect(() => {

    async function getChannelByUserId(){

        const filters = {
          type: 'messaging',
          member_count: 2,
          members: { $eq: [client.user.id, idToLoadChannel] },
        };
      
        const [existingChannel] = await client.queryChannels(filters);

        if (existingChannel){
          setActiveChannel(existingChannel)
        } else {
          const newChannel = client.channel('messaging', {
            members: [client.userID, idToLoadChannel],
          });
          setActiveChannel(newChannel);
        }
    }

    if (loadSpecificChannel){
      getChannelByUserId()
    }
  }, []);

  return (
    <div className={!panelsOpen.left ? 'mobile-close channel-list__container' : 'channel-list__container'}>
      {/* <SideBar /> */}
      <div className='channel-list__list__wrapper'>
        {/* <CompanyHeader /> */}
        <ChannelSearch filters={filters[0]} connections={connections}/>
        <ChannelList
          channelRenderFilterFn={customChannelTeamFilter}
          filters={filters[0]}
          options={options}
          sort={sort}
          setActiveChannelOnMount={loadSpecificChannel ? false : true}
          EmptyStateIndicator={noChannelsMsg}
          List={(listProps) => (
            <TeamChannelList
              {...listProps}
              {...{ setCreateType, setIsCreating, setIsEditing, setPanelsOpen }}
              type='team'
            />
          )}
          Preview={(previewProps) => (
            <TeamChannelPreview
              {...previewProps}
              {...{ setIsCreating, setIsEditing, setPanelsOpen }}
              type='team'
            />
          )}
        />
        <ChannelList
          channelRenderFilterFn={customChannelMessagingFilter}
          filters={filters[1]}
          options={options}
          setActiveChannelOnMount={false}
          sort={sort}
          EmptyStateIndicator={noDMsMsg}
          List={(listProps) => (
            <TeamChannelList
              {...listProps}
              {...{ setCreateType, setIsCreating, setIsEditing, setPanelsOpen }}
              type='messaging'
            />
          )}
          Preview={(previewProps) => (
            <TeamChannelPreview
              {...previewProps}
              {...{ setIsCreating, setIsEditing, setPanelsOpen }}
              type='messaging'
            />
          )}
        />
      </div>
    </div>
  );
};
