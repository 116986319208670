import { apiDomain } from "../../../util/constants";

function MobileRedirect() {

    const redirectUrl = 'dentalworks://'
    window.location.href = redirectUrl

    setTimeout(() => {
        window.location.href = apiDomain
      }, "2000");

    return (
        <div>
        </div>
    );
}    

export default MobileRedirect;