import { React } from "react";
import Loading from "../../other/Loading";
import "react-datepicker/dist/react-datepicker.css";
import { JobsList } from "../JobsList/JobsList";
import DatePicker from "react-datepicker";
import { GET } from "../../../util/api/api";

export function TabBodyFreelancer(props) {
    const { 
        users, 
        setUsers, 
        activeTab, 
        changeTab, 
        currentUser,
        setCurrentUser, 
        setCurrentSearchedUsers, 
        setUserSpotlight, 
        setSpotlightActive, 
        setPanelsOpen,
        useState,
        jobs,
        setJobs,
        isLoading
    } = props

    const [state, setState] = useState({});
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");

    const emptyMessageSearch = "There are no jobs available with the current search filters. Please adjust your search or check back later."
    const emptyMessageApplied = "No results yet. When you apply for a job it will appear here until the office selects an applicant."
    const emptyMessageAccepted = "No results yet. When you are accepted for a job it will appear here until that job is marked completed."
    const emptyMessageCompleted = "No results yet. When you complete a job, that job will appear here!"

    function onCalendarChange(dates) {
        const [start, end] = dates;
        if (end){
            end.setHours(23,59,59,59)
        }
        setStartDate(start);
        setEndDate(end);
      };

    function handleInput(e) {
        setState({
            ...state,
            [e.target.name]: e.target.value,
        });
    }

    async function search() {
        const { data } = await GET(`/api/jobs/search?freelancer_type=${state.freelancer_type}&pay_rate=${state.pay_rate}&start_date=${startDate}&end_date=${endDate}&location_radius=${state.location_radius}`);
        setJobs(data);
    }

    if (activeTab === "search") {
        return (
            <>
            <section id="job-search-bar">
                    <div>
                        <select name="freelancer_type" onChange={handleInput} >
                            <option value="">Show All Job Types</option>
                            <option value="Registered Dental Hygienist (RDH)">Registered Dental Hygienist (RDH)</option>
                            <option value="Dental Assistant (DA)">Dental Assistant (DA)</option>
                            <option value="Front Desk (FD)">Front Desk (FD)</option>
                        </select>

                        <span className="pay-rate-prefix">Minimum Hourly Rate: $</span>
                        <input className="pay-rate-filter" defaultValue={0} type="number" name="pay_rate" onChange={handleInput}></input>

                        <DatePicker 
                            className="date-range-picker" 
                            placeholderText={"Show All Dates"} 
                            selected={startDate} 
                            minDate={new Date()} 
                            onChange={onCalendarChange}
                            startDate={startDate}
                            endDate={endDate}
                            selectsRange
                        />

                        <select className="location-radius" name="location_radius" onChange={handleInput} >
                            <option value="">Show All Distances</option>
                            <option value="16.09">Within 10 Miles of Me</option>
                            <option value="40.23">Within 25 Miles of Me</option>
                            <option value="80.47">Within 50 Miles of Me</option>
                            <option value="160.93">Within 100 Miles of Me</option>
                            <option value="321.87">Within 200 Miles of Me</option>
                        </select>

                        <button className="buttonMain" onClick={search}>Search</button>
                        
                    </div>
                </section>
                <section>

                {isLoading ? (
                    <div className="oc-loading-container">
                        <div className="loading-container-block">
                            <Loading color={"brown"} />
                        </div>
                    </div>
                ) : (
                    <JobsList 
                        jobs={jobs} 
                        user={currentUser} 
                        activeTab={activeTab} 
                        changeTab={changeTab} 
                        emptyMessage={emptyMessageSearch}
                        setUserSpotlight={setUserSpotlight}
                        setSpotlightActive={setSpotlightActive}
                    />
                )}
                </section>
            </>
        );
    }

    if (activeTab === "applied") {
        return isLoading ? (
            <div className="oc-loading-container">
                <div className="loading-container-block">
                    <Loading color={"brown"} />
                </div>
            </div>
        ) : (
            <JobsList 
                jobs={jobs} 
                user={currentUser} 
                activeTab={activeTab} 
                changeTab={changeTab} 
                emptyMessage={emptyMessageApplied}
                setUserSpotlight={setUserSpotlight}
                setSpotlightActive={setSpotlightActive}
            />
        );
    }

    if (activeTab === "accepted") {
        return isLoading ? (
            <div className="oc-loading-container">
                <div className="loading-container-block">
                    <Loading color={"brown"} />
                </div>
            </div>
        ) : (
            <JobsList 
                jobs={jobs} 
                user={currentUser} 
                activeTab={activeTab} 
                changeTab={changeTab} 
                emptyMessage={emptyMessageAccepted}
                setUserSpotlight={setUserSpotlight}
                setSpotlightActive={setSpotlightActive}
            />
        );
    }

    if (activeTab === "completed") {
        return isLoading ? (
            <div className="oc-loading-container">
                <div className="loading-container-block">
                    <Loading color={"brown"} />
                </div>
            </div>
        ) : (
            <JobsList 
                jobs={jobs} 
                user={currentUser} 
                activeTab={activeTab} 
                changeTab={changeTab} 
                emptyMessage={emptyMessageCompleted}
                setUserSpotlight={setUserSpotlight}
                setSpotlightActive={setSpotlightActive}
            />
        );
    }
}