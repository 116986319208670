
import {LoginMain} from "./LoginMain";

function Login() {

    return (
        <LoginMain form={"Login"}></LoginMain>
    )
}

export default Login;