import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShareAltSquare, faCheck } from "@fortawesome/free-solid-svg-icons";
import React, { useState} from "react";
import PhoneInput from 'react-phone-input-2'
import { GET } from "../../../util/api/api";

import "./helper-pages.css"


function Share() {
    const [textCopied, setTextCopied] = useState(false);
    const [textMsgSent, setTextMsgSent] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState(false);
    const url = window.location.origin + "/register"

    function handleClick(){
        navigator.clipboard.writeText(url)
        setTextCopied(true)

        setTimeout(() => {
            setTextCopied(false)
        }, 2500);
    }

    async function handleSend(){

        if (textMsgSent || !phoneNumber) {
            return
        }

        try { 
            const response = await GET(`/other/share/send-invite-sms?phone_number=${phoneNumber}`);
            
            if (response.status === 200){
                setTextMsgSent(true)
                setPhoneNumber('')
            }
        } catch (err) {
            console.log(err)
        }
    }


    return (
        <div id="sharepage" className="demo-mask">
            <div className="demo-container">
                <div>
                    <h2>Share DentalWORKS With Your Friends</h2>
                    <div>
                        <FontAwesomeIcon icon={faShareAltSquare} />
                    </div>
                    <p>
                        Enter your friends phone number below and we'll send them a text message with the link to sign!
                        <br></br>
                        <br></br> 
                    </p>
                    {/* <input className="shareable-link-box"></input> */}
                    <PhoneInput 
                        inputProps={{
                            name: 'cell_phone_number',
                            minlength: "14"
                        }} 
                        placeholder={"(702) 123-4567"}
                        value={phoneNumber}
                        country={'us'}
                        onlyCountries={['us']}
                        disableCountryCode={true}
                        disableDropdown={true}
                        onChange={cell_phone_number => setPhoneNumber(cell_phone_number)}/>
                    <button className="helper-copy-text-button cellphonebutton" style={ textMsgSent ? {cursor:'not-allowed'} : {}} onClick={handleSend}>{ textMsgSent ? <FontAwesomeIcon className="helper-check-icon" icon={faCheck}/> : "Send" }</button>
                    <p>
                        Or just copy the link below and share it with any friends or organizations you'd like to invite to the platform!
                        <br></br>
                        <br></br> 
                    </p>
                    <p className="shareable-link-box" hand>{url}</p>
                    <button className="helper-copy-text-button" onClick={handleClick}>{ textCopied ? <FontAwesomeIcon className="helper-check-icon" icon={faCheck}/> : "Copy" }</button>
                </div>
            </div>
        </div>
    );
}    

export default Share;